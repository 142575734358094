// TODO: Write unit tests for this class

/**
 * A utility class that helps process messages that were sent by the same sender the original order
 * with which the sender had sent them
 */
export default class MessageOrderPreserver {
  constructor() {
    // TODO: Clean up senders when they disconnect. Not just for memory, but because they may have the same connectionId upon reconnection!
    this.senders = {};
  }

  /**
   * Checks whether the given message is sequential to the previous one sent by the same sender.
   * If it is not sequential, then the message will be enqueued so that it can be read when it becomes relevant.
   * If it is sequential, then it is recommended to read the "future messages" which may have become relevant just now.
   * @param {*} senderId A unique identifier of the sender
   * @param {*} messageCounter The number of the message
   * @param {*} message The message itself
   * @returns True when the message should be received, False when it should be ignored
   */
  shouldReceiveMessageFromOtherClient(senderId, messageCounter, message) {
    const sender = this.senders[senderId];

    // If the given senderId is not recognized, initialize an empty sender and accept the incoming message
    if (!sender) {
      this.senders[senderId] = {
        lastValidMessageCounter: messageCounter,
        futureMessages: []
      };
      return true;
    }

    // If the given counter is too far ahead, add it to the futureMessages queue and decline the incoming message
    if (messageCounter > sender.lastValidMessageCounter + 1) {
      sender.futureMessages.push({ messageCounter, message });
      sender.futureMessages = sender.futureMessages.sort(
        (message1, message2) =>
          message1.messageCounter - message2.messageCounter
      );

      return false;
    }

    sender.lastValidMessageCounter = Math.max(
      sender.lastValidMessageCounter,
      messageCounter
    );
    return true;
  }

  /**
   * Removes and returns all cached messages that are sequential to the last valid counter of the given sender.
   * @param {*} senderId A unique identifier of the sender
   * @returns List of all messages that were previously declined and are now part of the sequencel
   */
  dequeueFutureMessages(senderId) {
    const sender = this.senders[senderId];
    if (!sender) {
      return [];
    }

    const relevantFutureMessages = [];

    // Pull all the relevant messages starting from the beginning of the list.
    // A message is relevant if its counter is sequential to the last valid counter, e.g. 3->4->5->6
    while (
      sender.futureMessages.length > 0 &&
      sender.futureMessages[0].messageCounter ===
        sender.lastValidMessageCounter + 1
    ) {
      const futureMessage = sender.futureMessages.shift();
      relevantFutureMessages.push(futureMessage.message);
      sender.lastValidMessageCounter = futureMessage.messageCounter;
    }

    return relevantFutureMessages;
  }

  clear() {
    this.senders = {};
  }
}
