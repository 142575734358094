import throttle from 'lodash.throttle';

export default class ThrottledFlushQueue {
  constructor(flushCallback, throttleMilliseconds) {
    this.flushCallback = flushCallback;
    this.flush = throttle(this._flush, throttleMilliseconds);
    this.itemsToFlush = [];
    this._onceEmptyCallback = null;
  }

  push(item) {
    this.itemsToFlush.push(item);
  }

  _flush() {
    if (this.itemsToFlush.length === 0) {
      this.fireOnceEmptyEvent();
      return;
    }

    this.flushCallback(this.itemsToFlush);
    this.itemsToFlush = [];
    this.fireOnceEmptyEvent();
  }

  onceEmpty(callback) {
    this._onceEmptyCallback = callback;
  }

  fireOnceEmptyEvent() {
    if (this._onceEmptyCallback) {
      this._onceEmptyCallback();
      this._onceEmptyCallback = null;
    }
  }
}
