import paper from 'paper';
import TextBoxController from './text-box-controller';
import SimpleEventEmitter from '@/utils/SimpleEventEmitter';

export default class TextController extends SimpleEventEmitter {
  constructor() {
    super();

    this.textBoxController = new TextBoxController();
  }

  init(textBoxElement) {
    this.textBoxController.init(textBoxElement);
  }

  getClickedTextItem(point) {
    return paper.project.activeLayer.children.find(item => {
      return item instanceof paper.PointText && item.contains(point);
    });
  }

  enterTextItemEditMode(textItem, position, zoomFactor) {
    // hide text item
    textItem.visible = false;
    // display text box
    this.displayTextBox(position, {
      color: textItem.fillColor.toCSS(),
      zoomFactor,
      textStyle: textItem.data.textStyle
    });
    // fill the text box with the text item content
    this.textBoxController.setTextBoxText(textItem.content);
    // set text box cursor to the end
    this.textBoxController.moveCaretToEnd();
  }

  displayTextBox(topLeftPoint, { textColor, zoomFactor, textStyle }) {
    this.textBoxController.displayTextBox(
      topLeftPoint,
      textColor,
      zoomFactor,
      textStyle
    );
  }

  addNewTextItem(textItem) {
    this.emit('add-text-item', textItem);
  }

  updateTextItem(textItem) {
    this.emit('update-text-item', textItem);
  }

  textItemClicked(textItem) {
    this.emit('text-item-clicked', textItem);
  }
}
