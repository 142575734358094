import WebSocketMessageSizeExceededError from '@/errors/WebSocketMessageSizeExceededError';
import CollaborativeWhiteboardService from '@/services/collaborative-whiteboard-service';
import logger from '@/services/logger';

export default {
  init: ({ dispatch }) => {
    CollaborativeWhiteboardService.selectionController.on(
      'items-move-finalize',
      ({ items, position, originalPosition }) => {
        dispatch('finalizeMoveItems', { items, position, originalPosition });
      }
    );

    CollaborativeWhiteboardService.selectionController.on(
      'items-resize-finalize',
      ({ items, size, sizeBeforeResizing, center, centerBeforeResizing }) => {
        dispatch('finalizeResizeItems', {
          items,
          size,
          sizeBeforeResizing,
          center,
          centerBeforeResizing
        });
      }
    );
  },

  copySelectedItems: () => {
    CollaborativeWhiteboardService.actionsController.copySelectedItems();
  },

  pasteClipboardItems: ({ dispatch, rootGetters }) => {
    if (rootGetters.isWhiteboardReadonly) {
      return;
    }

    try {
      CollaborativeWhiteboardService.actionsController.pasteClipboardItems();
    } catch (error) {
      if (error instanceof WebSocketMessageSizeExceededError) {
        dispatch(
          'flashMessages/addFlashMessage',
          {
            type: 'warning',
            text:
              'Pasting failed. You may have tried to paste too many items at once'
          },
          { root: true }
        );
        logger.error('websocket-message-size-exceeded', {
          action: 'paste',
          error
        });
        return;
      }

      throw error;
    }
  },

  deleteSelectedItems: ({ dispatch, rootGetters }) => {
    if (rootGetters.isWhiteboardReadonly) {
      return;
    }

    try {
      CollaborativeWhiteboardService.actionsController.deleteSelectedItems();
    } catch (error) {
      if (error instanceof WebSocketMessageSizeExceededError) {
        dispatch(
          'flashMessages/addFlashMessage',
          {
            type: 'warning',
            text:
              'Deleting items failed. You may have tried to delete too many items at once'
          },
          { root: true }
        );
        logger.error('websocket-message-size-exceeded', {
          action: 'delete-selected-items',
          error
        });
        return;
      }

      throw error;
    }
  },

  finalizeMoveItems({ dispatch }, { items, position, originalPosition }) {
    try {
      CollaborativeWhiteboardService.actionsController.finalizeMoveItems({
        items,
        position,
        originalPosition
      });
    } catch (error) {
      if (error instanceof WebSocketMessageSizeExceededError) {
        dispatch(
          'flashMessages/addFlashMessage',
          {
            type: 'warning',
            text:
              'Moving items failed. You may have tried to move too many items at once'
          },
          { root: true }
        );
        logger.error('websocket-message-size-exceeded', {
          action: 'move-selected-items',
          error
        });
        return;
      }

      throw error;
    }
  },

  finalizeResizeItems(
    { dispatch },
    { items, size, sizeBeforeResizing, center, centerBeforeResizing }
  ) {
    try {
      CollaborativeWhiteboardService.actionsController.finalizeResizeItems({
        items,
        size,
        sizeBeforeResizing,
        center,
        centerBeforeResizing
      });
    } catch (error) {
      if (error instanceof WebSocketMessageSizeExceededError) {
        dispatch(
          'flashMessages/addFlashMessage',
          {
            type: 'warning',
            text:
              'Resizing items failed. You may have tried to resize too many items at once'
          },
          { root: true }
        );
        logger.error('websocket-message-size-exceeded', {
          action: 'resize-selected-items',
          error
        });
        return;
      }

      throw error;
    }
  },

  clear: () => {
    CollaborativeWhiteboardService.synchronizer.clearWhiteboard();
  },

  getNewestPaperItem: () => {
    return CollaborativeWhiteboardService.actionsController.getNewestItem();
  }
};
