<template>
  <svgicon
    :name="iconName"
    class="v-icon"
    original
    :class="{
      md,
      sm,
      xs,
      xxs,
      pointer: $listeners && $listeners.click && !preventClick,
      spin: spin
    }"
    @click.native="event => !preventClick && $emit('click', event)"
  />
</template>

<script>
import '@/assets/icons';
import * as svgicon from 'vue-svgicon';

export default {
  name: 'VIcon',

  components: {
    svgicon
  },

  props: {
    iconName: {
      type: String,
      default: ''
    },
    spin: {
      type: Boolean,
      default: false
    },
    sm: {
      type: Boolean,
      default: false
    },
    md: {
      type: Boolean,
      default: false
    },
    xs: {
      type: Boolean,
      default: false
    },
    xxs: {
      type: Boolean,
      default: false
    },
    preventClick: {
      type: Boolean,
      default: false
    },
    fill: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.v-icon {
  outline: none;
}

.pointer {
  cursor: pointer;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 1.5s infinite linear;
}

.md {
  height: 24px;
  width: 24px;
}

.sm {
  height: 20px;
  width: 20px;
}

.xs {
  height: 16px;
  width: 16px;
}

.xxs {
  height: 12px;
  width: 12px;
}
</style>
