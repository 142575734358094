/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shape': {
    width: 17,
    height: 16,
    viewBox: '0 0 17 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.5 0A1.5 1.5 0 000 1.5v13A1.5 1.5 0 001.5 16h13.001a1.5 1.5 0 001.5-1.5v-13a1.5 1.5 0 00-1.5-1.5h-13zM1 1.5a.5.5 0 01.5-.5h13.001a.5.5 0 01.5.5v13a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5v-13z" _fill="#000"/>'
  }
})
