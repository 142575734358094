import CollaborativeWhiteboardService from '@/services/collaborative-whiteboard-service';

export default {
  init: ({ commit }) => {
    CollaborativeWhiteboardService.canvasPanZoomController.on(
      'zoom',
      ({ factor }) => {
        commit('SET_ZOOM_FACTOR', factor);
        CollaborativeWhiteboardService.settingsProvider.zoomFactor = factor;
      }
    );
  },

  setZoomFactor: (_, { zoomFactor, zoomOnPoint }) => {
    CollaborativeWhiteboardService.canvasPanZoomController.setZoom(
      zoomFactor,
      zoomOnPoint
    );
  },

  resetZoom: () => {
    CollaborativeWhiteboardService.canvasPanZoomController.setZoom(1);
  },

  zoomToFit: () => {
    CollaborativeWhiteboardService.canvasPanZoomController.zoomToFit();
  },

  /**
   * Pans to an optimal initial center point
   */
  panToInitialCenter: async ({ dispatch }) => {
    let initialCenter = { x: 0, y: 0 };

    const newestPaperItem = await dispatch('canvas/getNewestPaperItem', null, {
      root: true
    });
    if (newestPaperItem) {
      initialCenter = newestPaperItem.bounds.center;
    }

    CollaborativeWhiteboardService.canvasPanZoomController.setCenter(
      initialCenter
    );
    CollaborativeWhiteboardService.canvasPanZoomController.finalizePan();
  }
};
