<template>
  <ToolButton
    displayName="Text"
    :active="active"
    @activated="activateTextTool"
    @click="activateTextTool"
  >
    <template slot="icon">
      <v-icon
        :iconName="active ? 'text-fill-negative' : 'text'"
        :class="{ 'no-fill': !active }"
      />
    </template>

    <template slot="menu">
      <div class="text-menu">
        <div class="size">
          <VDropdown
            class="text-sizes-dropdown"
            minWidth="175"
            :closeOnSelection="false"
            :items="textStylesDropdownItems"
            @change="onTextStyleChange"
          >
            <div slot="toggle-button" class="select-size-button">
              <span class="selected-size-name" :style="selectedTextStyleCss">{{
                selectedTextStyleText
              }}</span>
              <v-icon
                iconName="Vlt-icon-down-full"
                class="expand-dropdown-arrow-icon Vlt-black"
              />
            </div>
            <template slot="item" slot-scope="textStyleItem">
              <div
                :style="getTextStyleItemStyle(textStyleItem)"
                class="size-option"
              >
                {{ textStyleItem.item.label }}
                <v-icon
                  v-if="textStyleItem.item.value === textStyle.fontStyle"
                  iconName="Vlt-icon-check-bold"
                  class="check-icon"
                />
              </div>
            </template>
          </VDropdown>
        </div>
        <div class="separator"></div>
        <div class="text-decoration">
          <div
            :class="{ active: textStyle.isBold }"
            class="text-decoration-option"
            @click="toggleIsBold"
          >
            <v-icon iconName="text-bold" />
          </div>
          <div
            :class="{ active: textStyle.isItalic }"
            class="text-decoration-option"
            @click="toggleIsItalic"
          >
            <v-icon iconName="text-italic" />
          </div>
        </div>
        <div class="color">
          <TextColorSelection
            :selectedColor="textColor"
            @select="selectTextColor"
          />
        </div>
      </div>
    </template>
  </ToolButton>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ToolButton from '@/components/toolbar/ToolButton';
import TextColorSelection from '@/components/toolbar/TextColorSelection';
import { AVAILABLE_TEXT_STYLES } from '@/consts';

export default {
  components: {
    ToolButton,
    TextColorSelection
  },

  props: {
    active: {
      type: Boolean,
      default: false
    }
  },

  data() {
    const availableTextStyles = AVAILABLE_TEXT_STYLES;
    return {
      availableTextStyles,
      isBold: false,
      isItalic: false
    };
  },

  computed: {
    ...mapState('settings', ['textStyle', 'textColor']),
    ...mapGetters('settings', ['selectedTextStyleCss']),

    textStylesDropdownItems() {
      return Object.keys(this.availableTextStyles).map(textStyleKey => {
        return {
          value: textStyleKey,
          label: this.availableTextStyles[textStyleKey].text
        };
      });
    },

    selectedTextStyleText() {
      return this.availableTextStyles[this.textStyle.fontStyle].text;
    }
  },

  methods: {
    ...mapActions('settings', ['setTextStyle', 'selectTextColor']),

    activateTextTool() {
      this.$emit('activated');
    },

    toggleIsBold() {
      this.setTextStyle({ isBold: !this.textStyle.isBold });
    },

    toggleIsItalic() {
      this.setTextStyle({ isItalic: !this.textStyle.isItalic });
    },

    onTextStyleChange(textStyleItem) {
      this.setTextStyle({ fontStyle: textStyleItem.value });
    },

    getTextStyleItemStyle(textStyleItem) {
      const style = this.availableTextStyles[textStyleItem.item.value];
      return {
        fontSize: `${style.fontSize}px`,
        lineHeight: `${style.lineHeight}px`,
        fontWeight: style.fontWeight
      };
    }
  }
};
</script>

<style scoped>
.no-fill {
  fill: none;
}

.text-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 40px;
}

.size {
  width: 110px;
}

.text-sizes-dropdown {
  width: 100%;
}

.text-decoration {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.text-decoration-option {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 3px;
  cursor: pointer;
  border-radius: 4px;
  margin: 1px;
}

.text-decoration-option >>> .v-icon {
  width: 14px;
  height: 16px;
}

.text-decoration-option.active {
  background: #0000001a;
}

.expand-dropdown-arrow-icon {
  width: 12px;
  transition: 0.2s;
}

.text-sizes-dropdown.Vlt-dropdown--expanded .expand-dropdown-arrow-icon {
  transform: scale(1, -1);
}

.text-sizes-dropdown >>> .Vlt-dropdown__panel {
  margin-top: 13px;
}

.separator {
  height: 32px;
  border-left: 1px solid #cccccc;
  margin: 5px;
}

.select-size-button {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.size-option {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.selected-size-name {
  margin-left: 6px;
}

.check-icon {
  width: 12px;
}
</style>
