/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'text': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M1.003 1v4.364h.727l1.455-2.182h4.364v11.636l-2.182 1.455V17h7.273v-.727l-2.182-1.455V3.182h4.364l1.455 2.182h.727V1H1.003z" _stroke="#212121" stroke-miterlimit="10" stroke-linecap="square"/>'
  }
})
