import WebSocketMessageSizeExceededError from '@/errors/WebSocketMessageSizeExceededError';
import CollaborativeWhiteboardService from '@/services/collaborative-whiteboard-service';
import logger from '@/services/logger';

export default {
  init: ({ dispatch, commit }) => {
    CollaborativeWhiteboardService.settingsProvider.onChange('color', color => {
      dispatch('handleSelectedColorChanged', color);
    });

    CollaborativeWhiteboardService.settingsProvider.onChange(
      'textColor',
      color => {
        dispatch('handleSelectedTextColorChanged', color);
      }
    );

    CollaborativeWhiteboardService.settingsProvider.onChange(
      'textStyle',
      textStyle => {
        commit('SET_TEXT_STYLE', textStyle);
      }
    );
  },

  selectColor: (_, selectedColor) => {
    CollaborativeWhiteboardService.settingsProvider.color = selectedColor;
  },

  selectTextColor: (_, selectedColor) => {
    CollaborativeWhiteboardService.settingsProvider.textColor = selectedColor;
  },

  handleSelectedColorChanged: ({ dispatch, commit }, color) => {
    commit('SET_COLOR', color);

    try {
      CollaborativeWhiteboardService.actionsController.setSelectedItemsColor(
        color
      );
    } catch (error) {
      if (error instanceof WebSocketMessageSizeExceededError) {
        dispatch(
          'flashMessages/addFlashMessage',
          {
            type: 'warning',
            text:
              'Failed to change the color of the selected items. You may have selected too many items'
          },
          { root: true }
        );
        logger.error('websocket-message-size-exceeded', {
          action: 'change-selected-items-color',
          error
        });
        return;
      }

      throw error;
    }
  },

  handleSelectedTextColorChanged: ({ dispatch, commit }, color) => {
    commit('SET_TEXT_COLOR', color);

    try {
      CollaborativeWhiteboardService.actionsController.setSelectedTextItemsColor(
        color
      );
    } catch (error) {
      if (error instanceof WebSocketMessageSizeExceededError) {
        dispatch(
          'flashMessages/addFlashMessage',
          {
            type: 'warning',
            text:
              'Failed to change the color of the selected items. You may have selected too many items'
          },
          { root: true }
        );
        logger.error('websocket-message-size-exceeded', {
          action: 'change-selected-items-color',
          error
        });
        return;
      }

      throw error;
    }
  },

  setTextStyle: ({ state }, textStyleUpdate) => {
    const updatedTextStyle = { ...state.textStyle, ...textStyleUpdate };
    CollaborativeWhiteboardService.settingsProvider.textStyle = updatedTextStyle;
  }
};
