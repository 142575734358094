/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shape-fill-negative': {
    width: 17,
    height: 16,
    viewBox: '0 0 17 16',
    data: '<g clip-path="url(#clip0)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.001 2h-12v12h12V2zm-12-2A2 2 0 000 2v12a2 2 0 002 2h12.001a2 2 0 002-2V2a2 2 0 00-2-2h-12z" _fill="#fff"/></g><defs><clipPath id="clip0"><path pid="1" _fill="#fff" d="M0 0h16.001v16H0z"/></clipPath></defs>'
  }
})
