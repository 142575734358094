import paper from 'paper';
import {
  TEXTBOX_PADDING_LEFT,
  TEXTBOX_BORDER,
  TEXTBOX_PADDING_TOP
} from '@/consts';

export function screenPointToCanvasPoint(screenPoint) {
  return paper.view.viewToProject(
    new paper.Point(screenPoint.x, screenPoint.y)
  );
}

export function canvasPointToScreenPoint(canvasPoint) {
  return paper.view.projectToView(
    new paper.Point(canvasPoint.x, canvasPoint.y)
  );
}

export function calcTopLeftTextBoxPointByCanvasPoint(canvasPoint, fontStyle) {
  const textHorizontalOffset = TEXTBOX_PADDING_LEFT + TEXTBOX_BORDER;
  const textVerticalOffset =
    fontStyle.lineHeight / 2 + TEXTBOX_PADDING_TOP + TEXTBOX_BORDER;
  return new paper.Point(
    canvasPoint.x - textHorizontalOffset,
    canvasPoint.y - textVerticalOffset
  );
}

export function calcTextBaselinePointByTopLeftTextboxPoint(
  canvasPoint,
  fontStyle
) {
  const MAGIC_SPEZIA_PADDING = fontStyle.lineHeight - fontStyle.fontSize - 1; // Can't explain this one ( NORMAL - 5, SUBHEADING - 7, HEADING - 9, TITLE - 11)
  const textHorizontalOffset = TEXTBOX_PADDING_LEFT + TEXTBOX_BORDER;
  const textVerticalOffset =
    fontStyle.lineHeight +
    TEXTBOX_PADDING_TOP +
    TEXTBOX_BORDER -
    MAGIC_SPEZIA_PADDING;
  return new paper.Point(
    canvasPoint.x + textHorizontalOffset,
    canvasPoint.y + textVerticalOffset
  );
}

export function calcTopLeftTextBoxByTextItemTopLeftCanvasPoint(
  textItemTopLeftCanvasPoint
) {
  const textHorizontalOffset = TEXTBOX_PADDING_LEFT + TEXTBOX_BORDER;
  const textVerticalOffset = TEXTBOX_PADDING_TOP + TEXTBOX_BORDER;
  return new paper.Point(
    textItemTopLeftCanvasPoint.x - textHorizontalOffset,
    textItemTopLeftCanvasPoint.y - textVerticalOffset
  );
}
