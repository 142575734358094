export default class SimpleEventEmitter {
  constructor() {
    this.listeners = new Map();
  }

  on(eventName, callback) {
    if (!this.listeners.has(eventName)) {
      this.listeners.set(eventName, []);
    }

    this.listeners.get(eventName).push(callback);
  }

  emit(eventName, data) {
    if (!this.listeners.has(eventName)) {
      return;
    }

    this.listeners.get(eventName).forEach(async listener => listener(data));
  }
}
