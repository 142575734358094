<template>
  <ToolButton
    ref="toolButton"
    displayName="Shape"
    class="shape-tool-button"
    :active="active"
    @click="toolButtonClicked(selectedShapeType)"
  >
    <template slot="icon">
      <div
        class="shape selected-shape"
        :class="{ [selectedShapeType]: true, inverted: active }"
      />
    </template>

    <template slot="menu">
      <div class="shapes-container">
        <div
          v-for="shapeType in availableShapeTypes"
          :key="shapeType"
          class="shape"
          :class="{
            [shapeType]: true,
            selected: selectedShapeType === shapeType
          }"
          @click="selectShapeType(shapeType)"
        ></div>
      </div>
    </template>
  </ToolButton>
</template>

<script>
import ToolButton from '@/components/toolbar/ToolButton';

export default {
  components: {
    ToolButton
  },

  props: {
    active: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      selectedShapeType: 'rectangle',
      availableShapeTypes: ['ellipse', 'rectangle', `triangle`]
    };
  },

  methods: {
    activate(shapeType) {
      this.selectedShapeType = shapeType;
      this.$emit('activated', { shapeType });
    },

    toolButtonClicked(preselectedShapeType) {
      this.activate(preselectedShapeType);
    },

    selectShapeType(shapeType) {
      this.activate(shapeType);
      this.$refs.toolButton.closeMenu();
    }
  }
};
</script>

<style scoped>
.shapes-container {
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.shape {
  width: 16px;
  height: 16px;
  border: 1px solid black;
  cursor: pointer;
}

.shape-tool-button:active .selected-shape,
.selected-shape.inverted {
  filter: invert();
  border-width: 2px;
}

.shape:not(:last-child) {
  margin-bottom: 8px;
}

.shape.selected {
  border: 1px solid #871eff;
}

.shape.ellipse {
  border-radius: 50%;
}

.shape.triangle {
  border: none;
  width: 18px;
  height: 18px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16"><polygon points="8,0 0,16 16,16" style="fill:none;stroke:black;stroke-width:1" /></svg>');
  background-repeat: no-repeat;
  background-size: 18px 18px;
}

.shape.triangle.selected {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16"><polygon points="8,0 0,16 16,16" style="fill:none;stroke:%23871eff;stroke-width:1" /></svg>');
}

.shape.triangle.inverted {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16"><polygon points="8,0 0,16 16,16" style="fill:none;stroke:black;stroke-width:2" /></svg>');
}
</style>
