export default {
  ownerName: '',
  myParticipantName: '',
  numberOfParticipants: 0,
  isMobileMode: false,
  sessionData: null,
  sessionId: null,
  role: 'editor',
  isSynchronizing: false,
  isAppInitialized: false
};
