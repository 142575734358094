/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hand': {
    width: 17,
    height: 16,
    viewBox: '0 0 17 16',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M15.419 2.043c-.203 0-.393.04-.575.108-.182-.837-.952-1.47-1.877-1.47-.407 0-.785.122-1.1.34A1.906 1.906 0 0010.164 0c-.742 0-1.387.415-1.702 1.021a1.891 1.891 0 00-1.1-.34c-1.058 0-1.926.837-1.926 1.872v6.482c0 .095-.077.163-.239.156l-1.78-.694a1.787 1.787 0 00-1.905.381 1.69 1.69 0 00-.084 2.35l2.256 2.505C4.98 15.176 6.858 16 8.826 16h1.345c3.77 0 6.831-2.975 6.831-6.638V3.574c-.007-.844-.714-1.531-1.583-1.531zm.525 7.319c0 3.098-2.592 5.617-5.78 5.617H8.82a5.86 5.86 0 01-4.35-1.92l-2.256-2.506a.689.689 0 01.035-.953.73.73 0 01.77-.157l1.78.688c.14.054.294.089.455.089.673 0 1.226-.538 1.226-1.192V2.553c0-.47.393-.85.876-.85.484 0 .876.38.876.85V7.83h1.05V1.873c0-.47.393-.852.877-.852.483 0 .875.382.875.851V7.83h1.051V2.553c0-.47.392-.85.876-.85.483 0 .875.38.875.85V7.83h1.051V3.574c0-.279.239-.51.526-.51.287 0 .525.231.525.51v5.788h.007z" _fill="#000"/></g><defs><clipPath id="clip0"><path pid="1" _fill="#fff" transform="translate(.994)" d="M0 0h16.001v16H0z"/></clipPath></defs>'
  }
})
