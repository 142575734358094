import Vue from 'vue';
import Vuex from 'vuex';
import state from './state';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import panZoom from './panZoom';
import settings from './settings';
import cursors from './cursors';
import history from './history';
import flashMessages from './flashMessages';
import canvas from './canvas';

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules: {
    settings,
    cursors,
    panZoom,
    history,
    flashMessages,
    canvas
  }
});
