<template>
  <div
    id="app"
    @click="handleAppClicked"
    @touchmove="handleTouchMove"
    @touchend="handleTouchEnd"
  >
    <FlashMessages class="flash-messages" />
    <transition name="fade">
      <div v-if="isLoading" class="loader-wrapper">
        <div class="loader">Loading...</div>
      </div>
    </transition>
    <Whiteboard
      v-if="isAppInitialized"
      class="whiteboard"
      :participantId="username"
      :userCursorColor="color"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Whiteboard from '@/components/Whiteboard';
import FlashMessages from '@/components/flashMessages/FlashMessages';
import collaborativeWhiteboardService from '@/services/collaborative-whiteboard-service';
import AwsWebsocketCommunicationProvider from './whiteboard-communication/aws-websocket-communication-provider';

const colors = ['#D6219C', '#32AF4C', '#FA7454', '#80C7F5'];

export default {
  name: 'EmbedHome',

  components: {
    Whiteboard,
    FlashMessages
  },

  data() {
    return {
      usernameInput: '',
      sessionId: this.$route.params.sessionId,
      username:
        (this.$route.query && this.$route.query.display_name) || 'Unknown',
      token:
        this.$route.query &&
        (this.$route.query.token ||
          // Backwards compatibility: support owner_token for the time being
          this.$route.query.owner_token),
      color: null,
      wasTouchMoveFired: false
    };
  },

  computed: {
    ...mapState(['isAppInitialized', 'isMobileMode']),
    ...mapGetters(['isLoading'])
  },

  created() {
    this.color = colors[Math.floor(Math.random() * colors.length)];
  },

  async mounted() {
    this.setSessionId(this.sessionId);
    const participantToken = this.token;
    this.joinSession({
      participantToken
    });

    const communicationProvider = new AwsWebsocketCommunicationProvider(
      this.sessionId,
      participantToken
    );
    collaborativeWhiteboardService.init(this.sessionId, communicationProvider);

    await this.refreshSessionData();
    this.initApp();
    this.setIsAppInitialized(true);
  },

  methods: {
    ...mapActions([
      'initApp',
      'joinSession',
      'updateSession',
      'setIsAppInitialized',
      'setSessionId',
      'refreshSessionData'
    ]),

    emitClickedAnywhereEvent() {
      collaborativeWhiteboardService.emitClickedAnywhereEvent();
    },

    handleTouchMove() {
      this.wasTouchMoveFired = true;
    },

    handleTouchEnd() {
      // If the user dragged their finger on the screen, we do not want to emit the clicked-anywhere event
      if (!this.wasTouchMoveFired) {
        this.emitClickedAnywhereEvent();
      }

      this.wasTouchMoveFired = false;
    },

    handleAppClicked() {
      if (!this.isMobileMode) {
        this.emitClickedAnywhereEvent();
      }
    }
  }
};
</script>

<style>
@import './style/v-tooltip.css';

html,
body {
  margin: 0;
  overflow: hidden;
  height: 100%;
}

#app {
  display: flex;
  height: 100%;
  background: transparent;
}

.whiteboard {
  flex-grow: 1;
}

.flash-messages >>> .Vlt-flash {
  left: 20px;
  width: 380px;
  margin-left: 0;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<style scoped>
.loader-wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #edededaa;
}
</style>
