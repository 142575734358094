var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"whiteboardContainer",staticClass:"whiteboard-container",class:{ locked: _vm.isWhiteboardLocked, mobile: _vm.isMobileMode }},[_c('StopSharingModal',{ref:"stopSharingModal"}),_c('ClearBoardModal',{ref:"clearBoardModal"}),_c('RenameWhiteboardModal',{ref:"renameWhiteboardModal"}),(_vm.isMobileMode)?_c('div',{staticClass:"mobile-floating-board-name"},[_c('span',[_vm._v(_vm._s(_vm.title))])]):_vm._e(),(!_vm.isMobileMode)?_c('div',{staticClass:"topbar"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"document-name"},[(_vm.isWhiteboardLocked)?_c('v-icon',{attrs:{"iconName":"lock","xs":""}}):_vm._e(),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.title),expression:"title"}],staticClass:"document-title text-ellipsis",class:{ 'title-editable': _vm.isWhiteboardOwner },on:{"click":_vm.openEditBoardNameModal}},[_vm._v(_vm._s(_vm.title))])],1)]),(_vm.showLockedIndication)?_c('div',{staticClass:"locked-whiteboard-indication"},[_c('span',{staticClass:"locked-text"},[_vm._v("The owner has locked the whiteboard")])]):_c('div',{staticClass:"toolbar"},[_c('ToolButton',{attrs:{"displayName":"Move","active":_vm.activeToolType === _vm.toolTypes.HAND},on:{"activated":function($event){return _vm.userActivateTool({ type: _vm.toolTypes.HAND })}}},[_c('template',{slot:"icon"},[_c('v-icon',{attrs:{"iconName":_vm.activeToolType === _vm.toolTypes.HAND
                ? 'hand-fill-negative'
                : 'hand'}})],1)],2),_c('ToolButton',{attrs:{"displayName":"Selection","active":_vm.activeToolType === _vm.toolTypes.SELECT},on:{"activated":function($event){return _vm.userActivateTool({ type: _vm.toolTypes.SELECT })}}},[_c('template',{slot:"icon"},[_c('v-icon',{attrs:{"iconName":_vm.activeToolType === _vm.toolTypes.SELECT
                ? 'select-fill-negative-2'
                : 'select'}})],1)],2),_c('TextToolButton',{attrs:{"active":_vm.activeToolType === _vm.toolTypes.TEXT},on:{"activated":function($event){return _vm.userActivateTool({ type: _vm.toolTypes.TEXT })}}}),_c('PenToolButton',{attrs:{"active":_vm.activeToolType === _vm.toolTypes.PEN},on:{"activated":(options) => _vm.userActivateTool({ type: _vm.toolTypes.PEN, options })}},[_c('template',{slot:"icon"},[_c('v-icon',{attrs:{"iconName":"pen"}})],1)],2),_c('ShapeToolButton',{attrs:{"active":_vm.activeToolType === _vm.toolTypes.SHAPE},on:{"activated":(options) => _vm.userActivateTool({ type: _vm.toolTypes.SHAPE, options })}}),_c('ArrowToolButton',{attrs:{"active":_vm.activeToolType === _vm.toolTypes.ARROW},on:{"activated":(options) => _vm.userActivateTool({ type: _vm.toolTypes.ARROW, options })}}),_c('LineToolButton',{attrs:{"active":_vm.activeToolType === _vm.toolTypes.LINE},on:{"activated":(options) => _vm.userActivateTool({ type: _vm.toolTypes.LINE, options })}}),_c('ColorSelectionToolButton',{attrs:{"selectedColor":_vm.color},on:{"select":_vm.selectColor}}),_c('ToolButton',{attrs:{"displayName":"Eraser","active":_vm.activeToolType === _vm.toolTypes.ERASER},on:{"activated":function($event){return _vm.userActivateTool({ type: _vm.toolTypes.ERASER })}}},[_c('template',{slot:"icon"},[_c('v-icon',{attrs:{"iconName":_vm.activeToolType === _vm.toolTypes.ERASER
                ? 'eraser-fill-negative'
                : 'eraser'}})],1)],2),_c('div',{staticClass:"toolbar-separator"}),_c('ToolButton',{attrs:{"displayName":"Undo","disabled":!_vm.hasUndoActions},on:{"activated":_vm.undo}},[_c('template',{slot:"icon"},[_c('v-icon',{attrs:{"iconName":"undo"}})],1)],2),_c('ToolButton',{attrs:{"displayName":"Redo","disabled":!_vm.hasRedoActions},on:{"activated":_vm.redo}},[_c('template',{slot:"icon"},[_c('v-icon',{attrs:{"iconName":"redo"}})],1)],2)],1),_c('div',{staticClass:"board-actions"},[(_vm.isWhiteboardOwner)?_c('VDropdown',{staticClass:"owner-menu-dropdown",attrs:{"minWidth":"140","openToTheLeft":"","items":_vm.ownerMenuDropdownItems},on:{"change":(layout) => _vm.runOwnerAction(layout.value)}},[_c('v-button',{staticClass:"owner-menu-button",attrs:{"slot":"toggle-button","app":"","sm":"","secondary":"","outline":"","iconName":"Vlt-icon-more-v-negative"},slot:"toggle-button"})],1):_c('v-button',{staticClass:"action-button",attrs:{"app":"","sm":"","secondary":"","outline":"","iconName":"download","tooltip":{ content: 'Download Board' }},on:{"click":_vm.downloadCanvas}}),(_vm.isWhiteboardOwner)?_c('v-button',{attrs:{"app":"","sm":"","destructive":""},on:{"click":_vm.stopSharingWhiteboard}},[_vm._v("Stop Sharing")]):_vm._e()],1)]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],staticClass:"canvas-container"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeToolType === _vm.toolTypes.TEXT),expression:"activeToolType === toolTypes.TEXT"}],staticClass:"text-box-container"},[_c('div',{ref:"whiteboardTextBox",staticClass:"text-box",attrs:{"contentEditable":"true","spellcheck":"false"},on:{"click":function($event){$event.stopPropagation();}}})]),_c('div',{staticClass:"cursors-container"},_vm._l((_vm.remoteCursors),function(cursor){return _c('RemoteCursor',{key:cursor.name,staticClass:"remote-cursor",style:({
          transform: `translate(${cursor.pointOnScreen.x}px, ${cursor.pointOnScreen.y}px)`
        }),attrs:{"name":cursor.name,"color":cursor.color,"visible":cursor.isVisible}})}),1),_c('div',{staticClass:"pan-zoom-status-overlay"},[_c('PanZoomStatus')],1),_c('canvas',{ref:"whiteboardCanvas",staticClass:"whiteboard-canvas",class:_vm.cursorClass,attrs:{"resize":""},on:{"mouseover":_vm.attachCursor,"mouseleave":_vm.detachCursor}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }