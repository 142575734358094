/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hand-fill-negative': {
    width: 17,
    height: 16,
    viewBox: '0 0 17 16',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M15.419 3.064a.522.522 0 00-.525.51V7.83h-1.051V2.553a.864.864 0 00-.876-.85.864.864 0 00-.876.85V7.83h-1.05V1.873a.864.864 0 00-.876-.852.864.864 0 00-.876.852V7.83h-1.05V2.553a.864.864 0 00-.877-.85.864.864 0 00-.875.85v6.482c0 .654-.554 1.192-1.226 1.192a1.13 1.13 0 01-.456-.089l-1.78-.688a.73.73 0 00-.77.157.688.688 0 00-.035.953l2.256 2.506a5.878 5.878 0 004.35 1.913h1.346c3.187 0 5.78-2.52 5.78-5.617V3.575a.528.528 0 00-.533-.511z" _fill="#fff"/><path pid="1" d="M15.419 2.043c-.203 0-.392.04-.575.108-.182-.837-.952-1.47-1.877-1.47-.406 0-.785.122-1.1.34A1.906 1.906 0 0010.165 0c-.743 0-1.388.415-1.703 1.021a1.891 1.891 0 00-1.1-.34c-1.058 0-1.926.837-1.926 1.872v6.482c0 .095-.077.163-.238.156l-1.78-.694a1.787 1.787 0 00-1.906.381 1.69 1.69 0 00-.084 2.35l2.256 2.505C4.98 15.176 6.858 16 8.827 16h1.345c3.769 0 6.83-2.975 6.83-6.638V3.574c-.007-.844-.714-1.531-1.583-1.531zm.525 7.319c0 3.098-2.592 5.617-5.78 5.617H8.82a5.86 5.86 0 01-4.351-1.92l-2.256-2.506a.689.689 0 01.035-.953.73.73 0 01.77-.157l1.78.688c.14.054.294.089.456.089.672 0 1.226-.538 1.226-1.192V2.553c0-.47.392-.85.875-.85.484 0 .876.38.876.85V7.83h1.05V1.873c0-.47.393-.852.877-.852.483 0 .875.382.875.851V7.83h1.051V2.553c0-.47.393-.85.876-.85s.876.38.876.85V7.83h1.05V3.574c0-.279.239-.51.526-.51.287 0 .525.231.525.51v5.788h.007z" _fill="#000"/></g><defs><clipPath id="clip0"><path pid="2" _fill="#fff" transform="translate(.994)" d="M0 0h16.001v16H0z"/></clipPath></defs>'
  }
})
