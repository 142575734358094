import throttle from 'lodash.throttle';
import SimpleEventEmitter from '@/utils/SimpleEventEmitter';
import LogicalPaperGroup from '@/utils/logical-paper-group';
import CORNERS_ENUM from '@/enums/corner-enum';

const DRAGGING_THROTTLE_MILLISECONDS = 30;

export default class SelectionController extends SimpleEventEmitter {
  constructor() {
    super();
    this.selectedItemsGroup = null;
  }

  getSelectionCornerByPoint(point) {
    return (
      this.selectedItemsGroup && this.selectedItemsGroup.getCornerByPoint(point)
    );
  }

  getSelectionSize() {
    return this.selectedItemsGroup.bounds.size;
  }

  selectedItemsContain(point) {
    return (
      this.selectedItemsGroup && this.selectedItemsGroup.bounds.contains(point)
    );
  }

  resizeSelectedItems(newSize, draggedCorner = CORNERS_ENUM.BOTTOM_RIGHT) {
    if (!this.selectedItemsGroup.data.sizeBeforeResizing) {
      this.selectedItemsGroup.data.sizeBeforeResizing = this.selectedItemsGroup.bounds.size;
      this.selectedItemsGroup.data.centerBeforeResizing = this.selectedItemsGroup.bounds.center;
    }

    this.selectedItemsGroup.resize(newSize, draggedCorner);
  }

  deselectAllItems() {
    if (!this.selectedItemsGroup) {
      return;
    }

    this.finalizeSelectedItemsMove();
    this.selectedItemsGroup.deselect();
    this.selectedItemsGroup = null;
  }

  setSelectedItemsPosition = throttle(point => {
    if (!this.selectedItemsGroup) {
      return;
    }
    if (!this.selectedItemsGroup.data.positionBeforeMoving) {
      this.selectedItemsGroup.data.positionBeforeMoving = this.selectedItemsGroup.position;
    }

    this.selectedItemsGroup.position = point;
  }, DRAGGING_THROTTLE_MILLISECONDS);

  moveSelectedItemsBy(moveBy) {
    this.setSelectedItemsPosition(this.selectedItemsGroup.position.add(moveBy));
  }

  finalizeSelectedItemsMove() {
    if (!this.selectedItemsGroup) {
      return;
    }

    const position = this.selectedItemsGroup.position;
    const originalPosition = this.selectedItemsGroup.data.positionBeforeMoving;

    if (!originalPosition) {
      return;
    }

    this.emit('items-move-finalize', {
      position: { x: position.x, y: position.y },
      originalPosition: { x: originalPosition.x, y: originalPosition.y },
      items: this.selectedItemsGroup.items
    });

    this.selectedItemsGroup.data.positionBeforeMoving = null;
  }

  finalizeSelectedItemsResize() {
    if (!this.selectedItemsGroup) {
      return;
    }

    const size = this.selectedItemsGroup.bounds.size;
    const sizeBeforeResizing = this.selectedItemsGroup.data.sizeBeforeResizing;
    const centerBeforeResizing = this.selectedItemsGroup.data
      .centerBeforeResizing;

    if (!sizeBeforeResizing) {
      return;
    }

    this.emit('items-resize-finalize', {
      size: { width: size.width, height: size.height },
      sizeBeforeResizing: {
        width: sizeBeforeResizing.width,
        height: sizeBeforeResizing.height
      },
      center: {
        x: this.selectedItemsGroup.bounds.center.x,
        y: this.selectedItemsGroup.bounds.center.y
      },
      centerBeforeResizing: {
        x: centerBeforeResizing.x,
        y: centerBeforeResizing.y
      },
      items: this.selectedItemsGroup.items
    });

    this.selectedItemsGroup.data.sizeBeforeResizing = null;
    this.selectedItemsGroup.data.centerBeforeResizing = null;
  }

  getSelectedItems() {
    if (!this.selectedItemsGroup) {
      return [];
    }

    return this.selectedItemsGroup.items;
  }

  selectItems(items) {
    if (items.length === 0) {
      return;
    }

    this.selectedItemsGroup = new LogicalPaperGroup(items);
    this.selectedItemsGroup.select();
  }

  getSelectedItemsPosition() {
    if (!this.selectedItemsGroup) {
      return null;
    }
    return this.selectedItemsGroup.position;
  }

  selectedItemsClicked() {
    if (!this.selectedItemsGroup) {
      return;
    }

    this.emit('selected-items-clicked', this.selectedItemsGroup.items);
  }

  refreshSelectedItems() {
    if (!this.selectedItemsGroup) {
      return;
    }

    this.selectedItemsGroup.refreshBounds();

    // If the group is empty after refreshing it, it can be removed altogether
    if (this.selectedItemsGroup.items.length === 0) {
      this.deselectAllItems();
    }
  }
}
