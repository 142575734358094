/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'download': {
    width: 17,
    height: 16,
    viewBox: '0 0 17 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.5.667a.5.5 0 00-.5.5V7H5.5a.5.5 0 00-.377.83l3.5 4a.5.5 0 00.753 0l3.5-4A.5.5 0 0012.5 7H11V1.167a.5.5 0 00-.5-.5h-3zM8 7.5V1.667h2V7.5a.5.5 0 00.5.5h.898l-2.399 2.74L6.601 8H7.5a.5.5 0 00.5-.5z" _fill="#000"/><path pid="1" d="M1.999 7.5a.5.5 0 01.5-.5h1.333a.5.5 0 100-1H2.5a1.5 1.5 0 00-1.5 1.5v7a1.5 1.5 0 001.5 1.5h13A1.5 1.5 0 0017 14.5v-7A1.5 1.5 0 0015.5 6h-1.334a.5.5 0 000 1H15.5a.5.5 0 01.5.5v7a.5.5 0 01-.5.5H2.499a.5.5 0 01-.5-.5v-7z" _fill="#000"/>'
  }
})
