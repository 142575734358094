<template>
  <ToolButton
    ref="toolButton"
    displayName="Arrow"
    :active="active"
    @click="toolButtonClicked()"
  >
    <template slot="icon">
      <v-icon class="icon" :class="{ active }" :iconName="iconName" />
    </template>

    <template slot="menu">
      <div class="stroke-sizes-container">
        <div
          v-for="(strokeWidth, index) in availableStrokeWidths"
          :key="strokeWidth"
          class="stroke-size-container"
          :class="{ selected: selectedStrokeWidth === strokeWidth }"
          @click="selectStrokeWidth(strokeWidth)"
        >
          <div
            class="stroke-size"
            :style="{ '--stroke-size': `${index + 1}px` }"
          />
        </div>
      </div>
    </template>
  </ToolButton>
</template>

<script>
import ToolButton from '@/components/toolbar/ToolButton';

const STROKE_WIDTHS = [3, 5, 7];

export default {
  components: {
    ToolButton
  },

  props: {
    active: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      selectedStrokeWidth: STROKE_WIDTHS[0],
      availableStrokeWidths: STROKE_WIDTHS
    };
  },

  computed: {
    iconName() {
      return this.active ? 'arrow-fill-negative' : 'arrow';
    }
  },

  methods: {
    activate(strokeWidth) {
      this.selectedStrokeWidth = strokeWidth;
      this.$emit('activated', { strokeWidth });
    },

    toolButtonClicked() {
      this.activate(this.selectedStrokeWidth);
    },

    selectStrokeWidth(strokeWidth) {
      this.$refs.toolButton.closeMenu();
      this.activate(strokeWidth);
    }
  }
};
</script>

<style scoped>
.stroke-sizes-container {
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.stroke-size-container {
  display: flex;
  align-items: center;
  height: 12px;
  cursor: pointer;
}

.stroke-size-container.selected .stroke-size {
  background: #871eff;
}

.stroke-size {
  width: 18px;
  height: var(--stroke-size);
  background: black;
  border-radius: 2px;
}

.icon.active {
  fill: white;
}

.separator {
  height: 1px;
  background: #cccccc;
  margin: 0px 5px;
}
</style>
