<template>
  <div
    v-on-clickaway="hide"
    class="Vlt-dropdown unselectable"
    :class="{
      'Vlt-dropdown--expanded': isExpanded,
      'Vlt-dropdown--full-width': fullWidth
    }"
  >
    <div
      data-cy="toggle-container"
      class="toggle-container"
      :class="{ 'default-cursor': defaultCursor }"
      @click="toggle"
    >
      <slot name="toggle-button">
        <div class="Vlt-dropdown__trigger Vlt-dropdown__trigger--btn">
          <v-button class="toggle-button" secondary>
            <span class="Vlt-dropdown__selection">{{ label }}</span>
          </v-button>
        </div>
      </slot>
    </div>
    <div
      class="Vlt-dropdown__panel"
      :class="{ 'open-to-the-left': openToTheLeft }"
      :style="{ 'min-width': `${minWidth}px` }"
      @mouseleave="closeOnMouseLeave && hide()"
    >
      <div
        v-if="isExpanded"
        v-observe-keyboard.prevent.l2.esc="hide"
        class="Vlt-dropdown__panel__content"
      >
        <div
          v-for="(item, index) in items"
          :key="index"
          data-cy="dropdown-option"
          class="Vlt-dropdown__link"
          @click="onSelection(item)"
        >
          <slot name="item" :item="item">
            <span class="Vlt-dropdown__label">
              <v-icon
                v-if="item.icon"
                class="link-icon"
                :iconName="item.icon"
              />
              <span>{{ item.label }}</span>
              <v-icon
                v-if="item.checked"
                data-cy="vdropdown-checked-icon"
                class="checked-icon"
                iconName="Vlt-icon-check-bold"
              />
            </span>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';

export default {
  name: 'VDropdown',

  mixins: [clickaway],

  props: {
    items: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    closeOnMouseLeave: {
      type: Boolean,
      default: false
    },
    openToTheLeft: {
      type: Boolean,
      default: false
    },
    minWidth: {
      type: String,
      default: '200'
    },
    defaultCursor: {
      type: Boolean,
      default: false
    },
    closeOnSelection: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isExpanded: false
    };
  },

  computed: {},

  methods: {
    toggle() {
      this.isExpanded = !this.isExpanded;
    },

    hide() {
      this.isExpanded = false;
    },

    onSelection(item) {
      this.$emit('change', item);
      if (this.closeOnSelection) {
        this.isExpanded = false;
      }
    }
  }
};
</script>

<style scoped>
.Vlt-dropdown__trigger.Vlt-dropdown__trigger--btn .Vlt-btn:after {
  position: absolute;
  right: 8px;
  bottom: 16px;
}

.Vlt-dropdown__selection {
  font-size: 16px;
}

/* Check with Dave */
.Vlt-dropdown--expanded >>> .small-panel {
  max-height: 200px !important;
  overflow-y: scroll !important;
}

.Vlt-dropdown__panel__content {
  margin-top: 4px;
}

.open-to-the-left {
  right: 0;
}

.header {
  position: relative;
  height: 30px;
  width: 100%;
  padding-top: 5px !important;
  text-align: left;
  font-size: 14px;
}

.item-option {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 15px;
  padding: 10px 12px;
  cursor: pointer;
}
.item-option:not(:last-child)::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 12px;
  left: 12px;
  border-bottom: 1px solid #e7ebee;
}

.item-option-no-border:not(:last-child)::after {
  border-bottom: none !important;
}
.item-option:hover {
  background-color: transparent;
}
.selected-item {
  font-weight: bold;
}
.disabled {
  padding-right: 20px;
  opacity: 0.6;
}
.disabled-option {
  opacity: 0.6;
  cursor: default;
  pointer-events: none;
}

.icon-check {
  width: 16px;
  height: 16px;
  margin-left: auto;
}

.Vlt-form__element--big label {
  top: 0 !important;
}

.Vlt-dropdown__trigger .Vlt-btn--outline {
  color: #131415 !important;
  border: none;
  background-color: white;
}

.toggle-button {
  border: 1px solid #4d4d4d;
  border-radius: 6px;
  width: 100%;
  text-align: left;
  padding: 10px 2px;
  background-color: #0d0d0d;
}

.toggle-container {
  display: flex;
  cursor: pointer;
}

.default-cursor {
  cursor: default;
}

.Vlt-dropdown__label {
  display: flex;
  align-items: center;
  flex: 1;
}

.link-icon {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  fill: black;
}

.checked-icon {
  width: 12px;
  height: 12px;
  margin-left: auto;
  fill: black;
}

.Vlt-dropdown__selection {
  padding-left: 16px;
}
</style>
