/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'select': {
    width: 17,
    height: 16,
    viewBox: '0 0 17 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.146 8.144l5.25-2.625L1.583 1.58l3.938 11.812 2.625-5.25zM5.336 16L.002 0l16.001 5.333L8.891 8.89 5.336 16z" _fill="#000"/>'
  }
})
