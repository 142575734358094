/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'eraser': {
    width: 20,
    height: 19,
    viewBox: '0 0 20 19',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.705 17.882l-4.02-4.054a2.362 2.362 0 010-3.321L10.418.69a2.325 2.325 0 013.307 0l4.595 4.636c.91.917.91 2.403 0 3.321l-9.156 9.235h8.992V19H1.044v-1.118h3.66zM12.93 1.477a1.207 1.207 0 00-1.72 0L6.834 5.894l6.322 6.376 4.372-4.41a1.244 1.244 0 000-1.747L12.93 1.477zM1.479 11.294l4.567-4.606 6.322 6.376-4.778 4.818H6.28l-4.8-4.84a1.244 1.244 0 010-1.748z" _fill="#212121"/>'
  }
})
