import CanvasPanZoomController from '@/services/canvas-pan-zoom-controller';
import TextController from '@/services/text-controller';
import CanvasBoundariesController from '@/services/canvas-boundaries-controller';
import SelectionController from '@/services/selection-controller';
import CursorsTracker from '@/whiteboard-communication/cursors-tracker';
import ParticipantProvider from '@/whiteboard-communication/participant-provider';
import WhiteboardSynchronizer from '@/whiteboard-communication/whiteboard-synchronizer';
import SettingsProvider from '@/whiteboard-paper-tools/settings-provider';
import ToolsController from '@/services/tools-controller';
import ActionsController from '@/services/actions-controller';
import HistoryController from '@/services/history-controller';
import PathDrawingController from '@/services/path-drawing-controller';
import EraserController from '@/services/eraser-controller';
import TelemetriesService from '@/services/telemetries-service';
import IFrameCommunicationProvider from '@/whiteboard-communication/iframe-communication-provider';
import paper from 'paper';
import '@/utils/paper-extensions';
import ClockService from './clock-service';

/**
 * Singleton that contains all the business logic required for a single collaborative whiteboard
 */
class CollaborativeWhiteboardService {
  constructor() {
    this.iframeCommunication = new IFrameCommunicationProvider();
    this.settingsProvider = new SettingsProvider();
    this.textController = new TextController();
    this.toolsController = new ToolsController();
    this.history = new HistoryController();
    this.canvasBoundariesController = new CanvasBoundariesController();
    this.canvasPanZoomController = new CanvasPanZoomController(
      this.canvasBoundariesController
    );
    this.selectionController = new SelectionController();
    this.pathDrawingController = new PathDrawingController();
    this.eraserController = new EraserController();
    this.telemetries = new TelemetriesService();
    this.clock = new ClockService();
  }

  init(sessionId, communicationProvider) {
    this.sessionId = sessionId;
    this.communicationProvider = communicationProvider;
    this.telemetries.init(this.iframeCommunication);

    this.participantProvider = new ParticipantProvider(
      this.communicationProvider
    );
    this.cursorsTracker = new CursorsTracker(
      this.communicationProvider,
      this.participantProvider
    );
    this.synchronizer = new WhiteboardSynchronizer(
      this.communicationProvider,
      this.participantProvider,
      this.cursorsTracker,
      this.clock,
      this.selectionController
    );

    this.dependencies = {
      settingsProvider: this.settingsProvider,
      canvasPanZoomController: this.canvasPanZoomController,
      canvasBoundariesController: this.canvasBoundariesController,
      textController: this.textController,
      participantProvider: this.participantProvider,
      synchronizer: this.synchronizer,
      toolsController: this.toolsController,
      selectionController: this.selectionController,
      pathDrawingController: this.pathDrawingController,
      eraserController: this.eraserController,
      history: this.history,
      telemetries: this.telemetries,
      clock: this.clock
    };

    this.actionsController = new ActionsController(this.dependencies);
    this.actionsController.init();
  }

  attachToCanvas(canvasElement, textBoxElement) {
    this.dependencies.canvasElement = canvasElement;
    paper.setup(canvasElement);
    // Hide default selection handles because we draw custom ones
    paper.settings.handleSize = 0;
    this.textController.init(textBoxElement);

    this.cursorsTracker.attachToCanvas(canvasElement);
    this.synchronizer.init(this.sessionId);
    this.toolsController.init(this.dependencies);
    this.canvasBoundariesController.init();
  }

  activateToolByType(toolType, options) {
    this.toolsController.activateTool(toolType, options);
  }

  deactivateToolByType(toolType) {
    this.toolsController.deactivateTool(toolType);
  }

  stopSharingWhiteboard() {
    this.iframeCommunication.emit('stop-whiteboard');
  }

  emitTelemetry(payload) {
    this.telemetries.emitTelemetry(payload);
  }

  emitClickedAnywhereEvent() {
    this.iframeCommunication.emit('clicked-anywhere');
  }

  emitWhiteboardLoadedEvent() {
    this.iframeCommunication.emit('whiteboard-loaded');
  }

  syncBoard() {
    return this.synchronizer.syncBoard();
  }
}

export default new CollaborativeWhiteboardService();
