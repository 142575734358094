/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'text-bold': {
    width: 12,
    height: 16,
    viewBox: '0 0 12 16',
    data: '<path pid="0" d="M0 15.333v-1.58l.867-.273a.667.667 0 00.466-.636V3.17a.667.667 0 00-.484-.641L0 2.287V.667h6.74c3.464 0 4.436 1.58 4.436 3.585 0 1.925-1.235 2.999-2.47 3.404v.06C11.014 7.98 12 9.308 12 11.333c0 2.998-2.262 4-5.442 4H0zm5.443-8.508c1.458 0 2.755-.345 2.755-2.067 0-1.66-1.053-1.884-2.451-1.884h-1.08v3.951h.776zm.588 6.32c1.701 0 2.815-.405 2.815-2.167 0-1.682-1.134-2.127-2.511-2.127H4.667v4.294H6.03z" _fill="#212121"/>'
  }
})
