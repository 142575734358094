export default {
  isWhiteboardOwner: state => state.role === 'owner',

  title: state =>
    state.sessionData ? state.sessionData.boardName : 'Untitled Board',

  isWhiteboardLocked: state => state.sessionData && state.sessionData.isLocked,

  isLoading: state => !state.isAppInitialized || state.isSynchronizing,

  isWhiteboardReadonly: (state, getters) =>
    getters.isWhiteboardLocked && !getters.isWhiteboardOwner
};
