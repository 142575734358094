/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'text-line-through': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M.833.167h14.334v3h-1v-2H8.5V7.5h7.333v1H.167v-1H7.5V1.167H1.833v2h-1v-3zM7.5 14.833H4.167v1h7.666v-1H8.5v-4.667h-1v4.667z" _fill="#212121"/>'
  }
})
