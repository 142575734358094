/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow': {
    width: 17,
    height: 16,
    viewBox: '0 0 17 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.16 15.024a.571.571 0 10.809.809L15.85 1.95v6.62a.571.571 0 101.143 0v-8A.57.57 0 0016.422 0h-8a.571.571 0 100 1.143h6.62L1.161 15.024z" _fill="#000"/>'
  }
})
