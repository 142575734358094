/**
 * Helps maintain a synchronous queue of intervals (i.e. only one interval is running a time)
 * This design means all intervals must have some condition that stops them, otherwise the next interval will never run
 */
export default class IntervalQueue {
  constructor() {
    this.intervals = [];
    this.currentInterval = null;
    this._onceEmptyCallback = null;
    this._isStopped = false;
  }

  enqueueInterval({ callback, stopCondition, gapMilliseconds }) {
    this.intervals.push({ callback, stopCondition, gapMilliseconds });
    this.tryDequeueInterval();
  }

  tryDequeueInterval() {
    if (this.currentInterval || this._isStopped) {
      return;
    }

    if (this.intervals.length === 0) {
      if (this._onceEmptyCallback) {
        this._onceEmptyCallback();
        this._onceEmptyCallback = null;
      }
      return;
    }

    const { callback, stopCondition, gapMilliseconds } = this.intervals.shift();
    this.currentInterval = setInterval(() => {
      if (stopCondition() || this._isStopped) {
        clearInterval(this.currentInterval);
        this.currentInterval = null;
        this.tryDequeueInterval();
        return;
      }

      callback();
    }, gapMilliseconds);
  }

  onceEmpty(callback) {
    if (!this.currentInterval && this.intervals.length === 0) {
      callback();
      return;
    }

    this._onceEmptyCallback = callback;
  }

  stop() {
    this._isStopped = true;
    this.intervals = [];

    if (this.currentInterval) {
      clearInterval(this.currentInterval);
      this.currentInterval = null;
    }
  }

  resume() {
    this._isStopped = false;
  }
}
