import paper from 'paper';
import WhiteboardTool from './whiteboard-tool';
import {
  canvasPointToScreenPoint,
  calcTopLeftTextBoxPointByCanvasPoint,
  calcTextBaselinePointByTopLeftTextboxPoint,
  calcTopLeftTextBoxByTextItemTopLeftCanvasPoint,
  screenPointToCanvasPoint
} from '@/utils/points-converters';
import { getTextStyleByName } from '@/utils/text-utils';

export default class TextTool extends WhiteboardTool {
  constructor({
    settingsProvider,
    textController,
    canvasPanZoomController,
    telemetries
  }) {
    super();
    this.settingsProvider = settingsProvider;
    this.textController = textController;
    this.panZoomController = canvasPanZoomController;
    this.telemetries = telemetries;

    this.canvasTextBoxPoint = null;

    // If the user is zooming while the text box is visible,
    // we want to adjust the text box size and position to the new zoom factor
    this.panZoomController.on('pan', () => {
      this._updateTextBoxPositionAndSize(this.settingsProvider.zoomFactor);
    });
    this.panZoomController.on('zoom', ({ factor }) => {
      this._updateTextBoxPositionAndSize(factor);
    });
  }

  onMouseDown(ev) {
    // If the user clicked the canvas and already wrote something, we will draw the text to the canvas.
    // If the user didn't write anything yet, we'll just show the text box on the position he clicked.
    if (
      this.textController.textBoxController.isTextBoxVisible &&
      this.textController.textBoxController.textBoxContent !== undefined
    ) {
      return this._drawTextToCanvas();
    }
    const textItem = this.textController.getClickedTextItem(ev.downPoint);
    if (textItem) {
      this.canvasTextBoxPoint = calcTopLeftTextBoxByTextItemTopLeftCanvasPoint(
        textItem.bounds.topLeft
      );
      this.textController.textItemClicked(textItem);
    } else {
      // Display new empty textbox
      this.canvasTextBoxPoint = calcTopLeftTextBoxPointByCanvasPoint(
        ev.point,
        getTextStyleByName(this.settingsProvider.textStyle.fontStyle)
      );
      const textBoxPoint = canvasPointToScreenPoint(this.canvasTextBoxPoint);
      this.textController.displayTextBox(textBoxPoint, this.settingsProvider);
    }
  }

  onKeyDown(ev) {
    if (ev.key === 'escape') {
      this._drawTextToCanvas();
    }
  }

  activate(options) {
    super.activate();

    if (options && options.textItem) {
      this.textItemToUpdate = options.textItem;
      this.canvasTextBoxPoint = calcTopLeftTextBoxByTextItemTopLeftCanvasPoint(
        this.textItemToUpdate.bounds.topLeft
      );
    }
  }

  deactivate() {
    this._drawTextToCanvas();
    this.textItemToUpdate = null;
    this.canvasTextBoxPoint = null;
  }

  _drawTextToCanvas() {
    if (this.textItemToUpdate) {
      // Editing existing text item
      this.textController.updateTextItem(this.textItemToUpdate);
      this.telemetries.emitTelemetry({
        name: 'use-tool',
        data: {
          type: 'TEXT',
          color: this.settingsProvider.textColor,
          action: 'UPDATE'
        }
      });
    } else if (this.textController.textBoxController.textBoxContent) {
      // Adding new text item
      const textItem = new paper.PointText(
        calcTextBaselinePointByTopLeftTextboxPoint(
          screenPointToCanvasPoint(
            this.textController.textBoxController.textBoxPosition
          ),
          getTextStyleByName(this.settingsProvider.textStyle.fontStyle)
        )
      );
      // TODO: Make text items resize themselves by their fontsize
      textItem.data.disableResizing = true;
      this.textController.addNewTextItem(textItem);
      this.telemetries.emitTelemetry({
        name: 'use-tool',
        data: {
          type: 'TEXT',
          color: this.settingsProvider.textColor,
          action: 'ADD '
        }
      });
    }
    this.canvasTextBoxPoint = null;
    this.textItemToUpdate = null;
    this.textController.textBoxController.resetTextBox();
  }

  _updateTextBoxPositionAndSize(zoomFactor) {
    if (this.textController.textBoxController.isTextBoxVisible) {
      this.textController.textBoxController.setSizeByZoomFactor(zoomFactor);
      const textBoxPoint = canvasPointToScreenPoint(this.canvasTextBoxPoint);
      this.textController.textBoxController.setPosition(textBoxPoint);
    }
  }
}
