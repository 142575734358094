import paper from 'paper';

paper.Group.prototype.ungroup = function() {
  if (!this.parent) {
    return;
  }

  this.parent.insertChildren(this.index, this.removeChildren());
  this.remove();
};

// NOTE: Layer extends Group, so this is useful for both
paper.Group.prototype.getChildrenByIds = function(ids) {
  const children = new Array();
  for (const child of this.children) {
    if (ids.includes(child.data.id)) {
      children.push(child);
    }

    // If the child has children of its own, search through them too
    if (child.getChildrenByIds) {
      children.push(...child.getChildrenByIds(ids));
    }
  }

  return children;
};

paper.Group.prototype.getChildById = function(id) {
  return this.children.find(item => item.data.id === id);
};

/**
 * Creates a temporary group for the execution of a callback, then reverts the grouping
 * so that indexes are restored too
 * @param {*} items Items to be placed into the temporary group
 * @param {*} callback Will be executed with the temporary group as the 1st parameter
 */
paper.Group.executeOnTemporaryGroup = function(items, callback) {
  items.forEach(item => (item.data._indexBeforeTemporaryGroup = item.index));
  const temporaryGroup = new paper.Group(items);
  try {
    callback(temporaryGroup);
  } finally {
    const removedChildren = temporaryGroup.removeChildren();
    removedChildren.forEach(child => {
      // Insert each child with their original index
      temporaryGroup.parent.insertChild(
        child.data._indexBeforeTemporaryGroup,
        child
      );
      delete child.data['_indexBeforeTemporaryGroup'];
    });
    temporaryGroup.remove();
  }
};

paper.Path.createFromJSON = function(serializedPathData) {
  const pathType = JSON.parse(serializedPathData)[0];
  const path = new paper[pathType]();
  path.importJSON(serializedPathData);
  return path;
};

paper.Layer.executeOnTemporaryLayer = function(callback) {
  const defaultLayer = paper.project.activeLayer;
  const temporaryLayer = new paper.Layer();

  try {
    callback(temporaryLayer);
  } finally {
    temporaryLayer.remove();
    defaultLayer.activate();
  }
};

paper.Shape.ArrowLine = function(
  point1,
  point2,
  arrowHeadLength = 10,
  arrowHeadAngle = 150
) {
  const line = new paper.Path.Line(point1, point2);
  const arrowHeadBottomPoint = line.getPointAt(
    line.length - arrowHeadLength < 0 ? 0 : line.length - arrowHeadLength
  );
  const arrowHeadVector = new paper.Point(
    point2.x - arrowHeadBottomPoint.x,
    point2.y - arrowHeadBottomPoint.y
  ).normalize(arrowHeadLength);

  const arrowHeadLeftPointVector = arrowHeadVector.rotate(arrowHeadAngle);
  const arrowHeadLeftPoint = new paper.Point(
    point2.x + arrowHeadLeftPointVector.x,
    point2.y + arrowHeadLeftPointVector.y
  );
  const arrowHeadRightPointVector = arrowHeadVector.rotate(-arrowHeadAngle);
  const arrowHeadRightPoint = new paper.Point(
    point2.x + arrowHeadRightPointVector.x,
    point2.y + arrowHeadRightPointVector.y
  );
  const arrowHead = new paper.Path({
    segments: [arrowHeadLeftPoint, point2, arrowHeadRightPoint]
  });
  return new paper.CompoundPath({
    children: [line, arrowHead]
  });
};

/**
 * Checks for standard hitTest, then also checks for hitTest with curves=true
 * @param {*} point Point to check for hit test in the global coordinates system
 * @param {*} options Optional options such as tolerance
 * @returns HitResult or null
 */
paper.Item.prototype.smartHitTest = function(point, options = undefined) {
  const tolerance = options?.tolerance || paper.settings.hitTolerance || 0;

  // Create options to hitTest curves
  const curveOptions = {
    ...(options || {}),
    // For curves, tolerance >= 1 must be used
    tolerance: Math.max(tolerance, 1),
    curves: true
  };

  return this.hitTest(point, options) || this.hitTest(point, curveOptions);
};
