<template>
  <vwc-dialog
    ref="dialog"
    v-observe-keyboard.l2.enter="confirmStopSharing"
    class="dialog vvd-root"
    headline="Stop Sharing"
    :escapeKeyAction="hide"
    @click.stop
  >
    <div slot="body" class="content">
      <div class="text">
        Are you sure you want to stop sharing this whiteboard?
      </div>
    </div>

    <div slot="footer" class="buttons-footer">
      <vwc-button
        label="Cancel"
        appearance="outlined"
        size="normal"
        @click="hide"
      />
      <vwc-button
        label="Stop Sharing"
        size="normal"
        appearance="filled"
        connotation="primary"
        @click="confirmStopSharing"
      />
    </div>
  </vwc-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      isDialogOpen: false
    };
  },

  methods: {
    ...mapActions(['stopSharing', 'emitTelemetry']),

    showModal() {
      this.$refs.dialog.showModal();
      this.isDialogOpen = true;
    },

    async confirmStopSharing() {
      if (!this.isDialogOpen) {
        return;
      }
      this.emitTelemetry({
        name: 'stop-whiteboard',
        data: { shouldDownloadBoardFlag: false }
      });
      this.stopSharing();
      this.hide();
    },

    hide() {
      this.$refs.dialog.close();
      this.isDialogOpen = false;
    }
  }
};
</script>

<style scoped>
vwc-dialog {
  --dialog-min-inline-size: 455px;
  --dialog-max-inline-size: 455px;
}

.buttons-footer {
  display: flex;
  justify-content: flex-end;
  column-gap: 8px;
  margin-top: 16px;
}
</style>
