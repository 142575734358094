export default {
  color: '#CBA1FA',
  textColor: '#000000',
  backgroundColor: '#FFFFFF',
  textStyle: {
    isBold: false,
    isItalic: false,
    fontStyle: 'NORMAL' // NORMAL / SUBHEADING / HEADING / TITLE
  }
};
