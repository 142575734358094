import { EXPORT_WHITEBOARD_INTERVAL } from '@/consts';
import LogicalPaperGroup from '@/utils/logical-paper-group';
import WhiteboardServiceApi from '@/whiteboard-communication/whiteboard-service-api';
import paper from 'paper';
import { v4 as uuidv4 } from 'uuid';
import ContinuousPathsTracker from './continuous-paths-tracker';
export default class WhiteboardSynchronizer {
  constructor(
    communication,
    participantProvider,
    cursorsTracker,
    clock,
    selectionController
  ) {
    this.communication = communication;
    this.participantProvider = participantProvider;
    this.clock = clock;
    this.selectionController = selectionController;
    this.sessionId = null;

    this.communication.onBoardEvent('path', data => {
      paper.Path.createFromJSON(data);
    });
    this.communication.onBoardEvent('remove', data => {
      this.receiveItemsToDelete(data);
    });
    this.communication.onBoardEvent('move', data => {
      this.receiveMoveUpdate(data);
    });
    this.communication.onBoardEvent('resize', data => {
      this.receiveResizeUpdate(data);
    });
    this.communication.onBoardEvent('color-update', data => {
      this.receiveColorUpdate(data);
    });
    this.communication.onBoardEvent('update-text-item', data => {
      this.receiveTextItemUpdate(data);
    });
    this.communication.onBoardEvent('add-entire-group', data => {
      this.receiveEntireGroup(data);
    });
    this.continuousPathsTracker = new ContinuousPathsTracker(
      communication,
      cursorsTracker,
      clock
    );
  }

  async init(sessionId) {
    this.sessionId = sessionId;
  }

  saveEntireWhiteboard() {
    const board = this._exportWhiteboardWithoutLocalChanges();

    WhiteboardServiceApi.saveEntireWhiteboard(this.sessionId, board);
  }

  async syncBoard(retryOnEmptyQueue = true) {
    const EXTRA_LATENCY_INTERVAL = 1000;
    let boardData;
    try {
      boardData = await WhiteboardServiceApi.getWhiteboardData(this.sessionId);
    } catch (err) {
      // We want to enable incoming events incase of an error.
      // We'll get "not found" error if the board wasn't saved yet.
      this.communication.enableIncomingBoardEvents();
      console.error(err);
      return;
    }

    if (!boardData.serializedBoard) {
      // We want to enable incoming events right away in case the board is empty
      this.communication.enableIncomingBoardEvents();
      return;
    }

    const incomingEventsQueue = this.communication.incomingEventsQueue;
    // If we don't have enough data to sync the board yet, retry later
    if (
      (incomingEventsQueue.length === 0 && retryOnEmptyQueue) ||
      (incomingEventsQueue.length > 0 &&
        incomingEventsQueue[0].timestamp > boardData.lastModified)
    ) {
      const boardAgeInMilliSeconds =
        boardData.currentTimestamp - boardData.lastModified;
      return new Promise(resolve => {
        setTimeout(async () => {
          resolve(this.syncBoard(false));
        }, EXPORT_WHITEBOARD_INTERVAL - boardAgeInMilliSeconds + EXTRA_LATENCY_INTERVAL);
      });
    }
    paper.project.activeLayer.importJSON(boardData.serializedBoard.json);
    this.communication.enableIncomingBoardEvents(boardData.lastModified);
  }

  sendRawPath(path) {
    path.data.id = path.data.id || this.generateId();
    path.data.createdByParticipantId = this.participantProvider.myParticipantId;
    path.data.createdAt = Math.ceil(this.clock.now());

    const serializedPathData = path.exportJSON();
    this.communication.sendBoardEvent('path', serializedPathData);
  }

  moveItems(moveData) {
    this.communication.sendBoardEvent('move', moveData);
  }

  resizeItems(resizeData) {
    this.communication.sendBoardEvent('resize', resizeData);
  }

  updateTextItem(update) {
    this.communication.sendBoardEvent('update-text-item', update);
  }

  receiveTextItemUpdate({ id, properties }) {
    const textItem = this._getItemById(id);
    if (textItem) {
      textItem.style = {
        ...textItem.style,
        ...properties.style
      };
      if (properties.content !== undefined) {
        textItem.content = properties.content;
      }
      if (properties.point) {
        textItem.point = new paper.Point(
          properties.point.x,
          properties.point.y
        );
      }
    }
  }

  receiveMoveUpdate({ ids, position }) {
    const itemsToMove = this._getItemsByIds(ids);
    paper.Group.executeOnTemporaryGroup(itemsToMove, group => {
      group.position = new paper.Point(position.x, position.y);
    });

    if (itemsToMove.some(item => item.bounds.selected)) {
      this.selectionController.deselectAllItems();
    }
  }

  receiveResizeUpdate({ ids, size, center }) {
    const itemsToResize = this._getItemsByIds(ids);
    const temporaryGroup = new LogicalPaperGroup(itemsToResize);
    temporaryGroup.resize(size);

    paper.Group.executeOnTemporaryGroup(itemsToResize, group => {
      group.position = new paper.Point(center.x, center.y);
    });

    if (itemsToResize.some(item => item.bounds.selected)) {
      this.selectionController.deselectAllItems();
    }
  }

  deleteItems(ids) {
    this.communication.sendBoardEvent('remove', ids);
  }

  receiveItemsToDelete(ids) {
    const itemsToDelete = this._getItemsByIds(ids);
    const anyItemSelectedLocally = itemsToDelete.some(
      item => item.bounds.selected
    );
    // removing the items separately doesn't always work for some reason
    new paper.Group(itemsToDelete).remove();

    if (anyItemSelectedLocally) {
      this.selectionController.refreshSelectedItems();
    }
  }

  updateItemsColor(updateData) {
    this.communication.sendBoardEvent('color-update', updateData);
  }

  receiveColorUpdate({ ids, color }) {
    const itemsToUpdate = this._getItemsByIds(ids);
    itemsToUpdate.forEach(item => {
      if (item.hasFill()) {
        item.fillColor = color;
      }
      if (item.hasStroke()) {
        item.strokeColor = color;
      }
    });
  }

  trackOutgoingContinuousPath(continuousPath) {
    this.continuousPathsTracker.trackOutgoingPath(continuousPath);
  }

  generateId() {
    return uuidv4();
  }

  /**
   * Notifies all participants that the given group of items has been added in its entirety
   */
  addEntireGroup(group) {
    const serializedGroup = group.exportJSON();
    this.communication.sendBoardEvent('add-entire-group', {
      serializedGroup
    });
  }

  receiveEntireGroup({ serializedGroup }) {
    const group = new paper.Group();
    group.importJSON(serializedGroup);
    group.selected = false;
    group.ungroup();
  }

  _getItemsByIds(ids) {
    return paper.project.activeLayer.getChildrenByIds(ids);
  }

  _getItemById(id) {
    return paper.project.activeLayer.children.find(item => {
      return item.data.id === id;
    });
  }

  /**
   * Exports the whiteboard as JSON where all local changes are ignored
   * @returns JSON
   */
  _exportWhiteboardWithoutLocalChanges() {
    const serializedRawWhiteboard = paper.project.activeLayer.exportJSON();
    let serializedWhiteboard;

    // Clone the active layer into a temporary layer where we can manipulate items care-free
    paper.Layer.executeOnTemporaryLayer(temporaryLayer => {
      temporaryLayer.importJSON(serializedRawWhiteboard);

      // Ungroup all groups
      temporaryLayer.children.forEach(child => {
        if (child.ungroup) {
          child.ungroup();
        }
      });

      // Remove any local-only items
      temporaryLayer.children = temporaryLayer.children.filter(
        child => !child.data.isLocal
      );

      // Deselect all items and make sure they are visible
      temporaryLayer.children.forEach(child => {
        child.selected = false;
        child.visible = true;
      });

      serializedWhiteboard = temporaryLayer.exportJSON();
    });

    return { json: serializedWhiteboard };
  }

  sendClearWhiteboardMessage() {
    this.communication.sendOwnerSignal('clearAll');
  }

  clearWhiteboard() {
    paper.project.activeLayer.removeChildren();
  }
}
