/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'line': {
    width: 17,
    height: 16,
    viewBox: '0 0 17 16',
    data: '<path pid="0" _stroke="#000" stroke-linecap="round" d="M15.982.707L.707 15.217"/>'
  }
})
