import IntervalQueue from '@/utils/interval-queue';
import SimpleEventEmitter from '@/utils/SimpleEventEmitter';
import ThrottledFlushQueue from '@/utils/throttled-flush-queue';

/**
 * Base class for classes that implement "live tracking",
 * i.e. they track a local change and send updates about it, and they receive remote updates.
 *
 * This class allows us to throttle traffic without giving up visual smoothness because:
 *  1) it throttles the outgoing updates
 *  2) it processes incoming updates with linear interpolation based on the throttling mentioned above
 */
export default class LiveTracker extends SimpleEventEmitter {
  constructor(eventNames, emitThrottlingMilliseconds, communication) {
    super();

    const { startEventName, updateEventName, stopEventName } = eventNames;
    this.startEventName = startEventName;
    this.updateEventName = updateEventName;
    this.stopEventName = stopEventName;
    this.emitThrottlingMilliseconds = emitThrottlingMilliseconds;
    this.communication = communication;
    this._incomingItems = new Map();
    this._outgoingItems = new Map();

    this.communication.onBoardEvent(
      startEventName,
      this.onIncomingItemStart.bind(this)
    );
    this.communication.onBoardEvent(
      updateEventName,
      this.onIncomingItemUpdate.bind(this)
    );
    this.communication.onBoardEvent(stopEventName, data => {
      const id = data.id;
      const intervalQueue = this._incomingItems.get(id);

      if (!intervalQueue) {
        return;
      }

      intervalQueue.onceEmpty(() => {
        if (intervalQueue !== this._incomingItems.get(id)) {
          return;
        }

        this.onIncomingItemEnd(data);
      });
    });
  }

  onIncomingItemStart(data) {
    const id = data.id;

    if (this._incomingItems.has(id)) {
      this.onIncomingItemEnd({ id });
    }

    this._incomingItems.set(id, new IntervalQueue());
  }

  onIncomingItemUpdate(data) {
    const { id, updates } = data;
    if (!this._incomingItems.has(id)) {
      this.processMissingItemUpdate(data);
      return;
    }

    // Process the updates smoothly to make them seem like they're happening right now in real time
    // NOTE: We use a synchronous queue of intervals because the updates must be processed in the
    //       same order as they are received
    const intervalQueue = this._incomingItems.get(id);
    // TODO: Try to use requestAnimationFrame instead of intervals
    intervalQueue.enqueueInterval({
      callback: () => {
        const update = updates.shift();
        this.processSingleUpdate(id, update);
      },
      stopCondition: () => updates.length === 0,
      // TODO: Replace emitThrottlingMilliseconds with "how many milliseconds it took for the update
      //       to arrive from its sender to our client"
      gapMilliseconds: this.emitThrottlingMilliseconds / updates.length
    });
  }

  onIncomingItemEnd(data) {
    const id = data.id;
    if (!this._incomingItems.has(id)) {
      console.error(
        `Unrecognized item ID ${id} received incoming live-tracked termination signal`
      );
      return;
    }

    this._incomingItems.delete(id);
  }

  /**
   * Immediately stops processing any updates that were previously enqueued
   * @param {*} id ID of the item
   */
  overrideUpdates(id) {
    if (!this._incomingItems.has(id)) {
      return;
    }

    const intervalQueue = this._incomingItems.get(id);
    intervalQueue.stop();
  }

  stopOverridingUpdates(id) {
    if (!this._incomingItems.has(id)) {
      return;
    }

    const intervalQueue = this._incomingItems.get(id);
    intervalQueue.resume();
  }

  startTrackingOutgoingItem(id, metadata) {
    // Initialize a flush queue that will flush throttled updates
    this._outgoingItems.set(
      id,
      new ThrottledFlushQueue(updates => {
        this.communication.sendBoardEvent(this.updateEventName, {
          id,
          updates,
          metadata
        });
      }, this.emitThrottlingMilliseconds)
    );

    this.communication.sendBoardEvent(this.startEventName, {
      id,
      metadata
    });
  }

  /**
   * Emits outgoing updates in a throttled fashion.
   * Updates are bulked together until the throttled flush finally happens.
   * WARNING: More updates in a single flush = greater packet size
   */
  updateOutgoingItem(id, update) {
    const throttledFlushQueue = this._outgoingItems.get(id);
    throttledFlushQueue.push(update);
    throttledFlushQueue.flush();
  }

  stopTrackingOutgoingItem(id) {
    if (!this._outgoingItems.has(id)) {
      return;
    }

    const throttledFlushQueue = this._outgoingItems.get(id);
    throttledFlushQueue.onceEmpty(() => {
      this.communication.sendBoardEvent(this.stopEventName, {
        id
      });
    });
    throttledFlushQueue.flush();
  }

  /**
   * Process a raw update payload when the associated item is unrecognized
   * (i.e. the item's "start" event was never captured)
   * @param {*} payload Raw update payload object
   */
  // eslint-disable-next-line no-unused-vars
  processMissingItemUpdate(payload) {
    // When a cursor is not recognized (e.g. user joined the session while it is already on the screen),
    // we can just infer it based on the update payload
    console.warn(`Missing item update - ${payload}`);
    this.onIncomingItemStart(payload);
    this.onIncomingItemUpdate(payload);
  }

  /**
   * Process a single incoming update
   * @param {*} update The update that was sent by the symmetrical onOutgoingItemUpdated function
   */
  // ABSTRACT
  // eslint-disable-next-line no-unused-vars
  processSingleUpdate(update) {}
}
