<template>
  <ToolButton
    ref="toolButton"
    displayName="Draw"
    :active="active"
    @click="toolButtonClicked(selectedStrokeWidth)"
  >
    <template slot="icon">
      <v-icon :iconName="active ? 'pencil-fill-negative' : 'pencil'" />
    </template>

    <template slot="menu">
      <div class="stroke-sizes-container">
        <div
          v-for="strokeWidth in availableStrokeWidths"
          :key="strokeWidth"
          class="stroke-size-container"
          :class="{ selected: selectedStrokeWidth === strokeWidth }"
          @click="selectStrokeWidth(strokeWidth)"
        >
          <div
            class="stroke-size"
            :style="{ '--stroke-size': `${strokeWidth}px` }"
          />
        </div>
      </div>
    </template>
  </ToolButton>
</template>

<script>
import ToolButton from '@/components/toolbar/ToolButton';

export default {
  components: {
    ToolButton
  },

  props: {
    active: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      selectedStrokeWidth: 3,
      availableStrokeWidths: [3, 5, 7]
    };
  },

  methods: {
    activate(strokeWidth) {
      this.selectedStrokeWidth = strokeWidth;
      this.$emit('activated', { strokeWidth });
    },

    toolButtonClicked(preselectedStrokeWidth) {
      this.activate(preselectedStrokeWidth);
    },

    selectStrokeWidth(strokeWidth) {
      this.$refs.toolButton.closeMenu();
      this.activate(strokeWidth);
    }
  }
};
</script>

<style scoped>
.stroke-sizes-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.stroke-size-container {
  position: relative;
  width: 14px;
  height: 14px;
  border: 1px solid black;
  border-radius: 50%;
  cursor: pointer;
}

.stroke-size-container:not(:last-child) {
  margin-bottom: 8px;
}

.stroke-size-container.selected {
  border: 1px solid #871eff;
}

.stroke-size {
  position: absolute;
  width: var(--stroke-size);
  height: var(--stroke-size);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: black;
  border-radius: 50%;
}
</style>
