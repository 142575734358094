import WhiteboardTool from './whiteboard-tool';

export default class HandTool extends WhiteboardTool {
  constructor({ canvasPanZoomController, canvasBoundariesController }) {
    super();
    this.canvasPanZoomController = canvasPanZoomController;
    this.canvasBoundariesController = canvasBoundariesController;
  }

  onMouseDrag(event) {
    if (
      !this.canvasBoundariesController.isPointWithinBoundaries(event.downPoint)
    ) {
      return;
    }

    const panOffset = event.point.subtract(event.downPoint);
    this.canvasPanZoomController.moveCenterBy(panOffset.x, panOffset.y);
  }

  onMouseUp() {
    this.canvasPanZoomController.finalizePan();
  }
}
