import paper from 'paper';
import CORNERS_ENUM from '@/enums/corner-enum';

const CORNER_DIRECTIONS = Object.freeze({
  [CORNERS_ENUM.TOP_LEFT]: new paper.Size(-1, -1),
  [CORNERS_ENUM.TOP_RIGHT]: new paper.Size(1, -1),
  [CORNERS_ENUM.BOTTOM_RIGHT]: new paper.Size(1, 1),
  [CORNERS_ENUM.BOTTOM_LEFT]: new paper.Size(-1, 1)
});

export function getNormalizedDirectionByCorner(cornerName) {
  return CORNER_DIRECTIONS[cornerName];
}
