import PenTool from '@/whiteboard-paper-tools/pen-tool';
import HandTool from '@/whiteboard-paper-tools/hand-tool';
import LineTool from '@/whiteboard-paper-tools/line-tool';
import ArrowTool from '@/whiteboard-paper-tools/arrow-tool';
import ShapeTool from '@/whiteboard-paper-tools/shape-tool';
import TextTool from '@/whiteboard-paper-tools/text-tool';
import SelectionTool from '@/whiteboard-paper-tools/selection-tool';
import EraserTool from '@/whiteboard-paper-tools/eraser-tool';
import SimpleEventEmitter from '@/utils/SimpleEventEmitter';

const TOOLS = Object.freeze({
  HAND: HandTool,
  PEN: PenTool,
  LINE: LineTool,
  ARROW: ArrowTool,
  SHAPE: ShapeTool,
  TEXT: TextTool,
  SELECT: SelectionTool,
  ERASER: EraserTool
});
const TOOL_TYPES = Object.keys(TOOLS).reduce((toolTypes, toolName) => {
  toolTypes[toolName] = toolName;
  return toolTypes;
}, {});

export default class ToolsController extends SimpleEventEmitter {
  constructor() {
    super();
    this.activeToolType = null;
    this.tools = new Map();
  }
  init(dependencies) {
    this.tools = Object.keys(TOOLS).reduce((tools, toolType) => {
      tools.set(toolType, new TOOLS[toolType](dependencies));
      return tools;
    }, new Map());

    // Initialize all tools
    Array.from(this.tools.values()).forEach(tool => tool.initialize());
  }

  activateTool(toolType, options) {
    if (!this.tools.has(toolType)) {
      console.warn(
        'Attempted to activated a tool type that does not exist in the tools map'
      );
      return;
    }

    if (this.activeToolType && this.activeToolType !== toolType) {
      this.deactivateTool(this.activeToolType);
    }
    this.tools.get(toolType).activate(options);
    this.activeToolType = toolType;
    this.emit('tool-activated', toolType);
  }

  deactivateTool(toolType) {
    this.tools.get(toolType).deactivate();
  }

  get toolTypes() {
    return TOOL_TYPES;
  }
}
