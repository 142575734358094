import paper from 'paper';
import WhiteboardTool from './whiteboard-tool';

const DEFAULT_STROKE_WIDTH = 3;

export default class LineTool extends WhiteboardTool {
  constructor({ settingsProvider, pathDrawingController, telemetries }) {
    super();
    this.settingsProvider = settingsProvider;
    this.pathDrawingController = pathDrawingController;
    this.telemetries = telemetries;
    this.previewLine = null;

    this.strokeWidth = DEFAULT_STROKE_WIDTH;
  }

  activate(options) {
    super.activate();

    if (options) {
      this.strokeWidth = options.strokeWidth || DEFAULT_STROKE_WIDTH;
    }
  }

  onMouseDown(ev) {
    this.previewLine = this.createLine(ev.downPoint, ev.point);
    this.previewLine.strokeColor = 'grey';
    this.previewLine.strokeWidth = this.strokeWidth;
    this.previewLine.dashArray = [
      5 * this.previewLine.strokeWidth,
      5 * this.previewLine.strokeWidth
    ];
    this.previewLine.data.isLocal = true;
  }

  onMouseDrag(ev) {
    if (!this.previewLine) {
      return;
    }

    this.previewLine.segments[1].point = ev.point;
  }

  onMouseUp(ev) {
    if (!this.previewLine) {
      return;
    }
    const finalLine = this.createLine(ev.downPoint, ev.point);

    finalLine.strokeColor = this.settingsProvider.color;
    finalLine.fillColor = this.settingsProvider.color;
    finalLine.strokeWidth = this.strokeWidth;

    this.pathDrawingController.finalizeRawPath(finalLine);
    this.previewLine.remove();
    this.previewLine = null;
    this.emitUsageTelemetry();
  }

  emitUsageTelemetry() {
    this.telemetries.emitTelemetry({
      name: 'use-tool',
      data: {
        type: 'line',
        strokeWidth: this.strokeWidth,
        color: this.settingsProvider.color
      }
    });
  }

  onKeyDown(ev) {
    if (ev.key === 'escape' && this.previewLine) {
      this.previewLine.remove();
      this.previewLine = null;
    }
  }

  createLine(downPoint, point) {
    const line = new paper.Path.Line(downPoint, point);

    return line;
  }
}
