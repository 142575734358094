import jwtDecode from 'jwt-decode';
import hotkeys from 'hotkeys-js';
import throttle from 'lodash.throttle';
import logger from '@/services/logger';
import CollaborativeWhiteboardService from '@/services/collaborative-whiteboard-service';
import whiteboardServiceApi from '@/whiteboard-communication/whiteboard-service-api';

// Some keyboard shortcuts can be held down to cause repeated action,
// in which case we don't want to allow them to run too fast
const KEYBOARD_SHORTCUTS_THROTTLE = 100;
const REFRESH_SESSION_DATA_THROTTLE = 500;

export default {
  initApp: ({ dispatch }) => {
    dispatch('panZoom/init');
    dispatch('cursors/init');
    dispatch('settings/init');
    dispatch('history/init');
    dispatch('canvas/init');
    dispatch('registerKeyboardShortcuts');
  },

  setSessionId({ commit }, sessionId) {
    commit('SET_SESSION_ID', sessionId);
  },

  syncBoard: async ({ commit }) => {
    commit('SET_IS_SYNCHRONIZING', true);
    await CollaborativeWhiteboardService.syncBoard();
    commit('SET_IS_SYNCHRONIZING', false);
  },

  refreshSessionData: throttle(async ({ state, commit, dispatch }) => {
    const sessionId = state.sessionId;

    // TODO: Handle errors such as 404
    const newSessionData = await whiteboardServiceApi.getSession(sessionId);

    const oldSessionData = state.sessionData;
    commit('SET_SESSION_DATA', newSessionData);
    CollaborativeWhiteboardService.clock.updateServerTime(
      newSessionData.secondsSinceStarted
    );

    if (oldSessionData && oldSessionData.isLocked !== newSessionData.isLocked) {
      dispatch('flashMessages/addFlashMessage', {
        type: 'shoutout',
        text: newSessionData.isLocked
          ? 'The whiteboard has been locked.'
          : 'The whiteboard was unlocked.'
      });
    }
  }, REFRESH_SESSION_DATA_THROTTLE),

  joinSession: ({ commit }, { participantToken = null }) => {
    whiteboardServiceApi.setAccessToken(participantToken);

    const decodedJwt = jwtDecode(participantToken);
    commit('SET_ROLE', decodedJwt.role);

    return participantToken;
  },

  initWhiteboard: (
    { commit, dispatch },
    {
      ownerDisplayName,
      displayName,
      numberOfParticipants = 0,
      mobileMode = false
    }
  ) => {
    logger.info('initialize-whiteboard', {
      ownerDisplayName,
      displayName,
      numberOfParticipants,
      mobileMode
    });
    commit('SET_OWNER_NAME', ownerDisplayName);
    commit('SET_MY_PARTICIPANT_NAME', displayName);
    commit('SET_NUMBER_OF_PARTICIPANTS', numberOfParticipants);
    commit('SET_IS_MOBILE_MODE', mobileMode);

    CollaborativeWhiteboardService.communicationProvider.onSessionSignal(
      'session-updated',
      () => {
        dispatch('refreshSessionData');
      }
    );

    CollaborativeWhiteboardService.communicationProvider.onSessionSignal(
      'clear-all',
      () => {
        dispatch('clearAll');
      }
    );

    CollaborativeWhiteboardService.iframeCommunication.on(
      'number-of-participants',
      (payload) => {
        commit('SET_NUMBER_OF_PARTICIPANTS', payload.data.numberOfParticipants);
      }
    );

    dispatch('syncBoard').then(() => {
      dispatch('panZoom/panToInitialCenter');
    });
    CollaborativeWhiteboardService.communicationProvider.onSocketEvent(
      'reconnect',
      () => {
        dispatch('syncBoard');
        logger.warning('socket-reconnect', {
          displayName,
          mobileMode
        });
      }
    );
    CollaborativeWhiteboardService.communicationProvider.onBoardEvent(
      'out-of-sync',
      (outOfSyncInfo) => {
        dispatch('syncBoard');
        logger.warning('out-of-sync', {
          displayName,
          mobileMode,
          outOfSyncInfo
        });
      }
    );
  },

  sendClearWhiteboardMessage: () => {
    CollaborativeWhiteboardService.synchronizer.sendClearWhiteboardMessage();
  },

  clearAll: ({ dispatch }) => {
    dispatch('history/clear');
    dispatch('canvas/clear');
  },

  renameWhiteboard: ({ state, getters }, boardName) => {
    if (getters.isWhiteboardOwner) {
      return whiteboardServiceApi.setWhiteboardName(state.sessionId, boardName);
    }
  },

  stopSharing: () => {
    CollaborativeWhiteboardService.stopSharingWhiteboard();
  },

  setIsWhiteboardLocked: ({ state, getters }, isLocked) => {
    if (getters.isWhiteboardOwner) {
      whiteboardServiceApi.setIsWhiteboardLocked(state.sessionId, isLocked);
    }
  },

  emitTelemetry: (_, payload) => {
    CollaborativeWhiteboardService.emitTelemetry(payload);
  },

  setIsAppInitialized: ({ commit }, isInitialized) => {
    commit('SET_IS_APP_INITIALIZED', isInitialized);
  },

  registerKeyboardShortcuts: ({ dispatch }) => {
    hotkeys('backspace, delete', () => {
      dispatch('canvas/deleteSelectedItems');
    });
    hotkeys(
      'ctrl+z, command+z',
      throttle(() => {
        dispatch('history/undo');
      }, KEYBOARD_SHORTCUTS_THROTTLE)
    );
    hotkeys(
      'ctrl+shift+z, command+shift+z, ctrl+y, command+y',
      throttle(() => {
        dispatch('history/redo');
      }, KEYBOARD_SHORTCUTS_THROTTLE)
    );
    hotkeys('ctrl+c, command+c', () => {
      dispatch('canvas/copySelectedItems');
    });
    hotkeys(
      'ctrl+v, command+v',
      throttle(
        () => {
          dispatch('canvas/pasteClipboardItems');
        },
        // Pasting can often be costly, so we want to make sure there are no more than 2 pastes in a single second
        Math.max(500, KEYBOARD_SHORTCUTS_THROTTLE)
      )
    );
  }
};
