import Vue from 'vue';

export default {
  inserted(el, binding) {
    if (binding.value) {
      Vue.nextTick(() => el.focus());
    }
  },

  update(el, binding) {
    if (binding.value) {
      Vue.nextTick(() => el.focus());
    }
  }
};
