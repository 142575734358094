/**
 * A service that behaves like a clock that is synced with a remote server's clock
 * In the whiteboard use case, it helps us keep track of how many seconds passed since
 * the creation of a whiteboard session with not-so-great accuracy
 */
export default class ClockService {
  constructor() {
    this._lastServerTimeUpdateTime = null;
    this.lastKnownServerTime = null;
  }

  updateServerTime(serverTime) {
    this.lastKnownServerTime = serverTime;
    this._lastServerTimeUpdateTime = Date.now();
  }

  /**
   * Current server time in seconds
   * Returns 0 when unknown
   */
  now() {
    if (!this._lastServerTimeUpdateTime || !this.lastKnownServerTime) {
      return 0;
    }

    const secondsSinceLastUpdate =
      (Date.now() - this._lastServerTimeUpdateTime) / 1000;
    return this.lastKnownServerTime + secondsSinceLastUpdate;
  }
}
