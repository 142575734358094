/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'undo': {
    width: 17,
    height: 16,
    viewBox: '0 0 17 16',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M2.151 8.103l.671-3.747-.984-.176L.83 9.804l5.625 1.008.176-.984-4.122-.74a8.838 8.838 0 0111.748.666l.707-.708A9.837 9.837 0 002.15 8.103z" _fill="#212121"/></g><defs><clipPath id="clip0"><path pid="1" _fill="#fff" transform="translate(.01)" d="M0 0h16.001v16H0z"/></clipPath></defs>'
  }
})
