import { KEYBOARD_CODES, MODIFIERS } from './keycodes';

// example: { enter: true, esc: true, nobutton: true } => [enter, esc]
function keysListify(keysDict) {
  return Object.keys(keysDict)
    .map(modifier => KEYBOARD_CODES[modifier]) // cast modifier from name to keycode
    .filter(modifier => modifier); // filter undefined values from the list
}

function getModifiers(keysDict) {
  return MODIFIERS.reduce((modifiers, modifier) => {
    keysDict[modifier] && modifiers.add(modifier);
    return modifiers;
  }, new Set());
}

/*
**value:
handler function
**modifiers:
l2: for layer 2 modals (while l2 is bound, ignores l1 layer handlers)
stop: dont propagate if an input is highlighted
*/
export default {
  bind(el, binding) {
    const keysList = keysListify(binding.modifiers);
    const modifierKeys = getModifiers(binding.modifiers);

    // return if no keys were passed
    if (!keysList.length) {
      return;
    }

    let addListener = false;
    if (!el._keyboardHandlers) {
      el._keyboardHandlers = {};
      document._l2Counter = 0;
      addListener = true;
    }
    keysList.forEach(keyCode => {
      el._keyboardHandlers[keyCode] = {
        execute: binding.value,
        modifiers: modifierKeys
      };
    });

    if (binding.modifiers.l2) {
      document._l2Counter++;
    }

    if (addListener) {
      // add keydown listener if it is the first added listener of the element
      el._keyboardHandlerDict = e => {
        // if "notext" modifier is applied, the handler would be called only when textbox is not highlighted
        if (binding.modifiers.notext) {
          const { nodeName, isContentEditable } = document.activeElement;
          if (isContentEditable) return;

          switch (nodeName) {
            case 'INPUT':
            case 'TEXTAREA':
            case 'SELECT':
              return;
          }
        }

        // stops propagation if a modal is opened
        if (document._l2Counter && !binding.modifiers.l2) return;

        if (el._keyboardHandlers[e.keyCode]) {
          // validate correct modifier keys
          const modifiers = el._keyboardHandlers[e.keyCode].modifiers;
          if (
            modifiers.has('cmd') !== e.ctrlKey ||
            modifiers.has('cmd') !== e.metaKey ||
            modifiers.has('shift') !== e.shiftKey
          ) {
            return;
          }

          el._keyboardHandlers[e.keyCode].execute(e.keyCode);
          if (modifiers.has('prevent')) {
            e.preventDefault();
          }
        }
      };
      window.addEventListener('keydown', el._keyboardHandlerDict);
    }
  },

  unbind(el, binding) {
    keysListify(binding.modifiers).forEach(keyCode => {
      delete el._keyboardHandlers[keyCode];
    });

    if (binding.modifiers.l2) {
      document._l2Counter--;
    }

    // remove keydown listener if no key listeners remain
    if (!Object.keys(el._keyboardHandlers).length) {
      window.removeEventListener('keydown', el._keyboardHandlerDict);
    }
  }
};
