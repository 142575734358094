import WebSocketMessageSizeExceededError from '@/errors/WebSocketMessageSizeExceededError';
import CollaborativeWhiteboardService from '@/services/collaborative-whiteboard-service';
import logger from '@/services/logger';

export default {
  init: ({ commit }) => {
    CollaborativeWhiteboardService.history.on(
      'undo-stack-size-changed',
      newSize => {
        commit('SET_HAS_UNDO_ACTIONS', newSize > 0);
      }
    );

    CollaborativeWhiteboardService.history.on(
      'redo-stack-size-changed',
      newSize => {
        commit('SET_HAS_REDO_ACTIONS', newSize > 0);
      }
    );
  },

  undo: ({ dispatch, rootGetters }) => {
    if (rootGetters.isWhiteboardReadonly) {
      return;
    }

    dispatch('emitTelemetry', { name: 'undo' }, { root: true });

    try {
      CollaborativeWhiteboardService.history.undo();
    } catch (error) {
      if (error instanceof WebSocketMessageSizeExceededError) {
        dispatch(
          'flashMessages/addFlashMessage',
          {
            type: 'warning',
            text:
              'Failed to undo changes. You may have tried to undo a change that is too big'
          },
          { root: true }
        );
        logger.error('websocket-message-size-exceeded', {
          action: 'undo',
          error
        });
        return;
      }

      throw error;
    }
  },

  redo: ({ dispatch, rootGetters }) => {
    if (rootGetters.isWhiteboardReadonly) {
      return;
    }

    dispatch('emitTelemetry', { name: 'redo' }, { root: true });
    CollaborativeWhiteboardService.history.redo();
  },

  clear: () => {
    CollaborativeWhiteboardService.history.clear();
  }
};
