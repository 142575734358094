/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'eraser-fill-negative': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M15.757 14.546H.243V16h15.514v-1.454zM3.734 13.09H8.45l7.323-6.864a.695.695 0 000-1.028L10.457.213a.812.812 0 00-1.097 0L.227 8.775a.695.695 0 000 1.029l3.507 3.287z" _fill="#fff" _stroke="#000"/>'
  }
})
