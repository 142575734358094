export default {
  SET_OWNER_NAME: (state, ownerName) => {
    state.ownerName = ownerName;
  },

  SET_MY_PARTICIPANT_NAME: (state, participantName) => {
    state.myParticipantName = participantName;
  },

  SET_NUMBER_OF_PARTICIPANTS: (state, numberOfParticipants) => {
    state.numberOfParticipants = numberOfParticipants;
  },

  SET_IS_MOBILE_MODE: (state, isMobileMode) => {
    state.isMobileMode = isMobileMode;
  },

  SET_SESSION_DATA: (state, sessionData) => {
    state.sessionData = sessionData;
  },

  SET_SESSION_ID: (state, sessionId) => {
    state.sessionId = sessionId;
  },

  SET_ROLE: (state, role) => {
    state.role = role;
  },

  SET_IS_SYNCHRONIZING: (state, isSynchronizing) => {
    state.isSynchronizing = isSynchronizing;
  },

  SET_IS_APP_INITIALIZED: (state, isInitialized) => {
    state.isAppInitialized = isInitialized;
  }
};
