<template>
  <vwc-text-field
    ref="textField"
    v-focus="focus"
    :disabled="disabled"
    :value="value"
    :label="label"
    :required="required"
    :autoValidate="autoValidate"
    :validationMessage="validationMessage"
    :maxlength="maxlength"
    :icon="icon"
    :readOnly="readOnly"
    :placeholder="placeholder"
    :helper-text="helper"
    :success-text="success"
    :autocomplete="autocomplete"
    :appearance="appearance"
    :shape="shape"
    @focus="$emit('focus')"
    @blur="$emit('blur')"
    @input="$emit('input', $event.target.value)"
    @keyup.enter="$emit('submit', $event.target.value)"
  ></vwc-text-field>
</template>

<script>
export default {
  name: 'VTextfield',
  props: {
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    focus: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    autoValidate: {
      type: Boolean,
      default: false
    },
    validationMessage: {
      type: String,
      default: ''
    },
    maxlength: {
      type: Number,
      default: 30
    },
    minLength: {
      type: Number,
      default: 0
    },
    icon: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    helper: {
      type: String,
      default: ''
    },
    success: {
      type: String,
      default: ''
    },
    autocomplete: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    appearance: {
      type: String,
      default: 'fieldset'
    },
    shape: {
      type: String,
      default: 'rounded'
    }
  },

  mounted() {
    if (this.focus) {
      // Note: This is a hack, because our custom v-focus directive doesn't work in all cases
      setTimeout(() => {
        this.$refs.textField.focus();
      }, 50);
    }
  }
};
</script>
<style scoped>
vwc-textfield {
  width: 100%;
}
</style>
