import paper from 'paper';

const BOUNDARIES_BORDER_STROKE_WIDTH = Math.pow(2, 16);
// Currently using 4 times 1280x720 as the canvas size
const CANVAS_STATIC_BOUNDARIES = new paper.Rectangle(
  new paper.Point(-2 * 1280, -2 * 720),
  new paper.Size(1280 * 4, 720 * 4)
);
const BOUNDARIES_PADDING = 32;
const BORDER_COLOR = '#dadada';

/**
 * Controls the boundaries of the canvas (i.e. area where the user can draw)
 */
export default class CanvasBoundariesController {
  init() {
    const defaultLayer = paper.project.activeLayer;

    // Create a separate layer for the border
    const borderLayer = new paper.Layer();
    borderLayer.activate();

    const borderBounds = CANVAS_STATIC_BOUNDARIES.expand(
      BOUNDARIES_BORDER_STROKE_WIDTH
    );
    const borderRectangle = new paper.Path.Rectangle(borderBounds);
    borderRectangle.strokeColor = BORDER_COLOR;
    borderRectangle.strokeWidth = BOUNDARIES_BORDER_STROKE_WIDTH;

    // Re-activate the default layer
    defaultLayer.activate();
  }

  isPointWithinBoundaries(point) {
    return CANVAS_STATIC_BOUNDARIES.contains(point);
  }

  /**
   * Calculates a new value based on paper.view.center, so that the view will be within the boundaries
   * @returns Valid center value, or null if the existing center does not require correction
   */
  getCorrectedCenter() {
    const { horizontalPadding, verticalPadding } = this._calculatePadding();
    const paddedBoundaries = CANVAS_STATIC_BOUNDARIES.expand(
      horizontalPadding * 2,
      verticalPadding * 2
    );

    // If the padded boundaries contain the center, then it is in a valid position and doesn't
    // require correction
    if (paddedBoundaries.contains(paper.view.bounds)) {
      return null;
    }

    let correctedCenter = { x: paper.view.center.x, y: paper.view.center.y };

    // Correct horizontal axis
    if (paper.view.bounds.right > paddedBoundaries.right) {
      correctedCenter.x = paddedBoundaries.right - paper.view.bounds.width / 2;
    } else if (paper.view.bounds.left < paddedBoundaries.left) {
      correctedCenter.x = paddedBoundaries.left + paper.view.bounds.width / 2;
    }

    // Correct vertical axis
    if (paper.view.bounds.top < paddedBoundaries.top) {
      correctedCenter.y = paddedBoundaries.top + paper.view.bounds.height / 2;
    } else if (paper.view.bounds.bottom > paddedBoundaries.bottom) {
      correctedCenter.y =
        paddedBoundaries.bottom - paper.view.bounds.height / 2;
    }

    return correctedCenter;
  }

  _calculatePadding() {
    const viewToBoundariesSizeDiff = paper.view.bounds.size.subtract(
      CANVAS_STATIC_BOUNDARIES.size
    );

    // Calculate the padding so that it is not affected by the zoom factor
    const padding = BOUNDARIES_PADDING * (1 / paper.view.zoom);

    // When the view is large enough to contain an axis, we need to increase the padding
    // according to the size diff
    const horizontalPadding = Math.max(
      padding,
      viewToBoundariesSizeDiff.width / 2
    );
    const verticalPadding = Math.max(
      padding,
      viewToBoundariesSizeDiff.height / 2
    );

    return { horizontalPadding, verticalPadding };
  }
}
