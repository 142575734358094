/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'magnifying-glass': {
    width: 17,
    height: 16,
    viewBox: '0 0 17 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16.001 0v4l-4-4h4zm-2.39.667l1.723 1.724V.667h-1.723zM0 0h4L0 4V0zm.667 2.39L2.39.668H.667V2.39zM0 16v-4l4 4H0zm2.39-.667L.668 13.61v1.723H2.39zM16.002 16h-4l4-4v4zm-.667-2.39l-1.723 1.723h1.723V13.61zM6.92 3.699a3.888 3.888 0 100 7.776 3.888 3.888 0 000-7.776zm3.826 6.983a4.92 4.92 0 10-.73.73l1.77 1.77a.516.516 0 00.73-.73l-1.77-1.77z" _fill="#000"/>'
  }
})
