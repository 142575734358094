/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'text-fill-negative': {
    width: 17,
    height: 16,
    viewBox: '0 0 17 16',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M16.331 0H1.663a.667.667 0 00-.666.667v4H2.33a.667.667 0 00.555-.297L4.02 2.667h3.644V12.92l-2.298 1.15a.666.666 0 00-.37.596V16h8.002v-1.333a.667.667 0 00-.37-.597l-2.297-1.149V2.667h3.643L15.11 4.37a.667.667 0 00.554.297h1.334v-4A.667.667 0 0016.33 0z" _fill="#fff"/></g><defs><clipPath id="clip0"><path pid="1" _fill="#fff" transform="translate(.997)" d="M0 0h16.001v16H0z"/></clipPath></defs>'
  }
})
