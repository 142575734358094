var render = function render(){var _vm=this,_c=_vm._self._c;return _c('svgicon',{staticClass:"v-icon",class:{
    md: _vm.md,
    sm: _vm.sm,
    xs: _vm.xs,
    xxs: _vm.xxs,
    pointer: _vm.$listeners && _vm.$listeners.click && !_vm.preventClick,
    spin: _vm.spin
  },attrs:{"name":_vm.iconName,"original":""},nativeOn:{"click":function($event){return (event => !_vm.preventClick && _vm.$emit('click', event)).apply(null, arguments)}}})
}
var staticRenderFns = []

export { render, staticRenderFns }