import ContinuousPathWrapper from '@/whiteboard-paper-tools/paper-wrappers/continuous-path-wrapper';
import paper from 'paper';
import LiveTracker from './live-tracker';

const CONTINUOUS_PATH_STARTED_EVENT_NAME = 'continuous-path-started';
const CONTINUOUS_PATH_PROGRESS_EVENT_NAME = 'continuous-path-progress';
const CONTINUOUS_PATH_ENDED_EVENT_NAME = 'continuous-path-ended';

const THROTTLE_MILLISECONDS = 200;

export default class ContinuousPathsTracker extends LiveTracker {
  constructor(communication, cursorsTracker, clock) {
    super(
      {
        startEventName: CONTINUOUS_PATH_STARTED_EVENT_NAME,
        updateEventName: CONTINUOUS_PATH_PROGRESS_EVENT_NAME,
        stopEventName: CONTINUOUS_PATH_ENDED_EVENT_NAME
      },
      THROTTLE_MILLISECONDS,
      communication
    );

    this.cursorsTracker = cursorsTracker;
    this.clock = clock;
    this.incomingPaths = new Map();
  }

  onIncomingItemStart(eventData) {
    const id = eventData.id;
    const { createdByParticipantId, data } = eventData.metadata;
    const path = new ContinuousPathWrapper(
      id,
      createdByParticipantId,
      this.clock.now(),
      data
    );
    this.incomingPaths.set(id, { path, createdByParticipantId });
    this.cursorsTracker.overrideUpdates(createdByParticipantId);

    super.onIncomingItemStart(eventData);
  }

  processSingleUpdate(id, update) {
    const { path, createdByParticipantId } = this.incomingPaths.get(id);
    const point = update.point;
    path.add(new paper.Point(point.x, point.y));

    this.cursorsTracker.processSingleUpdate(createdByParticipantId, {
      point
    });
  }

  onIncomingItemEnd(data) {
    super.onIncomingItemEnd(data);
    const { id } = data;

    if (!this.incomingPaths.has(id)) {
      return;
    }

    const { path, createdByParticipantId } = this.incomingPaths.get(id);
    path.finalize();
    this.incomingPaths.delete(id);

    this.cursorsTracker.stopOverridingUpdates(createdByParticipantId);
  }

  trackOutgoingPath(continuousPath) {
    this.cursorsTracker.pauseUserCursor();

    const id = continuousPath.id;
    this.startTrackingOutgoingItem(id, {
      createdByParticipantId: continuousPath.path.data.createdByParticipantId,
      data: continuousPath.options
    });

    continuousPath.on('point-added', point => {
      this.updateOutgoingItem(id, { point });
    });

    continuousPath.on('finalized', () => {
      this.cursorsTracker.resumeUserCursor();

      this.stopTrackingOutgoingItem(id);
    });
  }
}
