import { AVAILABLE_TEXT_STYLES } from '@/consts';
import { textItemStyleToTextCss } from '@/utils/text-utils';
export default class TextBoxController {
  init(textBoxElement) {
    this.textBox = textBoxElement;
  }

  resetTextBox() {
    this.textBox.innerText = '';
    this.textBox.style.display = 'none';
    this._textboxPosition = null;
  }

  displayTextBox(textBoxPosition, color, zoomFactor, textStyle) {
    this.setPosition(textBoxPosition);
    this.setColor(color);
    this.setSizeByZoomFactor(zoomFactor);
    this.setTextStyle(textStyle);
    this.textBox.style.display = 'block';
    this.textBox.focus();
  }

  setPosition(point) {
    this._textboxPosition = point;
    this.textBox.style.top = `${point.y}px`;
    this.textBox.style.left = `${point.x}px`;
  }

  setSizeByZoomFactor(zoomFactor) {
    this.textBox.style.transform = `scale(${zoomFactor})`;
  }

  setColor(color) {
    this.textBox.style.color = color;
  }

  setTextStyle(textStyle) {
    const fontStyle = AVAILABLE_TEXT_STYLES[textStyle.fontStyle];
    Object.assign(
      this.textBox.style,
      textItemStyleToTextCss(textStyle, fontStyle)
    );
  }

  moveCaretToEnd() {
    document.getSelection().collapse(this.textBox, 1);
  }

  setTextBoxText(text) {
    this.textBox.innerText = text;
  }

  get textBoxContent() {
    return this.textBox.innerText;
  }

  get isTextBoxVisible() {
    return this.textBox.style.display === 'block';
  }

  get textBoxPosition() {
    return this._textboxPosition;
  }
}
