/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pencil-fill-negative': {
    width: 17,
    height: 16,
    viewBox: '0 0 17 16',
    data: '<g clip-path="url(#clip0)" _fill="#fff"><path pid="0" d="M9.099 3.5l-7.8 7.8c-.2.2-.301.4-.301.7v3c0 .6.4 1 1 1h3c.3 0 .5-.1.7-.3L13.5 7.9l-4.4-4.4zM16.7 3.3l-3.001-3c-.4-.4-1-.4-1.4 0l-1.8 1.8 4.4 4.4 1.8-1.8c.4-.4.4-1 0-1.4z"/></g><defs><clipPath id="clip0"><path pid="1" _fill="#fff" transform="translate(.998)" d="M0 0h16.001v16H0z"/></clipPath></defs>'
  }
})
