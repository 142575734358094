import Vue from 'vue';
import App from './App.vue';
import VTooltip from 'v-tooltip';
import store from '@/store';
import router from './router';
import VIcon from '@/components/volta/VIcon';
import VButton from '@/components/volta/VButton';
import VDropdown from '@/components/volta/VDropdown';
import VInput from '@/components/volta/VInput';
import CustomDirectives from './directives';
import logger from '@/services/logger';
import VTextfield from './components/VTextfield.vue';
import '@vonage/vivid/dialog';
import '@vonage/vivid/button';
import '@vonage/vivid/icon';
import '@vonage/vivid/text-field';

logger.init();
Vue.use(VTooltip);
Vue.directive('observe-keyboard', CustomDirectives.ObserveKeyboard);
Vue.directive('focus', CustomDirectives.Focus);

Vue.config.productionTip = false;

Vue.component('VIcon', VIcon);
Vue.component('VButton', VButton);
Vue.component('VDropdown', VDropdown);
Vue.component('VInput', VInput);
Vue.component('VTextfield', VTextfield);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
