import axios from 'axios';

export const api = axios.create({
  baseURL: process.env.VUE_APP_WHITEBOARD_SERVICE_API_URL
});

class WhiteboardServiceApi {
  constructor() {
    this._accessToken = null;
  }

  async getSession(sessionId) {
    const response = await api.get(`/sessions/${sessionId}`);
    return response.data;
  }

  async joinSession(sessionId) {
    const response = await api.post(`/sessions/${sessionId}/join`);

    // TODO: Implement token renewal logic (refresh token, etc)
    return response.data;
  }

  async getWhiteboardData(sessionId) {
    const response = await api.get(
      `/sessions/${sessionId}/serialized-whiteboard`
    );
    return response.data;
  }

  async saveEntireWhiteboard(sessionId, board) {
    return await api.put(`/sessions/${sessionId}/serialized-whiteboard`, {
      board
    });
  }

  async setIsWhiteboardLocked(sessionId, isLocked) {
    return await api.put(`/sessions/${sessionId}`, { isLocked });
  }

  async setWhiteboardName(sessionId, boardName) {
    return await api.put(`/sessions/${sessionId}`, { boardName });
  }

  setAccessToken(token) {
    // TODO: Save access token to cookies
    this._accessToken = token;
    api.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${this._accessToken}`;
  }
}

export default new WhiteboardServiceApi();
