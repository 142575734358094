/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'redo': {
    width: 17,
    height: 16,
    viewBox: '0 0 17 16',
    data: '<path pid="0" d="M13.871 8.103L13.2 4.356l.984-.176 1.008 5.624-5.625 1.008-.176-.984 4.123-.74a8.838 8.838 0 00-11.748.666l-.708-.708a9.837 9.837 0 0112.813-.943z" _fill="#212121"/>'
  }
})
