<template>
  <div
    class="Vlt-composite Vlt-composite--appendedicon input-container"
    :class="{
      'Vlt-form__element--big': label,
      'Vlt-form__element--error': errorState
    }"
  >
    <div
      v-if="searchIcon"
      class="Vlt-composite__prepend Vlt-composite__prepend--icon"
    >
      <v-icon iconName="Vlt-icon-search" />
    </div>
    <div
      v-else-if="iconName"
      class="Vlt-composite__prepend Vlt-composite__prepend--icon"
    >
      <v-icon class="Vlt-purple-dark" :iconName="iconName" />
    </div>
    <div class="Vlt-input v-input">
      <input
        :id="`v-input-${_uid}`"
        v-model="inputText"
        v-focus="focus"
        :type="inputType"
        :pattern="pattern"
        :inputmode="inputMode"
        :maxlength="maxLength"
        class="input"
        :placeholder="placeholder"
        :disabled="disabled"
        autocomplete="off"
        @input="updateText"
        @keyup.enter="submitText"
      />
      <label v-if="label" :for="`v-input-${_uid}`" class="unselectable">{{
        label
      }}</label>
    </div>
    <div v-if="submitButton" class="Vlt-composite__append">
      <button class="Vlt-btn Vlt-btn--icon" @click="submitText">
        <v-icon
          :class="{ 'Vlt-teal': inputText.length > 0 }"
          :iconName="
            inputText.length > 0 ? 'Vlt-icon-send-negative' : 'Vlt-icon-send'
          "
        />
      </button>
    </div>

    <div v-if="errorMessage || preservePlaceForError" class="error-container">
      <small
        v-if="errorMessage"
        :style="errorStyle"
        class="Vlt-form__element__error error-small-message"
        >{{ errorMessage }}</small
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'VInput',

  model: {
    prop: 'parentText',
    event: 'textChanged'
  },

  props: {
    parentText: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ' '
    },
    iconName: {
      type: String,
      default: ''
    },
    submitButton: {
      type: Boolean,
      default: false
    },
    searchIcon: {
      type: Boolean,
      default: false
    },
    errorState: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
    errorStyle: {
      type: Object,
      default: undefined
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: Number,
      default: undefined
    },
    preservePlaceForError: {
      type: Boolean,
      default: false
    },
    focus: {
      type: Boolean,
      default: false
    },
    inputType: {
      type: String,
      default: 'text'
    },
    numeric: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    inputText: {
      // Needed for two way binding with parent
      get() {
        return this.parentText;
      },
      set(value) {
        this.$emit('textChanged', value);
      }
    },

    pattern() {
      // On some mobile devices, showing a digits-only keyboard depends on the "pattern" attribute
      return this.numeric ? '[0-9]*' : undefined;
    },

    inputMode() {
      return this.numeric ? 'numeric' : undefined;
    }
  },

  watch: {
    text(newVal) {
      this.$emit('textChanged', newVal);
    }
  },

  methods: {
    submitText() {
      if (this.inputText) {
        this.$emit('submit', this.inputText);
      }
    },

    updateText(event) {
      this.inputText = event.target.value;
    }
  }
};
</script>

<style scoped>
.input-container {
  flex-direction: column;
}

.input::placeholder {
  color: #919eab;
}

.v-input {
  margin-bottom: 4px;
}

.error-container {
  min-height: 26px;
}

.error-small-message {
  font-size: 14px;
  margin: 2px 0 6px 0 !important;
}

.Vlt-composite__prepend--icon + .Vlt-input input {
  padding-left: 42px !important;
}

.Vlt-composite__prepend--icon + .Vlt-input label {
  padding-left: 28px !important;
}

.Vlt-composite__prepend--icon {
  padding: 15px 12px;
}
</style>
