import paper from 'paper';
import WhiteboardTool from './whiteboard-tool';

export default class EraserTool extends WhiteboardTool {
  constructor({ eraserController, telemetries }) {
    super();

    this.eraserController = eraserController;
    this.telemetries = telemetries;
  }

  onMouseDown(event) {
    this.telemetries.emitTelemetry({
      name: 'use-tool',
      data: { type: 'ERASER' }
    });
    this._deleteItemByPosition(event.point);
  }

  onMouseDrag(event) {
    this._deleteItemByPosition(event.point);
  }

  _deleteItemByPosition(point) {
    const items = paper.project.activeLayer.children;
    const itemToDelete = items.reduce((topMostItem, item) => {
      return item.smartHitTest(point) &&
        (!topMostItem || item.index > topMostItem.index)
        ? item
        : topMostItem;
    }, null);

    if (itemToDelete) {
      this.eraserController.deleteItems([itemToDelete]);
    }
  }
}
