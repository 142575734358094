import CORNERS_ENUM from '@/enums/corner-enum';
import paper from 'paper';
import { getNormalizedDirectionByCorner } from './corner-utils';

// Handles are the small rectangles that appear around a selected group to allow resizing
const HANDLE_SIZE = 10;
const HANDLE_COLOR = '#129CE9';

const PAPER_RECTANGLE_ORDERED_SEGMENTS_SIDES = [
  CORNERS_ENUM.BOTTOM_LEFT,
  CORNERS_ENUM.TOP_LEFT,
  CORNERS_ENUM.TOP_RIGHT,
  CORNERS_ENUM.BOTTOM_RIGHT
];

/**
 * Similar to paper's Group but without adding an actual group to the canvas itself.
 * The main benefit is that the items within this type of group will remain in their same position
 * in the hierarchy, instead of being nested into a Group (e.g. in the SVG under a <g> </g>)
 * Currently does not support adding/removing items after initialization
 */
export default class LogicalPaperGroup {
  constructor(items) {
    this.items = items;
    this.refreshBounds();
    this._originalCenter = this.bounds.center;
    this._visibleSelectionBounds = null;
    this.data = {};
  }

  /**
   * Makes the group appear selected by selecting each and every item, as well as adding a rectangle
   * that represents the boundaries
   */
  select() {
    this.items.forEach(item => (item.bounds.selected = true));
    this._createVisibleSelectionBounds();
  }

  deselect() {
    this.items.forEach(item => (item.bounds.selected = false));
    this._visibleSelectionBounds.data.corners?.remove();
    this._visibleSelectionBounds.remove();
    this._visibleSelectionBounds = null;
  }

  exportJSON() {
    let json;
    paper.Group.executeOnTemporaryGroup(this.items, group => {
      json = group.exportJSON();
    });
    return json;
  }

  /**
   * Updates the boundaries of the group. Useful when items are moved, added or removed
   */
  refreshBounds() {
    // Filter away items that have been removed
    this.items = this.items.filter(item => item.isInserted());

    let minX = 9999999,
      minY = 999999,
      maxX = -1 * minX,
      maxY = -1 * minY;
    for (const item of this.items) {
      minX = Math.min(minX, item.bounds.left);
      minY = Math.min(minY, item.bounds.top);
      maxX = Math.max(maxX, item.bounds.right);
      maxY = Math.max(maxY, item.bounds.bottom);
    }

    this.bounds = new paper.Rectangle(minX, minY, maxX - minX, maxY - minY);

    if (this._visibleSelectionBounds) {
      this._refreshVisibleSelectedBounds();
    }
  }

  get position() {
    return this.bounds.center;
  }

  set position(newCenter) {
    const movedBy = newCenter.subtract(this.bounds.center);
    this.bounds.center = newCenter;
    this._visibleSelectionBounds.position = this.bounds.center;
    this._refreshVisibleSelectedBounds();
    this.items.forEach(item => (item.position = item.position.add(movedBy)));
  }

  getCornerByPoint(point) {
    if (!this._visibleSelectionBounds.data.corners) {
      return null;
    }

    const corner = this._visibleSelectionBounds.data.corners.children.find(
      corner => corner.bounds.contains(point)
    );
    if (!corner) {
      return null;
    }

    return corner.data.cornerName;
  }

  _createVisibleSelectionBounds() {
    if (this._visibleSelectionBounds) {
      this._visibleSelectionBounds.data.corners?.remove();
      this._visibleSelectionBounds.remove();
    }

    this._visibleSelectionBounds = new paper.Path.Rectangle(this.bounds);
    this._visibleSelectionBounds.data.isLocal = true;
    this._visibleSelectionBounds.bounds.selected = true;

    // Create visible corners to allow dragging (unless there's any item that cannot be resized)
    if (!this.items.some(item => item.data.disableResizing)) {
      this._visibleSelectionBounds.data.corners = new paper.Group();
      this._visibleSelectionBounds.data.corners.data.isLocal = true;

      this._visibleSelectionBounds.segments.forEach((segment, i) => {
        const handle = new paper.Path.Rectangle({
          point: [
            segment.point.x - this.handleSize / 2,
            segment.point.y - this.handleSize / 2
          ],
          size: [this.handleSize, this.handleSize],
          strokeColor: HANDLE_COLOR
        });
        handle.data.cornerName = PAPER_RECTANGLE_ORDERED_SEGMENTS_SIDES[i];
        this._visibleSelectionBounds.data.corners.addChild(handle);
      });
    }
  }

  _updateSelectionBoundsCorners() {
    if (!this._visibleSelectionBounds.data.corners) {
      return;
    }

    this._visibleSelectionBounds.segments.forEach((segment, i) => {
      const corner = this._visibleSelectionBounds.data.corners.children[i];
      const cornerBounds = corner.bounds.clone();
      cornerBounds.size = new paper.Size(this.handleSize, this.handleSize);

      corner.bounds = cornerBounds;
      corner.position = segment.point;
    });
  }

  _refreshVisibleSelectedBounds() {
    if (!this._visibleSelectionBounds) {
      return;
    }

    this._visibleSelectionBounds.bounds = this.bounds;
    this._updateSelectionBoundsCorners();
  }

  resize(newSize, draggedCorner = CORNERS_ENUM.BOTTOM_RIGHT) {
    newSize = new paper.Size(
      // Set a minimum width and height for resizing selections to avoid edge cases
      Math.max(newSize.width, 5),
      Math.max(newSize.height, 5)
    );
    const totalSizeDiff = newSize.subtract(this.bounds.size);
    // The anchor point is the single corner that does not move when resizing
    const anchorPointPropertyName = this._getAnchorPointPropertyName(
      draggedCorner
    );
    const getAnchorPoint = item => item[anchorPointPropertyName];
    const setAnchorPointValue = (item, value) =>
      (item[anchorPointPropertyName] = value);

    this.items.forEach(item => {
      const itemBounds = item.bounds.clone();
      // Each item is resized based on its relative size within the selection
      const sizeMultiplier = itemBounds.size.divide(this.bounds.size);
      const itemSizeDiff = item.data.disableResizing
        ? new paper.Size(0, 0)
        : totalSizeDiff.multiply(sizeMultiplier);
      const normalizedItemCoordinates = getAnchorPoint(itemBounds)
        .subtract(getAnchorPoint(this.bounds))
        .divide(this.bounds.size)
        .abs();
      itemBounds.size = itemBounds.size.add(itemSizeDiff);

      // Each item is repositioned to maintain its relative position within the selection
      setAnchorPointValue(
        itemBounds,
        getAnchorPoint(this.bounds).add(
          normalizedItemCoordinates
            .multiply(newSize)
            .multiply(getNormalizedDirectionByCorner(draggedCorner))
        )
      );
      item.bounds = itemBounds;
    });

    this.bounds.size = newSize;
    this.bounds.center = this.bounds.center.add(
      totalSizeDiff
        .divide(2)
        .multiply(getNormalizedDirectionByCorner(draggedCorner))
    );

    // Reposition the visible bounds based on the anchor
    this.refreshBounds();
  }

  _getAnchorPointPropertyName(draggedCorner) {
    if (draggedCorner === CORNERS_ENUM.TOP_LEFT) {
      return 'bottomRight';
    } else if (draggedCorner === CORNERS_ENUM.TOP_RIGHT) {
      return 'bottomLeft';
    } else if (draggedCorner === CORNERS_ENUM.BOTTOM_RIGHT) {
      return 'topLeft';
    } else if (draggedCorner === CORNERS_ENUM.BOTTOM_LEFT) {
      return 'topRight';
    }
  }

  get handleSize() {
    return Math.min(
      HANDLE_SIZE,
      // Make the handles shrink when the bounds are small
      1 + Math.min(this.bounds.width, this.bounds.height) / 4
    );
  }
}
