<template>
  <div id="app" ref="app" class="app">
    <router-view />
  </div>
</template>

<script>
import tween from '@tweenjs/tween.js';

export default {
  name: 'App',

  mounted() {
    // Setup the animation loop that updates tween
    const updateTween = (time) => {
      requestAnimationFrame(updateTween);
      tween.update(time);
    };
    requestAnimationFrame(updateTween);
  }
};
</script>

<style lang="sass">
$Vlt-font-url: '~@vonagevolta/volta2/fonts/';
@import '~@vonagevolta/volta2/scss/volta';
@import "~@vonage/vivid/styles/core/all"
@import "~@vonage/vivid/styles/tokens/theme-dark.css"
@import "~@vonage/vivid/styles/tokens/theme-light.css"
</style>

<style>
@import './style/v-tooltip.css';
@import './style/spezia.css';
@import './style/typography.css';

:root {
  --vvd-font-family-upright: SpeziaCompleteVariableUpright;
  --vvd-font-family-monospace: SpeziaMonoCompleteVariable;
}

html,
body {
  margin: 0;
  overflow: hidden;
  height: 100%;
}

#app {
  margin: 8;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #f6f6f6;
}
</style>
