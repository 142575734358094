import { getNormalizedDirectionByCorner } from '@/utils/corner-utils';
import debounce from 'lodash.debounce';
import paper from 'paper';
import WhiteboardTool from './whiteboard-tool';

const ARROW_KEYS = Object.freeze({
  LEFT: 'left',
  UP: 'up',
  RIGHT: 'right',
  DOWN: 'down'
});
const ARROW_KEY_MOVE_BY = 5;
const MOVE_WITH_ARROW_KEYS_THROTTLE = 500;

const SELECTION_SUBTOOLS = Object.freeze({
  MOVE: 'move',
  RESIZE: 'resize'
});

export default class SelectionTool extends WhiteboardTool {
  constructor({ settingsProvider, selectionController }) {
    super();
    this.settingsProvider = settingsProvider;
    this.selectionController = selectionController;
    this.debouncedFinalizeSelectedItemsMove = debounce(
      this.selectionController.finalizeSelectedItemsMove.bind(
        this.selectionController
      ),
      MOVE_WITH_ARROW_KEYS_THROTTLE
    );

    this._resetDefaultProperties();
  }

  onMouseDown(ev) {
    const selectedCornerName = this.selectionController.getSelectionCornerByPoint(
      ev.downPoint
    );

    if (selectedCornerName) {
      this.activeSubtool = SELECTION_SUBTOOLS.RESIZE;
      this.selectedCornerName = selectedCornerName;
      this.sizeBeforeResizing = this.selectionController.getSelectionSize();
      return;
    } else if (this.selectionController.selectedItemsContain(ev.downPoint)) {
      this.activeSubtool = SELECTION_SUBTOOLS.MOVE;
      const position = this.selectionController.getSelectedItemsPosition();
      this.dragPositionDiff = {
        x: ev.downPoint.x - position.x,
        y: ev.downPoint.y - position.y
      };
    } else {
      if (this.selectionPath) {
        this.selectionPath.remove();
        this.selectionPath = null;
      }
      
      // Create a temporary rectangle to show the area that will be selected
      this.selectionPath = new paper.Path.Rectangle(ev.downPoint, ev.point);
      if (this.selectionPath.area < 1) {
        this.selectionPath.bounds.expand(1);
      }
      this.selectionPath.strokeColor = 'grey';
      this.selectionPath.dashArray = [5, 5];
      this.selectionPath.data.isLocal = true;
      this.selectedItemClicked = false;
      this.selectionController.deselectAllItems();
    }
  }

  onMouseDrag(ev) {
    if (this.activeSubtool === SELECTION_SUBTOOLS.RESIZE) {
      let sizeDiff = new paper.Size(ev.point.subtract(ev.downPoint)).multiply(
        getNormalizedDirectionByCorner(this.selectedCornerName)
      );
      // SHIFT = resize proportionally
      if (ev.modifiers.shift) {
        const isHorizontalAxisStronger =
          Math.abs(sizeDiff.width) >= Math.abs(sizeDiff.height);
        sizeDiff = new paper.Size(
          isHorizontalAxisStronger ? sizeDiff.width : sizeDiff.height
        );
      }
      this.selectionController.resizeSelectedItems(
        this.sizeBeforeResizing.add(sizeDiff),
        this.selectedCornerName
      );
    } else if (this.activeSubtool === SELECTION_SUBTOOLS.MOVE) {
      this.itemsDragInProgress = true;
      this.selectionController.setSelectedItemsPosition(
        new paper.Point(
          ev.point.x - this.dragPositionDiff.x,
          ev.point.y - this.dragPositionDiff.y
        )
      );
    } else {
      this.updateSelectionPath(ev.point, ev.downPoint);
    }
  }

  updateSelectionPath(point, downPoint) {
    this.selectionPath.segments[1].point = new paper.Point(
      point.x,
      downPoint.y
    );
    this.selectionPath.segments[2].point = new paper.Point(point.x, point.y);
    this.selectionPath.segments[3].point = new paper.Point(
      downPoint.x,
      point.y
    );
  }

  onKeyDown(event) {
    if (this.selectionController.getSelectedItems().length === 0) {
      return;
    }

    const moveBy = new paper.Point(0, 0);
    const moveByAcceleration = event.event.shiftKey ? 3 : 1;

    switch (event.key) {
      case ARROW_KEYS.UP: {
        moveBy.y = -1 * ARROW_KEY_MOVE_BY * moveByAcceleration;
        break;
      }
      case ARROW_KEYS.DOWN: {
        moveBy.y = ARROW_KEY_MOVE_BY * moveByAcceleration;
        break;
      }
      case ARROW_KEYS.LEFT: {
        moveBy.x = -1 * ARROW_KEY_MOVE_BY * moveByAcceleration;
        break;
      }
      case ARROW_KEYS.RIGHT: {
        moveBy.x = ARROW_KEY_MOVE_BY * moveByAcceleration;
        break;
      }
      default: {
        return;
      }
    }

    this.selectionController.moveSelectedItemsBy(moveBy);
    this.debouncedFinalizeSelectedItemsMove();
  }

  onMouseUp() {
    if (this.activeSubtool === SELECTION_SUBTOOLS.RESIZE) {
      this.selectionController.finalizeSelectedItemsResize();
      this.activeSubtool = null;
    } else if (
      this.activeSubtool === SELECTION_SUBTOOLS.MOVE &&
      !this.itemsDragInProgress
    ) {
      // If the user clicked on selected item without dragging it
      this.selectionController.selectedItemsClicked();
    } else if (this.itemsDragInProgress) {
      // If the user dragged the selected items
      this.activeSubtool = null;
      this.itemsDragInProgress = false;
      this.dragPositionDiff = null;
      this.selectionController.finalizeSelectedItemsMove();
    } else if (this.selectionPath) {
      // If the user dragged the mouse to select items
      this.selectedItemClicked = false;
      this._selectPaths();
      this.selectionPath.remove();
      this.selectionPath = null;
    }
  }

  deactivate() {
    this._resetDefaultProperties();
    this.selectionController.deselectAllItems();
  }

  _selectPaths() {
    // Only select a single item if the selection path is really small
    const shouldSelectSingleItem =
      this.selectionPath.bounds.size.width *
        this.selectionPath.bounds.size.height <
      5;

    let intersectsItems = paper.project.activeLayer.children.filter(child => {
      // Ignore local items such as the selection path itself
      if (child.data.isLocal) {
        return false;
      }

      // Create an expanded bounds rectangle to detect if the click was even close to an item
      const expandedItemBounds = child.bounds.scale(5);

      return (
        child.intersects(this.selectionPath) ||
        child.contains(this.selectionPath.bounds) ||
        this.selectionPath.contains(child.bounds) ||
        // Hit test is accurate but expensive, so we only run it if the click was somewhat close to the item
        (expandedItemBounds.contains(this.selectionPath.bounds.center) &&
          child.smartHitTest(this.selectionPath.bounds.center))
      );
    });

    if (intersectsItems.length === 0) {
      return;
    }

    if (shouldSelectSingleItem) {
      // When selecting a single item, we try to make the selection as accurate as possible
      intersectsItems = [
        intersectsItems.reduce((optimalItem, item) => {
          const optimalItemHasHit = !!optimalItem.smartHitTest(
            this.selectionPath.bounds.center
          );
          const currentItemHasHit = !!item.smartHitTest(
            this.selectionPath.bounds.center
          );

          // Prefer items whose "pixels" are hit by the selection over items that don't
          if (!optimalItemHasHit && currentItemHasHit) {
            return item;
          } else if (optimalItemHasHit && !currentItemHasHit) {
            return optimalItem;
          } else {
            // Otherwise prefer the topmost items
            return item.index > optimalItem.index ? item : optimalItem;
          }
        }, intersectsItems[0])
      ];
    }

    this.selectionController.selectItems(intersectsItems);
  }

  _resetDefaultProperties() {
    this.selectionPath = null;
    this.itemsDragInProgress = false;
    this.dragPositionDiff = null;
    this.activeSubtool = null;
    this.sizeBeforeResizing = null;
    this.selectedCornerName = null;
  }
}
