import SimpleEventEmitter from '@/utils/SimpleEventEmitter';

export default class PathDrawingController extends SimpleEventEmitter {
  finalizeRawPath(path) {
    this.emit('raw-path-finalized', path);
  }

  finalizeContinuousPath(continuousPath) {
    this.emit('continuous-path-finalized', continuousPath);
  }
}
