/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'text-italic': {
    width: 10,
    height: 16,
    viewBox: '0 0 10 16',
    data: '<path pid="0" d="M4.5.167h5v1H7.382L3.655 14.832H5.5v1h-5v-1h2.118L6.345 1.167H4.5v-1z" _fill="#212121"/>'
  }
})
