<template>
  <ToolButton
    ref="toolButton"
    displayName="Color"
    class="color-selection-tool-button"
  >
    <template slot="icon">
      <div
        class="color"
        :class="{ 'white-outline': isSelectedColorBlack }"
        :style="{ background: selectedColor }"
      />
    </template>

    <template slot="menu">
      <div class="colors-container">
        <div
          v-for="availableColor in availableColors"
          :key="availableColor"
          class="color-container"
          @click="colorSelected(availableColor)"
        >
          <div class="color" :style="{ background: availableColor }" />
        </div>
      </div>
    </template>
  </ToolButton>
</template>

<script>
import ToolButton from './ToolButton';

export default {
  components: {
    ToolButton
  },

  props: {
    selectedColor: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      availableColors: [
        '#F2F2F2',
        '#B3B3B3',
        '#000000',
        '#FFC321',
        '#F89B85',
        '#FB8FD8',
        '#FB6A6A',
        '#CBA1FA',
        '#65BAFF',
        '#53CA6A'
      ]
    };
  },

  computed: {
    isSelectedColorBlack() {
      return this.selectedColor === '#000000';
    }
  },

  methods: {
    colorSelected(color) {
      this.$emit('select', color);
      this.$refs.toolButton.closeMenu();
    }
  }
};
</script>

<style scoped>
.colors-container {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  width: 120px;
}

.color-container {
  padding: 2px;
  cursor: pointer;
}

.color-container:hover {
  background: #e6e6e6;
}

.color {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.white-outline {
  border: 2px solid #fff;
  width: 20px;
  height: 20px;
}
</style>
