<template>
  <vwc-dialog
    ref="dialog"
    v-observe-keyboard.l2.enter="confirmRenameWhiteboard"
    class="dialog vvd-root"
    headline="Rename Whiteboard"
    icon-placement="side"
    icon="edit-line"
    :escapeKeyAction="hide"
    @click.stop
  >
    <div slot="body" class="content">
      <VTextfield
        v-model="whiteboardName"
        class="input"
        :maxLength="128"
        focus
        placeholder="Whiteboard Name"
        @submit="confirmRenameWhiteboard"
      />
    </div>
    <div slot="footer" class="buttons-footer">
      <vwc-button
        label="Cancel"
        appearance="outlined"
        size="normal"
        @click="hide"
      />
      <vwc-button
        label="Save"
        appearance="filled"
        connotation="primary"
        size="normal"
        :disabled="disableRenameButton"
        @click="confirmRenameWhiteboard"
      />
    </div>
  </vwc-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      whiteboardName: this.$store.getters.title,
      errorMessage: null,
      renameInProgress: false,
      isDialogOpen: false
    };
  },

  computed: {
    disableRenameButton() {
      return this.renameInProgress || !this.whiteboardName;
    }
  },

  methods: {
    ...mapActions(['renameWhiteboard', 'emitTelemetry']),

    showModal() {
      this.$refs.dialog.showModal();
      this.isDialogOpen = true;
    },

    async confirmRenameWhiteboard() {
      if (!this.isDialogOpen) {
        return;
      }
      if (this.whiteboardName) {
        this.emitTelemetry({ name: 'rename-whiteboard' });
        this.renameInProgress = true;
        this.errorMessage = null;
        try {
          await this.renameWhiteboard(this.whiteboardName);
          this.hide();
        } catch (err) {
          this.errorMessage = 'Failed to rename board, please try again.';
        } finally {
          this.renameInProgress = false;
        }
      }
    },

    hide() {
      this.$refs.dialog.close();
      this.isDialogOpen = false;
    }
  }
};
</script>
<style scoped>
.input {
  margin-top: 12px;
  width: 357px;
}

vwc-dialog {
  --dialog-min-inline-size: 405px;
  --dialog-max-inline-size: 405px;
}

.buttons-footer {
  display: flex;
  justify-content: flex-end;
  column-gap: 8px;
  margin-top: 16px;
}
</style>
