<template>
  <div
    ref="whiteboardContainer"
    class="whiteboard-container"
    :class="{ locked: isWhiteboardLocked, mobile: isMobileMode }"
  >
    <StopSharingModal ref="stopSharingModal" />
    <ClearBoardModal ref="clearBoardModal" />
    <RenameWhiteboardModal ref="renameWhiteboardModal" />

    <div v-if="isMobileMode" class="mobile-floating-board-name">
      <span>{{ title }}</span>
    </div>

    <div v-if="!isMobileMode" class="topbar">
      <div class="title">
        <div class="document-name">
          <v-icon v-if="isWhiteboardLocked" iconName="lock" xs />
          <span
            v-tooltip="title"
            class="document-title text-ellipsis"
            :class="{ 'title-editable': isWhiteboardOwner }"
            @click="openEditBoardNameModal"
            >{{ title }}</span
          >
        </div>
      </div>

      <div v-if="showLockedIndication" class="locked-whiteboard-indication">
        <span class="locked-text">The owner has locked the whiteboard</span>
      </div>
      <div v-else class="toolbar">
        <ToolButton
          displayName="Move"
          :active="activeToolType === toolTypes.HAND"
          @activated="userActivateTool({ type: toolTypes.HAND })"
        >
          <template slot="icon">
            <v-icon
              :iconName="
                activeToolType === toolTypes.HAND
                  ? 'hand-fill-negative'
                  : 'hand'
              "
            />
          </template>
        </ToolButton>
        <ToolButton
          displayName="Selection"
          :active="activeToolType === toolTypes.SELECT"
          @activated="userActivateTool({ type: toolTypes.SELECT })"
        >
          <template slot="icon">
            <v-icon
              :iconName="
                activeToolType === toolTypes.SELECT
                  ? 'select-fill-negative-2'
                  : 'select'
              "
            />
          </template>
        </ToolButton>
        <TextToolButton
          :active="activeToolType === toolTypes.TEXT"
          @activated="userActivateTool({ type: toolTypes.TEXT })"
        />
        <PenToolButton
          :active="activeToolType === toolTypes.PEN"
          @activated="
            (options) => userActivateTool({ type: toolTypes.PEN, options })
          "
          ><template slot="icon">
            <v-icon iconName="pen" />
          </template>
        </PenToolButton>
        <ShapeToolButton
          :active="activeToolType === toolTypes.SHAPE"
          @activated="
            (options) => userActivateTool({ type: toolTypes.SHAPE, options })
          "
        />

        <ArrowToolButton
          :active="activeToolType === toolTypes.ARROW"
          @activated="
            (options) => userActivateTool({ type: toolTypes.ARROW, options })
          "
        />

        <LineToolButton
          :active="activeToolType === toolTypes.LINE"
          @activated="
            (options) => userActivateTool({ type: toolTypes.LINE, options })
          "
        />

        <ColorSelectionToolButton
          :selectedColor="color"
          @select="selectColor"
        />

        <ToolButton
          displayName="Eraser"
          :active="activeToolType === toolTypes.ERASER"
          @activated="userActivateTool({ type: toolTypes.ERASER })"
        >
          <template slot="icon">
            <v-icon
              :iconName="
                activeToolType === toolTypes.ERASER
                  ? 'eraser-fill-negative'
                  : 'eraser'
              "
            />
          </template>
        </ToolButton>
        <div class="toolbar-separator"></div>

        <ToolButton
          displayName="Undo"
          :disabled="!hasUndoActions"
          @activated="undo"
        >
          <template slot="icon">
            <v-icon iconName="undo" />
          </template>
        </ToolButton>

        <ToolButton
          displayName="Redo"
          :disabled="!hasRedoActions"
          @activated="redo"
        >
          <template slot="icon">
            <v-icon iconName="redo" />
          </template>
        </ToolButton>
      </div>

      <div class="board-actions">
        <VDropdown
          v-if="isWhiteboardOwner"
          class="owner-menu-dropdown"
          minWidth="140"
          openToTheLeft
          :items="ownerMenuDropdownItems"
          @change="(layout) => runOwnerAction(layout.value)"
        >
          <v-button
            slot="toggle-button"
            app
            sm
            secondary
            outline
            class="owner-menu-button"
            iconName="Vlt-icon-more-v-negative"
          />
        </VDropdown>

        <v-button
          v-else
          app
          sm
          secondary
          outline
          class="action-button"
          iconName="download"
          :tooltip="{ content: 'Download Board' }"
          @click="downloadCanvas"
        />

        <v-button
          v-if="isWhiteboardOwner"
          app
          sm
          destructive
          @click="stopSharingWhiteboard"
          >Stop Sharing</v-button
        >
      </div>
    </div>
    <div v-show="!isLoading" class="canvas-container">
      <div
        v-show="activeToolType === toolTypes.TEXT"
        class="text-box-container"
      >
        <div
          ref="whiteboardTextBox"
          contentEditable="true"
          spellcheck="false"
          class="text-box"
          @click.stop
        ></div>
      </div>
      <div class="cursors-container">
        <RemoteCursor
          v-for="cursor in remoteCursors"
          :key="cursor.name"
          class="remote-cursor"
          :name="cursor.name"
          :color="cursor.color"
          :style="{
            transform: `translate(${cursor.pointOnScreen.x}px, ${cursor.pointOnScreen.y}px)`
          }"
          :visible="cursor.isVisible"
        >
        </RemoteCursor>
      </div>

      <div class="pan-zoom-status-overlay">
        <PanZoomStatus />
      </div>

      <canvas
        ref="whiteboardCanvas"
        class="whiteboard-canvas"
        resize
        :class="cursorClass"
        @mouseover="attachCursor"
        @mouseleave="detachCursor"
      ></canvas>
    </div>
  </div>
</template>

<script>
import CollaborativeWhiteboardService from '@/services/collaborative-whiteboard-service';
import ClearBoardModal from '@/components/modals/ClearBoardModal';
import StopSharingModal from '@/components/modals/StopSharingModal';
import RenameWhiteboardModal from '@/components/modals/RenameWhiteboardModal';
import ToolButton from '@/components/toolbar/ToolButton';
import ColorSelectionToolButton from '@/components/toolbar/ColorSelectionToolButton';
import PenToolButton from '@/components/toolbar/PenToolButton';
import TextToolButton from '@/components/toolbar/TextToolButton';
import ShapeToolButton from '@/components/toolbar/ShapeToolButton';
import LineToolButton from '@/components/toolbar/LineToolButton';
import ArrowToolButton from '@/components/toolbar/ArrowToolButton';
import RemoteCursor from '@/components/RemoteCursor';
import PanZoomStatus from '@/components/PanZoomStatus';
import CanvasToFileConverter from '@/utils/canvas-to-file-converter';
import { EXPORT_WHITEBOARD_INTERVAL } from '@/consts';
import { mapActions, mapGetters, mapState } from 'vuex';
import { generatePencilCursor } from '@/utils/pencil-cursor-generator';

const OWNER_ACTIONS = Object.freeze({
  LOCK: 'LOCK',
  DOWNLOAD: 'DOWNLOAD',
  CLEAR: 'CLEAR'
});

// Keep a user's cursor visible for up to 30 seconds after their mouse leaves the canvas
const DETACH_CURSOR_DELAY_MILLISECONDS = 30 * 1000;
let detachCursorTimeout;

export default {
  components: {
    ClearBoardModal,
    StopSharingModal,
    RenameWhiteboardModal,
    ToolButton,
    ColorSelectionToolButton,
    PenToolButton,
    ShapeToolButton,
    RemoteCursor,
    TextToolButton,
    PanZoomStatus,
    LineToolButton,
    ArrowToolButton
  },

  props: {
    participantId: {
      type: String,
      required: true
    },
    userCursorColor: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      toolTypes: CollaborativeWhiteboardService.toolsController.toolTypes,
      activeToolType: null,
      defaultColor: null,
      initialCursorPoint: null
    };
  },

  computed: {
    ...mapState(['ownerName', 'isMobileMode']),
    ...mapState('settings', ['color']),
    ...mapState('history', ['hasUndoActions', 'hasRedoActions']),
    ...mapGetters([
      'isWhiteboardOwner',
      'title',
      'isWhiteboardLocked',
      'isWhiteboardReadonly',
      'isLoading'
    ]),
    ...mapGetters('cursors', ['cursors', 'publishMyCursor']),

    showLockedIndication() {
      return (
        !this.isMobileMode && !this.isWhiteboardOwner && this.isWhiteboardLocked
      );
    },

    cursorClass() {
      if (this.activeToolType === this.toolTypes.HAND) {
        return 'grab-cursor';
      } else if (this.activeToolType === this.toolTypes.TEXT) {
        return 'text-cursor';
      } else if (this.activeToolType === this.toolTypes.SELECT) {
        return 'selection-cursor';
      } else if (this.activeToolType === this.toolTypes.PEN) {
        return 'pencil-cursor';
      } else if (this.activeToolType === this.toolTypes.ERASER) {
        return 'eraser-cursor';
      } else if (
        [this.toolTypes.LINE, this.toolTypes.SHAPE].includes(
          this.activeToolType
        )
      ) {
        return 'crosshair-cursor';
      }

      return null;
    },

    ownerMenuDropdownItems() {
      return [
        {
          value: OWNER_ACTIONS.LOCK,
          label: this.isWhiteboardLocked ? 'Unlock Board' : 'Lock Board'
        },
        { value: OWNER_ACTIONS.DOWNLOAD, label: 'Download Board' },
        { value: OWNER_ACTIONS.CLEAR, label: 'Clear All' }
      ];
    },

    remoteCursors() {
      return this.cursors.map((cursor) => {
        const isOwner = cursor.name === this.ownerName;
        return {
          ...cursor,
          name: isOwner ? `(Owner) ${cursor.name}` : cursor.name,
          color: isOwner ? '#6405D1' : cursor.color
        };
      });
    }
  },

  watch: {
    participantId: {
      handler: function () {
        // TODO: Replace this temp hacky code with real state management for participants
        CollaborativeWhiteboardService.dependencies.participantProvider.myParticipant =
          {
            participantId: this.participantId,
            color: this.userCursorColor
          };
      },
      immediate: true
    },

    color: {
      handler: function () {
        this.updateDynamicPencilCursor();
      }
    },

    isWhiteboardReadonly: {
      handler: function () {
        if (this.isWhiteboardReadonly) {
          this.activateTool(
            { type: this.toolTypes.HAND },
            { trigger: 'whiteboard-locked' }
          );
        }
      },
      immediate: true
    },

    publishMyCursor: {
      handler: function () {
        if (this.publishMyCursor) {
          if (this.initialCursorPoint) {
            this.attachCursor(this.initialCursorPoint);
          }
        } else {
          this.detachCursor(true);
        }
      }
    },

    isLoading() {
      if (!this.isLoading) {
        CollaborativeWhiteboardService.emitWhiteboardLoadedEvent();
      }
    }
  },

  mounted() {
    CollaborativeWhiteboardService.attachToCanvas(
      this.$refs.whiteboardCanvas,
      this.$refs.whiteboardTextBox
    );

    // TODO: create tools vuex module
    CollaborativeWhiteboardService.toolsController.on(
      'tool-activated',
      (toolType) => {
        this.activeToolType = toolType;
      }
    );

    this.activateTool(
      { type: this.toolTypes.HAND },
      { trigger: 'initialization' }
    );

    this.initWhiteboard({
      ownerDisplayName:
        this.$route.query.owner_display_name || 'owner_not_specified',
      displayName: this.$route.query.display_name || 'name_not_specified',
      numberOfParticipants: JSON.parse(
        this.$route.number_of_participants || '0'
      ),
      mobileMode: JSON.parse(this.$route.query.mobile_mode || 'false')
    });

    if (this.isWhiteboardOwner) {
      setInterval(
        () => {
          CollaborativeWhiteboardService.synchronizer.saveEntireWhiteboard();
        },
        // TODO: Receive the interval from the backend
        EXPORT_WHITEBOARD_INTERVAL
      );
    }

    this.updateDynamicPencilCursor();
  },

  methods: {
    ...mapActions(['initWhiteboard', 'setIsWhiteboardLocked', 'emitTelemetry']),
    ...mapActions('history', ['undo', 'redo']),
    ...mapActions('settings', ['selectColor']),

    activateTool(tool, telemetryInfo = {}) {
      this.emitTelemetry({
        name: 'tool-activated',
        data: { ...tool, ...telemetryInfo }
      });
      CollaborativeWhiteboardService.activateToolByType(
        tool.type,
        tool.options
      );
    },

    userActivateTool(tool) {
      this.activateTool(tool, { trigger: 'user-action' });
    },

    clearCanvas() {
      this.$refs.clearBoardModal.showModal();
    },

    attachCursor(e) {
      this.initialCursorPoint = {
        offsetX: e.offsetX,
        offsetY: e.offsetY
      };
      if (!this.publishMyCursor) {
        return;
      }
      // If the cursor is already attached then we only need to cancel the detach timer
      if (detachCursorTimeout) {
        clearTimeout(detachCursorTimeout);
        detachCursorTimeout = null;
        return;
      }
      const point = {
        x: e.offsetX,
        y: e.offsetY
      };
      CollaborativeWhiteboardService.cursorsTracker.attachUserCursor(point);
    },

    detachCursor(force = false) {
      this.initialCursorPoint = null;
      if (detachCursorTimeout) {
        clearTimeout(detachCursorTimeout);
        detachCursorTimeout = null;
      }

      if (force) {
        CollaborativeWhiteboardService.cursorsTracker.detachUserCursor();
        detachCursorTimeout = null;
      } else {
        detachCursorTimeout = setTimeout(() => {
          CollaborativeWhiteboardService.cursorsTracker.detachUserCursor();
          detachCursorTimeout = null;
        }, DETACH_CURSOR_DELAY_MILLISECONDS);
      }
    },

    downloadCanvas() {
      CanvasToFileConverter.downloadPaperProjectAsPng(this.title);
    },

    stopSharingWhiteboard() {
      this.$refs.stopSharingModal.showModal();
    },

    runOwnerAction(actionName) {
      switch (actionName) {
        case OWNER_ACTIONS.LOCK: {
          this.emitTelemetry({
            name: 'lock-whiteboard',
            data: { isWhiteboardLocked: this.isWhiteboardLocked }
          });
          this.setIsWhiteboardLocked(!this.isWhiteboardLocked);
          break;
        }
        case OWNER_ACTIONS.DOWNLOAD: {
          this.emitTelemetry({ name: 'download-whiteboard' });
          this.downloadCanvas();
          break;
        }
        case OWNER_ACTIONS.CLEAR: {
          this.clearCanvas();
          break;
        }
      }
    },

    /**
     * Redefines the --pencil-cursor CSS variable to make it reflect the selected color
     */
    updateDynamicPencilCursor() {
      // Create a black pencil with a small dot that represents the selected color
      const pencilCursorSvgElement = generatePencilCursor('black', this.color);

      // Serialize the SVGElement to make it usable in CSS
      const xmlSerializer = new XMLSerializer();
      const pencilCursorSvgData = xmlSerializer.serializeToString(
        pencilCursorSvgElement
      );
      const pencilCursor = `url('data:image/svg+xml,${encodeURIComponent(
        pencilCursorSvgData
      )}') 0 22, auto`;

      // Update the --pencil-cursor CSS variable
      this.$refs.whiteboardContainer.style.setProperty(
        '--pencil-cursor',
        pencilCursor
      );
    },

    openEditBoardNameModal() {
      if (this.isWhiteboardOwner) {
        this.$refs.renameWhiteboardModal.showModal();
      }
    }
  }
};
</script>

<style scoped>
.whiteboard-container {
  display: flex;
  flex-direction: column;
  background: #ffffff;

  --pencil-cursor: url('/cursors/pencil-cursor.svg') 0 16, auto;
}

.whiteboard-canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
}

canvas[resize] {
  width: 100%;
  height: 100%;
}

.topbar {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #cccccc;
  height: 50px;
}

.toolbar {
  display: flex;
  flex: 1;
  justify-content: center;
  align-self: center;
  user-select: none;
}

.locked-whiteboard-indication {
  display: flex;
  justify-content: center;
  align-self: center;
  flex: 1;
  pointer-events: none;
}

@media only screen and (min-width: 870px) {
  .locked-whiteboard-indication {
    position: fixed;
    width: 100%;
  }
}

.locked-whiteboard-indication .locked-text {
  background: #f2f2f2;
  padding: 6px 10px;
  font-size: 14px;
}

.toolbar-separator {
  width: 2px;
  height: 24px;
  background: #ccc;
  margin: 0 16px;
  margin-top: 4px;
}

.toolbar > :not(.toolbar-separator) {
  margin: 0 2px;
}

.title {
  margin-left: 32px;
  align-self: center;
  flex: 1;
  justify-content: flex-start;
  overflow: hidden;
}

.title .document-name {
  display: flex;
  font-size: 16px;
  font-weight: 600;
}

.title .document-name .document-title {
  padding-left: 20px;
}

.title .document-name .document-title.title-editable {
  cursor: pointer;
}

.locked .topbar .title .document-name .document-title {
  padding-left: 4px;
}

.board-actions {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-self: center;
  user-select: none;
  margin-right: 12px;
}

.canvas-container {
  position: relative;
  flex-grow: 1;
}

.cursors-container {
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 9;
}

.remote-cursor {
  position: absolute;
  transition: transform 20ms;
}

.whiteboard-canvas.grab-cursor {
  cursor: grab;
}

.whiteboard-canvas.grab-cursor:active {
  cursor: grabbing;
}

.whiteboard-canvas.text-cursor {
  cursor: text;
}

.whiteboard-canvas.crosshair-cursor {
  cursor: crosshair;
}

.whiteboard-canvas.selection-cursor {
  cursor: url('/cursors/selection-cursor.svg'), auto;
}

.whiteboard-canvas.eraser-cursor {
  cursor: url('/cursors/eraser-cursor.svg') 0 16, auto;
}

.whiteboard-canvas.pencil-cursor {
  cursor: var(--pencil-cursor);
}

.pan-zoom-status-overlay {
  position: absolute;
  z-index: 10;
  right: 8px;
  bottom: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 8px 8px rgba(0, 0, 0, 0.05),
    0px 2px 16px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.text-box-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9;
  overflow: hidden;
  pointer-events: none;
}

.text-box-container .text-box {
  position: absolute;
  display: none;
  min-width: 50px;
  font-size: 20px;
  font-weight: 600;
  padding: 4px 8px;
  transform-origin: left 0;
  pointer-events: all;
  border: 2px solid #7777ff;
  border-radius: 6px;
  line-height: 1.2;
}

.action-button {
  padding: 0;
}

.owner-menu-dropdown >>> .Vlt-dropdown__link {
  height: 40px;
  padding: 0 24px;
}

.owner-menu-button:active,
.Vlt-dropdown--expanded .owner-menu-button {
  background: black;
}

.owner-menu-button:active >>> svg,
.Vlt-dropdown--expanded .owner-menu-button >>> svg {
  fill: white !important;
}

.mobile-floating-board-name {
  position: fixed;
  bottom: 8px;
  left: 8px;
  z-index: 10;
  pointer-events: none;
  user-select: none;
  max-width: 50vw;
}

.mobile-floating-board-name > span {
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.45));
  color: white;
  font-size: 12px;
  font-weight: 600;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
}

.mobile-floating-board-name::before {
  position: absolute;
  z-index: -1;
  content: '';
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  filter: blur(25px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* RESPONSIVENESS MEDIA SELECTORS */
/* Hide some elements when the board is embedded in confined spaces */
@media only screen and (max-width: 200px) {
  .pan-zoom-status-overlay,
  .mobile-floating-board-name,
  .topbar {
    display: none;
  }
}

@media only screen and (max-width: 600px) and (min-width: 201px) {
  .topbar {
    display: none;
  }
}

@media only screen and (max-width: 700px) and (min-width: 601px) {
  .title {
    display: none;
  }
}
</style>
