export default {
  cursors: state => {
    return state.rawCursors.map(rawCursor => {
      return {
        ...rawCursor,
        name: rawCursor.participantId // TODO: translate the participantId to a name using the participants vuex module
      };
    });
  },

  publishMyCursor: (state, getters, rootState, rootGetters) => {
    // Maybe we should get 'publishMyCursor' from the backend/url instead of numberOfParticipants
    return (
      rootGetters.isWhiteboardOwner ||
      (rootState.numberOfParticipants <= 10 && !rootGetters.isWhiteboardLocked)
    );
  }
};
