import paper from 'paper';
import CollaborativeWhiteboardService from '@/services/collaborative-whiteboard-service';
import { saveSvgAsPng } from 'save-svg-as-png';

class CanvasToFileConverter {
  _getCanvasAsSvgElement() {
    const originalZoom = paper.view.zoom;
    const originalCenter = paper.view.center;

    // Temporarily zoom-to-fit to make sure the exported SVG contains the entire canvas contents
    // in its visible area
    CollaborativeWhiteboardService.canvasPanZoomController.zoomToFit();

    try {
      return paper.project.exportSVG({
        onExport: (item, node) => {
          // PointText items must be broken down to <tspan> instances per line of text, otherwise
          // linebreaks don't exist in the exported SVG
          if (item._class === 'PointText') {
            node.textContent = null;
            for (let i = 0; i < item._lines.length; i++) {
              const tspan = document.createElementNS(
                'http://www.w3.org/2000/svg',
                'tspan'
              );
              tspan.textContent = item._lines[i];
              let dy = item.leading;
              if (i === 0) {
                dy = 0;
              }
              tspan.setAttributeNS(null, 'x', node.getAttribute('x'));
              tspan.setAttributeNS(null, 'dy', dy);
              node.appendChild(tspan);
            }
          }
          return node;
        }
      });
    } finally {
      // Revert the temporary zoom-to-fit
      CollaborativeWhiteboardService.canvasPanZoomController.setZoom(
        originalZoom
      );
      CollaborativeWhiteboardService.canvasPanZoomController.setCenter(
        originalCenter
      );
    }
  }

  /**
   * Downloads the entire paper canvas as a PNG
   * @param {*} fileName File name excluding the .png extension
   */
  downloadPaperProjectAsPng(fileName) {
    const canvasSvgElement = this._getCanvasAsSvgElement();
    canvasSvgElement.style.backgroundColor = 'white';
    return saveSvgAsPng(canvasSvgElement, `${fileName}.png`);
  }
}

export default new CanvasToFileConverter();
