/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'lock': {
    width: 12,
    height: 13,
    viewBox: '0 0 12 13',
    data: '<path pid="0" d="M6 .5c-1.65 0-3 1.35-3 3v.75H1.5c-.45 0-.75.3-.75.75v6.75c0 .45.3.75.75.75h9c.45 0 .75-.3.75-.75V5c0-.45-.3-.75-.75-.75H9V3.5c0-1.65-1.35-3-3-3zm.75 8.775v.975h-1.5v-.975C4.8 9.05 4.5 8.525 4.5 8c0-.825.675-1.5 1.5-1.5s1.5.675 1.5 1.5c0 .525-.3 1.05-.75 1.275zM7.5 4.25h-3V3.5C4.5 2.675 5.175 2 6 2s1.5.675 1.5 1.5v.75z" _fill="#000"/>'
  }
})
