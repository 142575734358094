import Vue from 'vue';
import Router from 'vue-router';
import EmbedHome from './EmbedHome';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/embed/:sessionId',
      name: 'EmbedHome',
      component: EmbedHome
    }
  ]
});

export default router;
