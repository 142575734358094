<template>
  <div class="cursor-container" :class="{invisible: !visible}">
    <div class="cursor">
      <svg
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.15227 1.52375L1.19927 1.21905L1.52771 2.16413L4.65537 11.1641L5.0356 12.2583L5.57104 11.2311L7.57916 7.37881L11.5992 5.45083L12.7335 4.90685L11.5352 4.52375L2.15227 1.52375Z"
          :fill="color"
          stroke="white"
          stroke-miterlimit="10"
          stroke-linecap="square"
        />
      </svg>
    </div>
    <div class="name-label text-ellipsis" :style="{ background: color }">
      {{ name }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style scoped>
.cursor-container {
  filter: drop-shadow(0px 2px 8px rgb(187, 188, 189));
}

.invisible.cursor-container {
  display: none;
}

.name-label {
  padding: 0px 6px;
  margin-left: 12px;
  border-radius: 3px;
  height: 19px;
  line-height: 19px;
  min-width: 32px;
  max-width: 96px;
  color: white;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
}
</style>
