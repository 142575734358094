export default {
  addFlashMessage: ({ commit }, options) => {
    // Set Default Options
    options.sm = !!options.sm;
    options.type = options.type || 'good';
    options.time = options.time || 4000;
    options.dismiss = options.dismiss || true;
    options.id = options.id || new Date().getTime(); // Using the current time as a unique key for the message

    commit('ADD_FLASH_MESSAGE', options);
  },

  removeFlashMessage: ({ commit }, options) =>
    commit('REMOVE_FLASH_MESSAGE', options)
};
