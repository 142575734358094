/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'select-fill-negative-2': {
    width: 17,
    height: 16,
    viewBox: '0 0 17 16',
    data: '<path pid="0" d="M.995 0L6.33 16l3.556-7.111 7.111-3.556L.996 0z" _fill="#fff"/>'
  }
})
