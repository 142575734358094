import SimpleEventEmitter from '@/utils/SimpleEventEmitter';

export default class HistoryController extends SimpleEventEmitter {
  constructor() {
    super();

    this.undoStack = [];
    this.redoStack = [];
  }

  undo() {
    if (this.undoStack.length === 0) {
      return;
    }

    const { undo, redo, historyState } = this.undoStack.pop();
    try {
      undo(historyState);
    } finally {
      this.redoStack.push({ undo, redo, historyState });
      this.emit('undo-stack-size-changed', this.undoStack.length);
      this.emit('redo-stack-size-changed', this.redoStack.length);
    }
  }

  redo() {
    if (this.redoStack.length === 0) {
      return;
    }

    const { undo, redo, historyState } = this.redoStack.pop();

    try {
      redo(historyState);
    } finally {
      this.undoStack.push({ undo, redo, historyState });
      this.emit('undo-stack-size-changed', this.undoStack.length);
      this.emit('redo-stack-size-changed', this.redoStack.length);
    }
  }

  push({ undo, redo, historyState = {} }) {
    this.undoStack.push({ undo, redo, historyState });

    // Clear the redo stack because we're starting a new "timeline"
    this.redoStack = [];

    this.emit('undo-stack-size-changed', this.undoStack.length);
    this.emit('redo-stack-size-changed', this.redoStack.length);
  }

  clear() {
    this.undoStack = [];
    this.redoStack = [];

    this.emit('undo-stack-size-changed', this.undoStack.length);
    this.emit('redo-stack-size-changed', this.redoStack.length);
  }
}
