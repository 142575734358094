/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-fill-negative': {
    width: 17,
    height: 16,
    viewBox: '0 0 17 16',
    data: '<path pid="0" d="M15.826 0H6.488v2.335h6.52L.993 14.35 2.643 16 14.66 3.985v6.52h2.334V1.168C16.993.523 16.47 0 15.826 0z" _fill="#fff"/>'
  }
})
