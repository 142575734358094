import paper from 'paper';
import LineTool from './line-tool';

const DEFAULT_STROKE_WIDTH = 3;

export default class ArrowTool extends LineTool {
  activate(options) {
    super.activate();

    if (options) {
      this.strokeWidth = options.strokeWidth || DEFAULT_STROKE_WIDTH;
    }
  }

  onMouseDrag(ev) {
    if (!this.previewLine) {
      return;
    }

    this.previewLine.remove();
    this.previewLine = this.createLine(ev.downPoint, ev.point);
    this.previewLine.strokeColor = 'grey';
    this.previewLine.strokeWidth = this.strokeWidth;
    this.previewLine.dashArray = [
      5 * this.previewLine.strokeWidth,
      5 * this.previewLine.strokeWidth
    ];
    this.previewLine.data.isLocal = true;
  }

  emitUsageTelemetry() {
    this.telemetries.emitTelemetry({
      name: 'use-tool',
      data: {
        type: 'arrow',
        strokeWidth: this.strokeWidth,
        color: this.settingsProvider.color
      }
    });
  }

  createLine(downPoint, point) {
    const arrowHeadLength = this.strokeWidth + 10;
    const arrowLine = new paper.Shape.ArrowLine(
      downPoint,
      point,
      arrowHeadLength
    );

    return arrowLine;
  }
}
