import throttle from 'lodash.throttle';
import LiveTracker from './live-tracker';
import { screenPointToCanvasPoint } from '@/utils/points-converters';

const THROTTLE_USER_MOUSE_MOVE_MILLISECONDS = 20;
const THROTTLE_MOUSE_UPDATES_MILLISECONDS = 100;

/**
 * Tracks incoming/outgoing data about the positions of cursors.
 * Outgoing: Tracks the user's cursor to communicate it to other participants
 * Incoming: Receives info about cursors of other participants
 */
export default class CursorsTracker extends LiveTracker {
  constructor(communication, participantsProvider) {
    super(
      {
        startEventName: 'add-cursor',
        updateEventName: 'cursor-position-update',
        stopEventName: 'remove-cursor'
      },
      THROTTLE_MOUSE_UPDATES_MILLISECONDS,
      communication
    );

    this.canvasElement = null;
    this.participantsProvider = participantsProvider;
    this.shouldIgnoreUserCursor = false;

    this.throttledOnUserMouseMove = throttle(
      this.onUserMouseMove.bind(this),
      THROTTLE_USER_MOUSE_MOVE_MILLISECONDS
    );
  }

  attachToCanvas(canvasElement) {
    this.canvasElement = canvasElement;
  }

  /** Starts tracking the app user's cursor to emit its position in real-time */
  attachUserCursor(point) {
    if (!this.canvasElement) {
      console.error(
        'attachUserCursor called before attaching the CursorsTracker to a canvas'
      );
      return;
    }

    const { participantId, color } = this.participantsProvider.myParticipant;
    const startingPoint = screenPointToCanvasPoint(point);
    this.startTrackingOutgoingItem(participantId, { color, startingPoint });

    this.canvasElement.addEventListener(
      'mousemove',
      this.throttledOnUserMouseMove
    );
  }

  /** Stops tracking the app user's cursor */
  detachUserCursor() {
    if (!this.canvasElement) {
      console.error(
        'detachUserCursor called before attaching the CursorsTracker to a canvas'
      );
      return;
    }

    const { participantId } = this.participantsProvider.myParticipant;
    this.stopTrackingOutgoingItem(participantId);

    this.canvasElement.removeEventListener(
      'mousemove',
      this.throttledOnUserMouseMove
    );
  }

  onUserMouseMove(e) {
    if (
      this.shouldIgnoreUserCursor ||
      // This condition fixes a Firefox bug where a falsy (0, 0) offset is reported every other invocation
      (e.offsetX === 0 && e.offsetY === 0)
    ) {
      return;
    }

    const pointOnScreen = { x: e.offsetX, y: e.offsetY };
    const pointOnCanvas = screenPointToCanvasPoint(pointOnScreen);
    const point = { x: pointOnCanvas.x, y: pointOnCanvas.y };
    const { participantId } = this.participantsProvider.myParticipant;
    this.updateOutgoingItem(participantId, { point });
  }

  pauseUserCursor() {
    this.shouldIgnoreUserCursor = true;
  }

  resumeUserCursor() {
    this.shouldIgnoreUserCursor = false;
  }

  onIncomingItemStart(data) {
    super.onIncomingItemStart(data);

    const { id, metadata } = data;
    const { color, startingPoint } = metadata;
    this.emit('participant-cursor-added', {
      participantId: id,
      color,
      point: startingPoint
    });
  }

  processSingleUpdate(id, update) {
    this.emit('participant-cursor-updated', {
      participantId: id,
      point: update.point
    });
  }

  onIncomingItemEnd(data) {
    super.onIncomingItemEnd(data);

    const { id } = data;
    this.emit('participant-cursor-removed', { participantId: id });
  }
}
