export default {
  ADD_FLASH_MESSAGE: (state, options) => {
    // Using unshift to push to the head of the list in order to make the flash pop at the top of the other messages
    state.flashMessages.unshift(options);
  },

  REMOVE_FLASH_MESSAGE: (state, id) => {
    const index = state.flashMessages.findIndex(msg => msg.id === id);
    if (index >= 0) {
      state.flashMessages.splice(index, 1);
    }
  }
};
