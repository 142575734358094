import paper from 'paper';
import SimpleEventEmitter from '@/utils/SimpleEventEmitter';

/**
 * Wrapper around paper.Path to make it easier to synchronize in real-time
 */
export default class ContinuousPathWrapper extends SimpleEventEmitter {
  constructor(id, createdByParticipantId, createdAt, options = {}) {
    super();
    this.id = id;

    const { strokeColor, strokeWidth, fillColor } = options;
    this.options = options;
    this.path = new paper.Path();
    this.path.name = id;
    this.path.strokeColor = strokeColor;
    this.path.strokeWidth = strokeWidth;
    this.path.fillColor = fillColor;
    this.path.data.id = id;
    this.path.data.createdByParticipantId = createdByParticipantId;
    this.path.data.createdAt = Math.ceil(createdAt);
  }

  add(point) {
    this.path.add(point);
    this.emit('point-added', { x: point.x, y: point.y });
  }

  finalize() {
    // Detect a path that only has a single point (i.e. the user made a single click without moving their cursor)
    if (
      this.path.segments.length === 1 ||
      (this.path.segments.length === 2 &&
        this.path.segments[0].point.equals(this.path.segments[1].point))
    ) {
      // Add an extra point to that single-point path, otherwise it will not be rendered
      this.add(this.path.segments[0].clone().point.add(1));
    }

    this.path.simplify();
    this.emit('finalized');
  }
}
