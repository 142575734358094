import paper from 'paper';
import tween from '@tweenjs/tween.js';
import SimpleEventEmitter from '@/utils/SimpleEventEmitter';
import debounce from 'lodash.debounce';

const MIN_ZOOM_FACTOR = 0.25;
const MAX_ZOOM_FACTOR = 4;
// Factors that we will round to, e.g. when going from 0.47 to 0.52, just round it to be 0.5 instead
const ZOOM_SNAP_FACTORS = [0.5, 1, 1.5, 2, 3];

export default class CanvasPanZoomController extends SimpleEventEmitter {
  constructor(boundariesController) {
    super();
    this.boundariesController = boundariesController;

    // Detect scrolling and zooming in (Ctrl + Scroll)
    document.addEventListener('wheel', this._onMouseWheel.bind(this), {
      passive: false
    });

    // Detect pinching on touch devices
    document.addEventListener('touchstart', this._onTouchStart.bind(this), {
      passive: false
    });
    document.addEventListener('touchmove', this._onTouchMove.bind(this), {
      passive: false
    });
    document.addEventListener('touchend', this._onTouchEnd.bind(this), {
      passive: false
    });

    this.debouncedFinalizePan = debounce(this.finalizePan, 50, {
      maxWait: 500
    });
  }

  setCenter(point) {
    paper.view.center = point;
    this.emit('pan');
  }

  finalizePan() {
    const correctedCenter = this.boundariesController.getCorrectedCenter();
    if (correctedCenter) {
      this.animateCenter(correctedCenter);
    }
  }

  animateCenter(point, animationDuration = 250) {
    const center = { x: paper.view.center.x, y: paper.view.center.y };
    const centerAnimation = new tween.Tween(center)
      .to({ x: point.x, y: point.y }, animationDuration)
      .easing(tween.Easing.Quadratic.Out)
      .onUpdate(() => {
        paper.view.center = center;
      })
      .onComplete(() => {
        this.setCenter(point);
      });
    centerAnimation.start();
  }

  /**
   * Changes the zoom factor to the given factor and adjusts the pan based on the point that the user is zooming onto
   * @param {*} factor Values between 0 to 1 will zoom-out. Values larger than 1 will zoom-in
   * @param {*} zoomOnPoint (Optional) The point-on-screen that is being zoomed into (normally the user's cursor location)
   */
  setZoom(factor, zoomOnPoint = null) {
    const oldZoom = paper.view.zoom;
    const beta = oldZoom / factor;
    zoomOnPoint = zoomOnPoint || paper.view.projectToView(paper.view.center);
    const mousePosition = new paper.Point(zoomOnPoint.x, zoomOnPoint.y);

    // viewToProject: returns the coordinates in the Project space from the Screen Coordinates
    const viewPosition = paper.view.viewToProject(mousePosition);
    const currentCenter = paper.view.center;

    const delta = viewPosition.subtract(currentCenter);
    const offset = viewPosition
      .subtract(delta.multiply(beta))
      .subtract(currentCenter);

    paper.view.zoom = factor;
    paper.view.center = paper.view.center.add(offset);

    paper.view.draw();
    this.emit('zoom', { factor });
  }

  zoomToFit() {
    if (paper.project.activeLayer.children.length === 0) {
      return;
    }

    const layerBounds = paper.project.activeLayer.bounds;

    // Normalize the zoom before making any calculation
    this.setZoom(1);

    // Align the center of the view with the center of the layer
    this.setCenter(layerBounds.center);

    // Adjust zoom so that the larger dimension fits entirely into the view
    const minHorizontalFactor =
      paper.view.bounds.width / (layerBounds.width + 16);
    const minVerticalFactor =
      paper.view.bounds.height / (layerBounds.height + 16);
    const newZoomFactor = Math.min(minHorizontalFactor, minVerticalFactor);

    this.setZoom(Math.min(4, Math.max(0.01, newZoomFactor)));
  }

  /**
   * Moves the center of the canvas by the specified amount of pixels
   */
  moveCenterBy(deltaX, deltaY) {
    const newCenter = paper.view.center.subtract(
      new paper.Point(deltaX, deltaY)
    );
    this.setCenter(newCenter);
  }

  /**
   * Sets the zoom
   * @param {*} shouldZoomIn
   */
  zoomWithLimits(factor, zoomOnPoint) {
    const oldZoomFactor = paper.view.zoom;
    // When the factor is really close to a user-friendly value, snap to it (e.g. 0.99 to 1.00)
    factor = this._snapToUserFriendlyZoomFactor(oldZoomFactor, factor);

    // Apply min/max zoom limits
    factor = Math.max(factor, MIN_ZOOM_FACTOR);
    factor = Math.min(factor, MAX_ZOOM_FACTOR);
    this.setZoom(factor, zoomOnPoint);
  }

  _onMouseWheel(event) {
    event.preventDefault();

    // Note: event.ctrlKey is true when pinching on a trackpad
    if (event.ctrlKey || event.metaKey) {
      const zoomFactor =
        event.deltaY < 0 ? paper.view.zoom * 1.05 : paper.view.zoom * 0.95;
      const zoomOnPoint = { x: event.offsetX, y: event.offsetY };
      this.zoomWithLimits(zoomFactor, zoomOnPoint);
    } else {
      this.moveCenterBy(
        -1 * event.deltaX * (1 / paper.view.zoom),
        -1 * event.deltaY * (1 / paper.view.zoom)
      );
      // Use a debounced version of finalizePan because wheel is a spammy event
      this.debouncedFinalizePan();
    }
  }

  _snapToUserFriendlyZoomFactor(oldZoomFactor, newZoomFactor) {
    const factor = ZOOM_SNAP_FACTORS.find(userFriendlyZoomFactor => {
      const lowerFactor = Math.min(oldZoomFactor, newZoomFactor);
      const greaterFactor = Math.max(oldZoomFactor, newZoomFactor);

      if (
        lowerFactor < userFriendlyZoomFactor &&
        userFriendlyZoomFactor < greaterFactor
      ) {
        return userFriendlyZoomFactor;
      }
    });

    return factor || newZoomFactor;
  }

  _onTouchStart(event) {
    if (event.touches.length !== 2) {
      return;
    }

    this.pinchStartDistance = Math.hypot(
      event.touches[0].pageX - event.touches[1].pageX,
      event.touches[0].pageY - event.touches[1].pageY
    );
    this.pinchInitialZoom = paper.view.zoom;
  }

  _onTouchMove(event) {
    if (!this.pinchStartDistance) {
      return;
    }
    event.preventDefault();

    const deltaDistance = Math.hypot(
      event.touches[0].pageX - event.touches[1].pageX,
      event.touches[0].pageY - event.touches[1].pageY
    );
    const scale = deltaDistance / this.pinchStartDistance;

    const zoomFactor = this.pinchInitialZoom * scale;
    const touchCenter = {
      x: (event.touches[1].pageX + event.touches[0].pageX) / 2,
      y: (event.touches[1].pageY + event.touches[0].pageY) / 2
    };
    this.zoomWithLimits(zoomFactor, touchCenter);
  }

  _onTouchEnd() {
    this.isPinching = false;
  }
}
