<template>
  <transition name="slide">
    <div
      class="Vlt-callout flash-message"
      :class="[calloutClass, { 'off-screen': offScreen }]"
    >
      <div v-if="options.customIcon" class="Vlt-callout__icon">
        <v-icon :iconName="options.customIcon" />
      </div>
      <i v-else />
      <div class="Vlt-callout__content">
        <h4 v-if="hasTitle">
          {{ options.title }}
        </h4>
        <p class="selectable" :class="{ 'flash-message-bold-text': !hasTitle }">
          <slot name="text" />
        </p>
        <button
          v-if="options.button && options.button.actions"
          class="Vlt-callout__cta"
          @click="dispatchButtonAction"
        >
          {{ buttonText }}
        </button>
      </div>
      <button
        v-if="options.dismiss"
        class="Vlt-callout__dismiss"
        @click="removeSelf"
      ></button>
    </div>
  </transition>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'VFlashMessage',

  props: {
    options: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      offScreen: true,
      timeout: null
    };
  },

  computed: {
    calloutClass() {
      return `Vlt-callout--${this.options.type}`;
    },
    buttonText() {
      return this.options?.button?.text || 'ok';
    },
    hasTitle() {
      return !!this.options?.title;
    }
  },

  mounted() {
    setTimeout(() => (this.offScreen = false), 10);
    this.timeout = setTimeout(() => this.removeSelf(), this.options.time);
  },

  methods: {
    ...mapActions('flashMessages', ['removeFlashMessage']),

    removeSelf() {
      clearTimeout(this.timeout);
      this.$nextTick(() => this.removeFlashMessage(this.options.id));
    },

    dispatchButtonAction() {
      if (this.options?.button?.actions) {
        this.options?.button?.actions.forEach(action => {
          this.$store.dispatch(action.name, action.payload);
        });
      }
    }
  }
};
</script>

<style scoped>
.flash-message {
  transition: transform 0.2s;
}

.slide-leave-to {
  transform: translateX(-410px);
}

.off-screen {
  transform: translateX(-360px);
}

.flash-message-bold-text {
  font-weight: 600;
}
</style>
