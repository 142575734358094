/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'select-fill-negative': {
    width: 16,
    height: 16,
    viewBox: '0 0 33 32',
    data: '<g clip-path="url(#clip0)" filter="url(#filter0_ddd)"><path pid="0" d="M8.995 6l5.334 16 3.556-7.111 7.111-3.556L8.996 6z" _fill="#fff"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M17.14 14.143l5.25-2.624L10.576 7.58l3.938 11.812 2.625-5.25zM14.328 22L8.995 6l16.001 5.333-7.111 3.556L14.329 22z" _fill="#000"/></g><defs><clipPath id="clip0"><path pid="2" _fill="#fff" transform="translate(8.995 6)" d="M0 0h16.001v16H0z"/></clipPath><filter id="filter0_ddd" x=".995" y="0" width="32.001" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="2"/><feGaussianBlur stdDeviation="4"/><feColorMatrix values="0 0 0 0 0.0745098 0 0 0 0 0.0784314 0 0 0 0 0.0823529 0 0 0 0.1 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="2"/><feGaussianBlur stdDeviation="2"/><feColorMatrix values="0 0 0 0 0.0745098 0 0 0 0 0.0784314 0 0 0 0 0.0823529 0 0 0 0.05 0"/><feBlend in2="effect1_dropShadow" result="effect2_dropShadow"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="4"/><feGaussianBlur stdDeviation="1"/><feColorMatrix values="0 0 0 0 0.0745098 0 0 0 0 0.0784314 0 0 0 0 0.0823529 0 0 0 0.05 0"/><feBlend in2="effect2_dropShadow" result="effect3_dropShadow"/><feBlend in="SourceGraphic" in2="effect3_dropShadow" result="shape"/></filter></defs>'
  }
})
