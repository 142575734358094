import { AVAILABLE_TEXT_STYLES, DEFAULT_LINE_HEIGHT } from '@/consts';

export function textItemStyleToTextCss(textStyle, fontStyle = {}) {
  const style = {};

  if (fontStyle.fontSize) {
    style['font-size'] = `${fontStyle.fontSize}px`;
  }
  if (fontStyle.lineHeight) {
    style['line-height'] = `${fontStyle.lineHeight}px`;
  }
  if (textStyle.isBold) {
    style['font-weight'] = '600';
  } else if (fontStyle.fontWeight) {
    style['font-weight'] = fontStyle.fontWeight;
  }
  style['font-style'] = textStyle.isItalic ? 'italic' : '';
  return style;
}

export function getTextStyleByName(textStyleName) {
  return AVAILABLE_TEXT_STYLES[textStyleName];
}

export function getTextStyleFontSize(textStyle) {
  return AVAILABLE_TEXT_STYLES[textStyle.fontStyle].fontSize;
}

export function getFontSizePadding(fontSize) {
  return (
    (fontSize * DEFAULT_LINE_HEIGHT - fontSize / DEFAULT_LINE_HEIGHT) * 0.9
  );
}
