/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-check-bold': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.45 3.45L7.5 17.4l-4.95-4.95c-.6-.6-1.5-.6-2.1 0-.6.6-.6 1.5 0 2.1l6 6c.3.3.6.45 1.05.45.45 0 .75-.15 1.05-.45l15-15c.6-.6.6-1.5 0-2.1-.6-.6-1.5-.6-2.1 0z"/>'
  }
})
