/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pencil': {
    width: 17,
    height: 16,
    viewBox: '0 0 17 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.852.146a.5.5 0 00-.707 0L9.646 2.645l-.001.001-.002.002-7.499 7.498a.5.5 0 00-.137.256l-1 5a.5.5 0 00.589.588l5-1a.5.5 0 00.256-.136l10-10a.5.5 0 000-.708l-4-4zm1.146 6.147L15.793 4.5l-3.294-3.293L10.705 3l3.293 3.293zm-4-2.586L13.291 7l-7.04 7.04-4.116.823.823-4.117 7.04-7.039z" _fill="#000"/>'
  }
})
