const PENCIL_BODY_PATH =
  'M14.1 3.5L6.3 11.3C6.1 11.5 6 11.7 6 12V15C6 15.6 6.4 16 7 16H10C10.3 16 10.5 15.9 10.7 15.7L18.5 7.9L14.1 3.5Z';
const PENCIL_ERASER_PATH =
  'M21.7 3.3L18.7 0.3C18.3 -0.1 17.7 -0.1 17.3 0.3L15.5 2.1L19.9 6.5L21.7 4.7C22.1 4.3 22.1 3.7 21.7 3.3Z';
const ICON_SIZE = 22;
const CIRCLE_RADIUS = 2.5;

/**
 * Generates an SVGElement that can be used as a cursor for the pencil tool.
 * @param {*} pencilBodyColor Color of the pencil itself
 * @param {*} targetColor Color of the small circle
 * @returns SVGElement
 */
export function generatePencilCursor(pencilBodyColor, targetColor) {
  const svg = createElementSvgNS('svg');
  svg.setAttribute('width', ICON_SIZE);
  svg.setAttribute('height', ICON_SIZE);

  // Create the circle that shows the given color (positioned at the bottom-left corner of the SVG)
  const colorCircle = createSvgCircle(
    CIRCLE_RADIUS,
    ICON_SIZE - CIRCLE_RADIUS,
    CIRCLE_RADIUS,
    targetColor
  );

  // Add the pen itself
  const pencilContainerGroup = createElementSvgNS('g');
  const pencilBodyPath = createSvgPath(PENCIL_BODY_PATH, pencilBodyColor);
  const pencilEraserPath = createSvgPath(PENCIL_ERASER_PATH, pencilBodyColor);

  // Group them all together into the SVG element
  pencilContainerGroup.appendChild(pencilBodyPath);
  pencilContainerGroup.appendChild(pencilEraserPath);
  svg.appendChild(colorCircle);
  svg.appendChild(pencilContainerGroup);

  return svg;
}

function createSvgCircle(centerX, centerY, radius, fillColor) {
  const circle = createElementSvgNS('circle');
  circle.setAttribute('cx', centerX);
  circle.setAttribute('cy', centerY);
  circle.setAttribute('r', radius);
  circle.setAttribute('fill', fillColor);

  return circle;
}

function createSvgPath(pathData, fillColor) {
  const path = createElementSvgNS('path');
  path.setAttribute('d', pathData);
  path.setAttribute('fill', fillColor);

  return path;
}

function createElementSvgNS(elementName) {
  return document.createElementNS('http://www.w3.org/2000/svg', elementName);
}
