<template>
  <div class="flash-messages-container">
    <VFlashMessage
      v-for="message in flashMessages"
      :key="message.id"
      :options="message"
    >
      <template slot="text">
        {{ message.text }}
      </template>
    </VFlashMessage>
  </div>
</template>
<script>
import VFlashMessage from './VFlashMessage';
import { mapState } from 'vuex';

export default {
  name: 'VFlashMessages',

  components: {
    VFlashMessage
  },

  computed: {
    ...mapState('flashMessages', ['flashMessages'])
  }
};
</script>

<style scoped>
.flash-messages-container {
  position: absolute;
  display: inline-flex;
  flex-direction: column;
  margin: 60px 10px 10px 10px;
  width: 410px;
  z-index: 10000;
}
</style>
