import paper from 'paper';
import { FONT_FAMILY, PASTE_OFFSET } from '@/consts';
import {
  canvasPointToScreenPoint,
  screenPointToCanvasPoint,
  calcTopLeftTextBoxByTextItemTopLeftCanvasPoint,
  calcTextBaselinePointByTopLeftTextboxPoint
} from '@/utils/points-converters';
import { getTextStyleByName } from '@/utils/text-utils';
import logger from '@/services/logger';
import LogicalPaperGroup from '@/utils/logical-paper-group';

export default class ActionsController {
  constructor({
    settingsProvider,
    selectionController,
    textController,
    toolsController,
    canvasPanZoomController,
    synchronizer,
    pathDrawingController,
    eraserController,
    history,
    canvasBoundariesController
  }) {
    this.settingsProvider = settingsProvider;
    this.selectionController = selectionController;
    this.synchronizer = synchronizer;
    this.textController = textController;
    this.toolsController = toolsController;
    this.panZoomController = canvasPanZoomController;
    this.pathDrawingController = pathDrawingController;
    this.eraserController = eraserController;
    this.history = history;
    this.canvasBoundariesController = canvasBoundariesController;
    this._rawCopiedGroup = null;
  }

  init() {
    this.settingsProvider.onChange('textColor', color => {
      this.textController.textBoxController.setColor(color);
    });

    this.settingsProvider.onChange('textStyle', textStyle => {
      this.textController.textBoxController.setTextStyle(textStyle);
    });

    this.pathDrawingController.on('raw-path-finalized', rawPath => {
      this.synchronizer.sendRawPath(rawPath);
      this._saveCreatedPathToHistory(rawPath);
    });

    this.selectionController.on('selected-items-clicked', selectedItems => {
      // if only one items is selected and its a text item
      if (
        selectedItems.length === 1 &&
        selectedItems[0] instanceof paper.PointText
      ) {
        this._enterTextEditMode(selectedItems[0]);
      }
    });

    this.textController.on('text-item-clicked', textItem => {
      this._enterTextEditMode(textItem);
    });

    this.textController.on('add-text-item', textItem => {
      this._fillTextItem(textItem);
      this.synchronizer.sendRawPath(textItem);
      this._saveCreatedPathToHistory(textItem);
    });

    this.textController.on('update-text-item', textItem => {
      const originalTextItemProperties = this._getTextItemEditableProperties(
        textItem
      );
      this._fillTextItem(textItem);
      if (!this._isTextItemModified(textItem, originalTextItemProperties)) {
        return;
      }
      this._updateTextItem(textItem);
      const doUpdate = historyState => {
        const item = paper.project.activeLayer.getChildById(
          historyState.textItemId
        );
        const textItemProperties = this._getTextItemEditableProperties(item);
        this._fillTextItem(item, historyState.originalTextItemProperties);
        this._updateTextItem(item);
        historyState.originalTextItemProperties = textItemProperties;
      };

      this.history.push({
        undo: doUpdate,
        redo: doUpdate,
        historyState: {
          textItemId: textItem.data.id,
          originalTextItemProperties
        }
      });
    });

    this.pathDrawingController.on(
      'continuous-path-finalized',
      continuousPath => {
        this._saveCreatedPathToHistory(continuousPath.path);
      }
    );

    this.eraserController.on('delete-items', items => {
      this.deleteItems(items);
    });
  }

  _saveCreatedPathToHistory(path) {
    const serializedPathData = path.exportJSON();

    this.history.push({
      undo: historyState => {
        const path = paper.project.activeLayer.getChildrenByIds([
          historyState.pathId
        ])[0];
        if (path.bounds.selected) {
          this.selectionController.deselectAllItems();
        }

        path.remove();
        this.synchronizer.deleteItems([historyState.pathId]);
      },
      redo: historyState => {
        const path = paper.Path.createFromJSON(serializedPathData);
        path.data.id = historyState.pathId;
        this.synchronizer.sendRawPath(path);
      },
      historyState: { pathId: path.data.id }
    });
  }

  deleteSelectedItems() {
    // Make a copy of the selectedItems array, because after calling deselect this array will be empty
    const selectedItems = [...this.selectionController.getSelectedItems()];
    this.selectionController.deselectAllItems();
    this.deleteItems(selectedItems);
  }

  deleteItems(items) {
    const itemIds = items.map(item => item.data.id);

    // Attempt to synchronize this change with other participants before applying it locally, because synchronization may fail
    this.synchronizer.deleteItems(itemIds);

    items.forEach(item => (item.data._indexBeforeDeleting = item.index));
    const deletedGroup = new paper.Group(items);
    const serializedDeletedGroup = deletedGroup.exportJSON();
    deletedGroup.remove();

    this.history.push({
      undo: historyState => {
        const group = new paper.Group();
        group.importJSON(historyState.serializedDeletedGroup);
        group.selected = false;

        try {
          this.synchronizer.addEntireGroup(group);
        } catch (error) {
          group.remove();
          throw error;
        }

        group.removeChildren().forEach(item => {
          paper.project.activeLayer.insertChild(
            item.data._indexBeforeDeleting,
            item
          );
          delete item.data['_indexBeforeDeleting'];
        });
        group.remove();
      },

      redo: historyState => {
        const itemsToRemoveAgain = paper.project.activeLayer.getChildrenByIds(
          historyState.itemIds
        );
        if (itemsToRemoveAgain.some(item => item.bounds.selected)) {
          this.selectionController.deselectAllItems();
        }
        const group = new paper.Group(itemsToRemoveAgain);
        group.remove();
        this.synchronizer.deleteItems(historyState.itemIds);
      },
      historyState: { itemIds, serializedDeletedGroup }
    });
  }

  setSelectedItemsColor(color) {
    const selectedItems = this.selectionController.getSelectedItems();
    this._setItemsColorAction(
      selectedItems.filter(item => !(item instanceof paper.PointText)),
      color
    );
  }

  setSelectedTextItemsColor(color) {
    const selectedItems = this.selectionController.getSelectedItems();
    this._setItemsColorAction(
      selectedItems.filter(item => item instanceof paper.PointText),
      color
    );
  }

  _setItemsColorAction(items, color) {
    if (items.length === 0) {
      return;
    }

    const idsToUpdate = [];
    const idsToOldColorMap = new Map();

    items.forEach(item => {
      idsToUpdate.push(item.data.id);
      const previousColor = item.strokeColor || item.fillColor;
      idsToOldColorMap.set(item.data.id, previousColor.toCSS(true));
    });

    // Attempt to synchronize this change with other participants before applying it locally, because synchronization may fail
    this.synchronizer.updateItemsColor({ ids: idsToUpdate, color });

    // Apply the change locally
    items.forEach(item => {
      this._setLocalItemColor(item, color);
    });

    this.history.push({
      undo: historyState => {
        // Deselect items before undoing/redoing color changes, otherwise weird stuff happens
        this.selectionController.deselectAllItems();

        paper.project.activeLayer
          .getChildrenByIds(historyState.itemIds)
          .forEach(item => {
            const oldColor = historyState.idsToOldColorMap.get(item.data.id);
            this._setLocalItemColor(item, oldColor);
            this.synchronizer.updateItemsColor({
              ids: [item.data.id],
              color: oldColor
            });
          });
      },
      redo: historyState => {
        // Deselect items before undoing/redoing color changes, otherwise weird stuff happens
        this.selectionController.deselectAllItems();

        paper.project.activeLayer
          .getChildrenByIds(historyState.itemIds)
          .forEach(item => {
            this._setLocalItemColor(item, historyState.color);
          });
        this.synchronizer.updateItemsColor({
          ids: historyState.itemIds,
          color: historyState.color
        });
      },
      historyState: { itemIds: idsToUpdate, idsToOldColorMap, color }
    });
  }

  finalizeMoveItems({ items, position, originalPosition }) {
    const ids = items.map(item => item.data.id);

    try {
      this.synchronizer.moveItems({
        position: { x: position.x, y: position.y },
        ids
      });
    } catch (error) {
      // Revert the change locally
      paper.Group.executeOnTemporaryGroup(
        paper.project.activeLayer.getChildrenByIds(ids),
        group => {
          group.position = originalPosition;
        }
      );
      throw error;
    }

    this.history.push({
      undo: historyState => {
        const itemsToRestore = paper.project.activeLayer.getChildrenByIds(
          historyState.ids
        );
        paper.Group.executeOnTemporaryGroup(itemsToRestore, group => {
          group.position = historyState.originalPosition;
        });
        if (itemsToRestore.some(item => item.bounds.selected)) {
          this.selectionController.refreshSelectedItems();
        }

        this.synchronizer.moveItems({
          position: historyState.originalPosition,
          ids: historyState.ids
        });
      },
      redo: historyState => {
        const itemsToRestore = paper.project.activeLayer.getChildrenByIds(
          historyState.ids
        );
        paper.Group.executeOnTemporaryGroup(itemsToRestore, group => {
          group.position = historyState.position;
        });
        if (itemsToRestore.some(item => item.bounds.selected)) {
          this.selectionController.refreshSelectedItems();
        }

        this.synchronizer.moveItems({
          position: historyState.position,
          ids: historyState.ids
        });
      },
      historyState: { position, originalPosition, ids }
    });
  }

  finalizeResizeItems({
    items,
    size,
    center,
    sizeBeforeResizing,
    centerBeforeResizing
  }) {
    const ids = items.map(item => item.data.id);

    try {
      this.synchronizer.resizeItems({
        size: { width: size.width, height: size.height },
        center: { x: center.x, y: center.y },
        ids
      });
    } catch (error) {
      // Revert the change locally
      this._revertResize(items, sizeBeforeResizing, centerBeforeResizing);
      throw error;
    }

    this.history.push({
      undo: historyState => {
        const itemsToRestore = paper.project.activeLayer.getChildrenByIds(
          historyState.ids
        );

        this._revertResize(
          itemsToRestore,
          historyState.sizeBeforeResizing,
          historyState.centerBeforeResizing
        );

        if (itemsToRestore.some(item => item.bounds.selected)) {
          this.selectionController.refreshSelectedItems();
        }

        this.synchronizer.resizeItems({
          ids: historyState.ids,
          size: historyState.sizeBeforeResizing,
          center: historyState.centerBeforeResizing
        });
      },
      redo: historyState => {
        const itemsToRestore = paper.project.activeLayer.getChildrenByIds(
          historyState.ids
        );
        this._revertResize(
          itemsToRestore,
          historyState.size,
          historyState.center
        );

        if (itemsToRestore.some(item => item.bounds.selected)) {
          this.selectionController.refreshSelectedItems();
        }

        this.synchronizer.resizeItems({
          ids: historyState.ids,
          size: historyState.size,
          center: historyState.center
        });
      },
      historyState: {
        size,
        sizeBeforeResizing,
        center,
        centerBeforeResizing,
        ids
      }
    });
  }

  _updateTextItem(textItem) {
    const update = {
      id: textItem.data.id,
      properties: this._getTextItemEditableProperties(textItem)
    };
    this.synchronizer.updateTextItem(update);
  }

  _setLocalItemColor(item, color) {
    if (item.hasFill()) {
      item.fillColor = color;
    }

    if (item.hasStroke()) {
      item.strokeColor = color;
    }
  }

  _enterTextEditMode(textItem) {
    const TEXT_TOOL_TYPE = this.toolsController.toolTypes.TEXT;
    const position = canvasPointToScreenPoint(
      calcTopLeftTextBoxByTextItemTopLeftCanvasPoint(textItem.bounds.topLeft)
    );

    this.toolsController.activateTool(TEXT_TOOL_TYPE, {
      textItem
    });
    this.settingsProvider.textColor = textItem.fillColor.toCSS();
    this.settingsProvider.textStyle = textItem.data.textStyle;
    setTimeout(() => {
      this.textController.enterTextItemEditMode(
        textItem,
        position,
        this.settingsProvider.zoomFactor
      );
    }, 0);
  }

  _fillTextItem(textItem, properties = {}) {
    textItem.style = properties.style || this._getSettingsStyleForTextItem();
    textItem.content =
      properties.content ||
      this.textController.textBoxController.textBoxContent;
    textItem.data.textStyle = { ...this.settingsProvider.textStyle };
    textItem.point = calcTextBaselinePointByTopLeftTextboxPoint(
      screenPointToCanvasPoint(
        this.textController.textBoxController.textBoxPosition
      ),
      getTextStyleByName(this.settingsProvider.textStyle.fontStyle)
    );

    textItem.visible = true;
  }

  _getSettingsStyleForTextItem() {
    const fontStyle = getTextStyleByName(
      this.settingsProvider.textStyle.fontStyle
    );
    const style = {
      fontSize: fontStyle.fontSize,
      leading: fontStyle.lineHeight
    };
    const fontWeight = [];
    if (this.settingsProvider.textStyle.isBold) {
      fontWeight.push('600');
    } else if (fontStyle.fontWeight) {
      fontWeight.push(fontStyle.fontWeight);
    }
    if (this.settingsProvider.textStyle.isItalic) {
      fontWeight.push('italic');
    }
    if (fontWeight.length > 0) {
      style.fontWeight = fontWeight.join(' ');
    }
    return {
      fillColor: this.settingsProvider.textColor,
      fontFamily: FONT_FAMILY,
      strokeWidth: 0,
      ...style
    };
  }

  _getTextItemEditableProperties(textItem) {
    return {
      content: textItem.content,
      point: { x: textItem.point.x, y: textItem.point.y },
      style: {
        fillColor: textItem.style.fillColor.toCSS(),
        fontSize: textItem.style.fontSize,
        fontWeight: textItem.style.fontWeight,
        fontFamily: textItem.style.fontFamily,
        strokeWidth: textItem.style.strokeWidth,
        leading: textItem.style.leading
      }
    };
  }

  _isTextItemModified(textItem, oldProps) {
    const newProps = this._getTextItemEditableProperties(textItem);
    return JSON.stringify(newProps) !== JSON.stringify(oldProps);
  }

  copySelectedItems() {
    if (this.selectionController.selectedItemsGroup) {
      this._rawCopiedGroup = this.selectionController.selectedItemsGroup.exportJSON();
    }
  }

  pasteClipboardItems() {
    if (!this._rawCopiedGroup) {
      return;
    }

    const itemIds = this._paste(this._rawCopiedGroup).map(
      clonedItem => clonedItem.data.id
    );

    if (itemIds.length === 0) {
      return;
    }

    this.toolsController.activateTool(this.toolsController.toolTypes.SELECT);

    this.history.push({
      undo: historyState => {
        const group = new paper.Group(
          paper.project.activeLayer.getChildrenByIds(historyState.itemIds)
        );
        group.remove();
        this.synchronizer.deleteItems(historyState.itemIds);
      },
      redo: historyState => {
        historyState.itemIds = this._paste(historyState.rawCopiedGroup).map(
          clonedItem => clonedItem.data.id
        );
      },
      historyState: { rawCopiedGroup: this._rawCopiedGroup, itemIds }
    });
  }

  /**
   * Pastes the given raw clipboard content onto the board, thereby creating a clone of the
   * original group.
   * @param {*} rawClipboardText The pasted contents, expected as a JSON string of a group
   * @returns Array of cloned items
   */
  _paste(rawClipboardText) {
    const clipboardGroup = new paper.Group();
    try {
      clipboardGroup.importJSON(rawClipboardText);
    } catch (err) {
      logger.warning(
        'Clipboard contents could not be imported as a JSON into the board',
        { rawClipboardText }
      );
      return;
    }

    // Add some offset to the clone so that it won't overlap with its origin
    clipboardGroup.position = clipboardGroup.position.add(PASTE_OFFSET);
    clipboardGroup.selected = false;

    // If the clone is outside of the canvas boundaries, cancel it
    if (
      !this.canvasBoundariesController.isPointWithinBoundaries(
        clipboardGroup.bounds.bottomRight
      )
    ) {
      clipboardGroup.remove();
      return [];
    }

    // If the clone is outside of the visible boundaries, move the center to make it visible
    if (!paper.view.bounds.contains(clipboardGroup.position)) {
      this.panZoomController.animateCenter(clipboardGroup.position);
    }

    // Generate new IDs for all the cloned items
    const clonedItems = [];
    clipboardGroup.children.forEach(child => {
      child.data.copiedFromId = child.data.id;
      child.data.id = this.synchronizer.generateId();
      clonedItems.push(child);
    });

    try {
      this.synchronizer.addEntireGroup(clipboardGroup);
    } catch (err) {
      clipboardGroup.remove();
      throw err;
    }

    clipboardGroup.ungroup();

    // Move selection over to the cloned items
    this.selectionController.deselectAllItems();
    this.selectionController.selectItems(clonedItems);

    // Copy the re-positioned clone to clipboard, so that if the user pastes again,
    // then the 2nd clone won't overlap with the 1st clone
    this.copySelectedItems();

    return clonedItems;
  }

  getNewestItem() {
    if (!paper.project.activeLayer.hasChildren) {
      return null;
    }

    // Return the item whose 'createdAt' value is maximal
    return paper.project.activeLayer.children?.reduce(
      (newestItem, item) =>
        item.data.createdAt && item.data.createdAt >= newestItem.data.createdAt
          ? item
          : newestItem,
      paper.project.activeLayer.children[0]
    );
  }

  _revertResize(items, sizeBeforeResizing, centerBeforeResizing) {
    const temporaryGroup = new LogicalPaperGroup(items);
    temporaryGroup.resize(sizeBeforeResizing);

    paper.Group.executeOnTemporaryGroup(items, group => {
      group.position = centerBeforeResizing;
    });
  }
}
