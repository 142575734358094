/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'trash': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#000" d="M16.5 4.5h5.75c.45 0 .75.3.75.75v4.5c0 .45-.3.75-.75.75H1.75c-.45 0-.75-.3-.75-.75v-4.5c0-.45.3-.75.75-.75H7.5V.75c0-.45.3-.75.75-.75h7.5c.45 0 .75.3.75.75V4.5zm-1.5-3H9v3h6v-3zM2.5 9h19V6h-19v3zm16 3.75c0-.45.3-.75.75-.75s.75.3.75.75v7.5c0 2.1-1.65 3.75-3.75 3.75h-8.5C5.65 24 4 22.35 4 20.25v-7.5c0-.45.3-.75.75-.75s.75.3.75.75v7.5c0 1.2 1.05 2.25 2.25 2.25h8.5c1.2 0 2.25-1.05 2.25-2.25v-7.5z"/>'
  }
})
