<template>
  <vwc-dialog
    ref="dialog"
    v-observe-keyboard.l2.enter="confirmClearWhiteboard"
    class="dialog vvd-root"
    headline="Clear Whiteboard"
    icon-placement="side"
    icon="eraser-line"
    :escapeKeyAction="hide"
    @click.stop
  >
    <div slot="body" class="content">
      <div class="text">
        Are you sure you want to clear this whiteboard? This action cannot be
        undone.
      </div>
    </div>
    <div slot="footer" class="buttons-footer">
      <vwc-button
        label="Cancel"
        appearance="outlined"
        size="normal"
        @click="hide"
      />
      <vwc-button
        label="Clear"
        appearance="filled"
        connotation="alert"
        size="normal"
        @click="confirmClearWhiteboard"
      />
    </div>
  </vwc-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      isDialogOpen: false
    };
  },

  methods: {
    ...mapActions(['sendClearWhiteboardMessage', 'emitTelemetry']),

    showModal() {
      this.$refs.dialog.showModal();
      this.isDialogOpen = true;
    },

    confirmClearWhiteboard() {
      if (!this.isDialogOpen) {
        return;
      }
      this.emitTelemetry({ name: 'clear-whiteboard' });
      this.sendClearWhiteboardMessage();
      this.hide();
    },

    hide() {
      this.$refs.dialog.close();
      this.isDialogOpen = false;
    }
  }
};
</script>

<style scoped>
.text {
  max-width: 340px;
  margin-top: 12px;
}

vwc-dialog {
  --dialog-min-inline-size: 400px;
  --dialog-max-inline-size: 400px;
}

.buttons-footer {
  display: flex;
  justify-content: flex-end;
  column-gap: 8px;
  margin-top: 16px;
}
</style>
