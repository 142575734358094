/**
 * Interface for the communication layer required by the whiteboard
 */
export default class ICommunicationProvider {
  /**
   * Listens to an incoming board-related update event.
   * Implementation must ignore events that were sent by the user himself
   * @param {*} eventName Name of event to listen to
   * @param {*} callback Callback to run when the event is emitted. 1st parameter must be the emitted data
   */
  // eslint-disable-next-line no-unused-vars
  onBoardEvent(eventName, callback) {}

  /**
   * Listens to an incoming session-related signal.
   * @param {*} eventName Name of event to listen to
   * @param {*} callback Callback to run when the event is emitted
   */
  // eslint-disable-next-line no-unused-vars
  onSessionSignal(eventName, callback) {}

  /**
   * Sends a board-related update event.
   * @param {*} eventName Name of event to emit
   * @param {*} data Data to attach to the payload, which should eventually be a ".data" property in the emitted payload
   */
  // eslint-disable-next-line no-unused-vars
  sendBoardEvent(eventName, data) {}
}
