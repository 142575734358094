export default class ParticipantProvider {
  constructor(communicationProvider) {
    this.communicationProvider = communicationProvider;
    this.myParticipant = { participantId: -1, color: '#000' };
    this.participants = [];

    this.communicationProvider.onBoardEvent(
      'participants',
      this.handleParticipantsUpdate.bind(this)
    );
  }

  initialize() {
    this.communicationProvider.emit('request-participants');
  }

  handleParticipantsUpdate(data) {
    this.participants = data.participants;
  }

  get myParticipantId() {
    return this.myParticipant.participantId;
  }
}
