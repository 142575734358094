import WhiteboardTool from './whiteboard-tool';
import ContinuousPathWrapper from './paper-wrappers/continuous-path-wrapper';

export default class PenTool extends WhiteboardTool {
  constructor({
    settingsProvider,
    synchronizer,
    participantProvider,
    pathDrawingController,
    telemetries,
    clock
  }) {
    super();
    this.settingsProvider = settingsProvider;
    this.synchronizer = synchronizer;
    this.participantProvider = participantProvider;
    this.pathDrawingController = pathDrawingController;
    this.telemetries = telemetries;
    this.clock = clock;
    this.path = null;
  }

  activate(options) {
    super.activate();

    if (options && options.strokeWidth) {
      this.strokeWidth = options.strokeWidth;
    }
  }

  onMouseDown(event) {
    const id = this.synchronizer.generateId();
    this.path = new ContinuousPathWrapper(
      id,
      this.participantProvider.myParticipantId,
      this.clock.now(),
      {
        strokeWidth: this.strokeWidth,
        strokeColor: this.settingsProvider.color
      }
    );
    this.synchronizer.trackOutgoingContinuousPath(this.path);

    this.path.add(event.point);
  }

  onMouseDrag(event) {
    this.path.add(event.point);
  }

  onMouseUp() {
    this.path.finalize();
    this.pathDrawingController.finalizeContinuousPath(this.path);
    this.telemetries.emitTelemetry({
      name: 'use-tool',
      data: {
        type: 'PEN',
        strokeWidth: this.strokeWidth,
        color: this.settingsProvider.color
      }
    });
  }
}
