var render = function render(_c,_vm){return _c('button',_vm._g({directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.props.tooltip),expression:"props.tooltip",modifiers:{"bottom":true}}],staticClass:"Vlt-btn Vlt-btn--no-focus unselectable",class:[
    {
      'Vlt-btn--icon': !_vm.slots().default,
      'Vlt-btn--disabled': _vm.props.disabled,
      'Vlt-btn--app': _vm.props.app,
      'Vlt-btn--primary': _vm.props.primary,
      'Vlt-btn--secondary': _vm.props.secondary,
      'Vlt-btn--destructive': _vm.props.destructive,
      'Vlt-btn--tertiary': !(
        _vm.props.secondary ||
        _vm.props.quaternary ||
        _vm.props.primary ||
        _vm.props.destructive
      ),
      'Vlt-btn--small': _vm.props.sm,
      'Vlt-btn--large': _vm.props.lg,
      'Vlt-btn--quaternary': _vm.props.quaternary,
      'Vlt-btn--outline': _vm.props.outline,
      'Vlt-btn--white': _vm.props.white
    },
    _vm.data.staticClass,
    _vm.data.class
  ],attrs:{"id":_vm.data.attrs.id,"data-cy":_vm.data.attrs.id}},_vm.listeners),[(_vm.props.iconName)?_c('v-icon',{attrs:{"spin":_vm.props.spin,"iconName":_vm.props.iconName}}):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }