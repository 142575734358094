import CollaborativeWhiteboardService from '@/services/collaborative-whiteboard-service';
import { canvasPointToScreenPoint } from '@/utils/points-converters';
import { IDLE_CURSOR_MAX_TIME } from '@/consts';

export default {
  init: ({ dispatch }) => {
    CollaborativeWhiteboardService.cursorsTracker.on(
      'participant-cursor-added',
      event => {
        dispatch('addParticipantCursor', event);
      }
    );
    CollaborativeWhiteboardService.cursorsTracker.on(
      'participant-cursor-updated',
      event => {
        dispatch('updateParticipantCursor', event);
      }
    );
    CollaborativeWhiteboardService.cursorsTracker.on(
      'participant-cursor-removed',
      event => {
        dispatch('removeParticipantCursor', event);
      }
    );
    CollaborativeWhiteboardService.canvasPanZoomController.on('pan', () => {
      dispatch('realignCursors');
    });
    CollaborativeWhiteboardService.canvasPanZoomController.on('zoom', () => {
      dispatch('realignCursors');
    });
  },

  addParticipantCursor: (
    { state, commit, dispatch },
    { participantId, color, point }
  ) => {
    const existingCursorIndex = state.rawCursors.findIndex(
      cursor => cursor.participantId === participantId
    );
    if (existingCursorIndex >= 0) {
      commit('REMOVE_CURSOR', existingCursorIndex);
    }
    const cursorData = {
      participantId,
      color,
      pointOnCanvas: point,
      pointOnScreen: canvasPointToScreenPoint(point),
      isVisible: true
    };
    commit('ADD_CURSOR', cursorData);
    dispatch('startHideCursorTimer', { participantId, cursorData });
  },

  updateParticipantCursor: ({ state, commit, dispatch }, { participantId, point }) => {
    const cursorIndex = state.rawCursors.findIndex(
      cursor => cursor.participantId === participantId
    );
    const cursorData = {
      pointOnCanvas: point,
      pointOnScreen: canvasPointToScreenPoint(point),
      isVisible: true
    };
    commit('UPDATE_CURSOR', { cursorIndex, cursorData });
    dispatch('startHideCursorTimer', { participantId, cursorData });
  },

  startHideCursorTimer: ({commit, state, dispatch}, { participantId, cursorData }) => {
    dispatch('stopHideCursorTimer', participantId);

    const timerId = setTimeout(() => {
      const cursorIndex = state.rawCursors.findIndex(
        cursor => cursor.participantId === participantId
      );
      cursorData.isVisible = false;
      commit('UPDATE_CURSOR', { cursorIndex, cursorData });
      const cursorTimerIndex = state.cursorsTimersIds.findIndex(
        cursorTimer => cursorTimer.participantId === participantId
      );
      commit('REMOVE_CURSOR_TIMER_ID', cursorTimerIndex);
    }, IDLE_CURSOR_MAX_TIME);
    commit('ADD_CURSOR_TIMER_ID', {participantId, timerId});
  },

  stopHideCursorTimer: ({commit, state}, participantId) => {
    const cursorTimerIndex = state.cursorsTimersIds.findIndex(
      cursorTimer => cursorTimer.participantId === participantId
    );
    if (cursorTimerIndex < 0) {
      return;
    }

    clearTimeout(state.cursorsTimersIds[cursorTimerIndex].timerId);
    commit('REMOVE_CURSOR_TIMER_ID', cursorTimerIndex);
  },

  removeParticipantCursor: ({ state, commit, dispatch }, { participantId }) => {
    const cursorIndex = state.rawCursors.findIndex(
      cursor => cursor.participantId === participantId
    );
    commit('REMOVE_CURSOR', cursorIndex);
    dispatch('stopHideCursorTimer', participantId);
  },

  realignCursors: ({ state }) => {
    state.rawCursors = state.rawCursors.map(rawCursor => {
      return {
        ...rawCursor,
        pointOnScreen: canvasPointToScreenPoint(rawCursor.pointOnCanvas)
      };
    });
  }
};
