export const EXPORT_WHITEBOARD_INTERVAL = 5000;

export const DEFAULT_LINE_HEIGHT = 1.2;
export const TEXTBOX_PADDING_LEFT = 8;
export const TEXTBOX_PADDING_TOP = 4;
export const TEXTBOX_BORDER = 2;

export const IDLE_CURSOR_MAX_TIME = 15000;

export const AVAILABLE_TEXT_STYLES = {
  NORMAL: {
    text: 'Normal',
    fontSize: 14,
    lineHeight: 20,
    fontWeight: 'normal'
  },
  SUBHEADING: {
    text: 'Subheading',
    fontSize: 20,
    lineHeight: 28,
    fontWeight: 'bold'
  },
  HEADING: {
    text: 'Heading',

    fontSize: 26,
    lineHeight: 36,
    fontWeight: 'bold'
  },
  TITLE: {
    text: 'Title',
    fontSize: 32,
    lineHeight: 44,
    fontWeight: 'bold'
  }
};

export const FONT_FAMILY = 'Spezia';

// Amount of offset (both horizontal and vertical) to add pasted contents to
// help the user tell the difference between the original and the clone
export const PASTE_OFFSET = 15;
