import paper from 'paper';
import SimpleEventEmitter from '@/utils/SimpleEventEmitter';

/**
 * Abstract base class for PaperJS tools that can be used by the whiteboard
 */
export default class WhiteboardTool extends SimpleEventEmitter {
  initialize() {
    this.tool = new paper.Tool();
    this.tool.onMouseDown = this.onMouseDown.bind(this);
    this.tool.onMouseDrag = this.onMouseDrag.bind(this);
    this.tool.onMouseUp = this.onMouseUp.bind(this);
    this.tool.onKeyDown = this.onKeyDown.bind(this);
    this.tool.onKeyUp = this.onKeyUp.bind(this);
  }

  activate() {
    this.tool.activate();
    this.emit('activated');
  }

  get isActive() {
    return paper.tool === this.tool;
  }

  onMouseDown() {}
  onMouseDrag() {}
  onMouseUp() {}
  onKeyDown() {}
  onKeyUp() {}
  deactivate() {}
}
