export default {
  init(tag) {
    window._LTracker = window._LTracker || [];
    window._LTracker.push({
      logglyKey: process.env.VUE_APP_LOGGLY_KEY,
      sendConsoleErrors: false,
      tag
    });
  },

  push(log) {
    if (typeof log !== 'object') {
      throw new Error('Log must be an object');
    }
    window._LTracker.push(log);
  }
};
