import SimpleEventEmitter from '@/utils/SimpleEventEmitter';
// TODO: Consider storing settings such as PenThickness, ShapeType, LineType here as well
// to have 1 source of truth for those values
export default class SettingsProvider extends SimpleEventEmitter {
  constructor() {
    super();
    this._color = '#CBA1FA';
    this._textColor = '#000000';
    this._background = '#ffffff';
    this._zoomFactor = 1;
    this._textStyle = {
      isBold: false,
      isItalic: false,
      fontStyle: 'NORMAL' // NORMAL / SUBHEADING / HEADING / TITLE
    };
  }

  get color() {
    return this._color;
  }

  get textColor() {
    return this._textColor;
  }

  get zoomFactor() {
    return this._zoomFactor;
  }

  get textStyle() {
    return this._textStyle;
  }

  set color(value) {
    this._color = value;
    this.emit('color-changed', value);
  }

  set textColor(value) {
    this._textColor = value;
    this.emit('textColor-changed', value);
  }

  set zoomFactor(value) {
    this._zoomFactor = value;
    this.emit('zoomFactor-changed', value);
  }

  set textStyle(value) {
    this._textStyle = value;
    this.emit('textStyle-changed', value);
  }

  onChange(property, callback) {
    this.on(`${property}-changed`, callback);
  }
}
