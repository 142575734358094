import Vue from 'vue';

export default {
  ADD_CURSOR(state, cursor) {
    state.rawCursors.push(cursor);
  },

  UPDATE_CURSOR: (state, { cursorIndex, cursorData }) => {
    Vue.set(state.rawCursors, cursorIndex, {
      ...state.rawCursors[cursorIndex],
      ...cursorData
    });
  },

  REMOVE_CURSOR(state, cursorIndex) {
    state.rawCursors.splice(cursorIndex, 1);
  },

  ADD_CURSOR_TIMER_ID: (state, { participantId, timerId }) => {
    state.cursorsTimersIds.push({ participantId, timerId });
  },

  REMOVE_CURSOR_TIMER_ID(state, timerIndex) {
    state.cursorsTimersIds.splice(timerIndex, 1);
  },
};
