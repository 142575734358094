<template>
  <div class="pan-zoom-status-container">
    <div
      v-tooltip="'Zoom to fit'"
      class="zoom-to-fit-button"
      @click.stop="zoomToFitClicked"
      @touchend.stop
    >
      <v-icon iconName="magnifying-glass" class="magnifying-glass-icon" />
    </div>

    <div
      v-tooltip="'Zoom to 100%'"
      class="zoom-percentage-label"
      @click.stop="resetZoomClicked"
      @touchend.stop
    >
      {{ zoomPercentage }}%
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  computed: {
    ...mapState('panZoom', ['zoomFactor']),

    zoomPercentage() {
      return Math.round(this.zoomFactor * 100);
    }
  },

  methods: {
    ...mapActions(['emitTelemetry']),
    ...mapActions('panZoom', ['resetZoom', 'zoomToFit']),

    zoomToFitClicked() {
      const fromPercentage = this.zoomPercentage;
      this.zoomToFit();
      this.emitTelemetry({
        name: 'zoom-to-fit',
        data: {
          fromZoomPercentage: fromPercentage,
          toZoomPercentage: this.zoomPercentage
        }
      });
    },

    resetZoomClicked() {
      this.emitTelemetry({
        name: 'reset-zoom',
        data: { fromZoomPercentage: this.zoomPercentage, toZoomPercentage: 100 }
      });
      this.resetZoom();
    }
  }
};
</script>

<style scoped>
.pan-zoom-status-container {
  display: flex;
  align-items: center;
  padding: 4px;
  user-select: none;
  cursor: pointer;
  background: white;
  border-radius: 6px;
}

.mobile .pan-zoom-status-container {
  cursor: none;
}

.mobile .pan-zoom-status-container {
  cursor: none;
}

.zoom-to-fit-button {
  width: 40px;
  height: 40px;
}

.magnifying-glass-icon {
  width: 16px;
  height: 16px;
  margin: 12px;
}

.zoom-percentage-label {
  font-size: 12px;
  font-weight: 600;
  padding: 8px;
}
</style>
