<template>
  <popper
    ref="popper"
    v-tooltip.bottom="tooltip"
    trigger="clickToToggle"
    :options="{
      placement: 'bottom'
    }"
    :visible-arrow="false"
    @show="isMenuOpen = hasMenu"
    @hide="isMenuOpen = false"
  >
    <div v-show="hasMenu" class="popper">
      <slot name="menu" />
    </div>

    <div
      slot="reference"
      class="tool-button"
      :class="{ active: active || isMenuOpen, disabled }"
      @click="onClick"
    >
      <slot name="icon" />
    </div>
  </popper>
</template>

<script>
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';

export default {
  components: { popper: Popper },

  props: {
    active: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    displayName: {
      type: String,
      required: true
    }
  },

  data() {
    return { hasMenu: false, isMenuOpen: false };
  },

  computed: {
    tooltip() {
      if (this.isMenuOpen || this.disabled) {
        return null;
      }

      return {
        content: this.displayName
      };
    }
  },

  mounted() {
    this.hasMenu = Object.keys(this.$slots).includes('menu');
  },

  methods: {
    onClick() {
      if (this.disabled) {
        return;
      }

      if (this.hasMenu) {
        this.$emit('click');
      } else {
        this.$emit('activated');
        return;
      }
    },

    closeMenu() {
      this.$refs.popper.doClose();
    }
  }
};
</script>

<style scoped>
.tool-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  cursor: pointer;
}

.tool-button:hover:not(.disabled) {
  background: #e6e6e6;
}

.tool-button:active:not(.disabled),
.tool-button.active:not(.disabled) {
  background: black;
}

.tool-button:active:not(.disabled):not(.active) >>> .v-icon {
  filter: invert(1);
}

.tool-button.disabled {
  background: rgba(194, 196, 204);
  filter: opacity(0.5);
}

.tool-button >>> .v-icon {
  width: 16px;
  height: 16px;
}

.popper {
  box-shadow: 0px 4px 2px rgba(19, 20, 21, 0.05),
    0px 2px 4px rgba(19, 20, 21, 0.05), 0px 2px 8px rgba(19, 20, 21, 0.1);
}
</style>
