import paper from 'paper';
import WhiteboardTool from './whiteboard-tool';

const SHAPE_TYPES = {
  RECTANGLE: 'rectangle',
  ELLIPSE: 'ellipse',
  TRIANGLE: 'triangle'
};

export default class ShapeTool extends WhiteboardTool {
  constructor({ settingsProvider, pathDrawingController, telemetries }) {
    super();
    this.settingsProvider = settingsProvider;
    this.pathDrawingController = pathDrawingController;
    this.telemetries = telemetries;

    this.shapeType = SHAPE_TYPES.RECTANGLE;
    this.previewPath = null;
  }

  activate(options) {
    super.activate();

    if (options && options.shapeType) {
      this.shapeType = options.shapeType;
    }
  }

  onMouseDown(ev) {
    this.previewPath = this.createPathBySelectedShapeType(
      ev.downPoint,
      ev.point
    );
    this.previewPath.strokeColor = 'grey';
    this.previewPath.dashArray = [5, 5];
    this.previewPath.data.isLocal = true;
  }

  onMouseDrag(ev) {
    if (!this.previewPath) {
      return;
    }

    switch (this.shapeType) {
      case SHAPE_TYPES.RECTANGLE:
        this.previewPath.segments[1].point = new paper.Point(
          ev.point.x,
          ev.downPoint.y
        );
        this.previewPath.segments[2].point = new paper.Point(
          ev.point.x,
          ev.point.y
        );
        this.previewPath.segments[3].point = new paper.Point(
          ev.downPoint.x,
          ev.point.y
        );
        break;
      default:
        this.previewPath.remove();
        this.previewPath = this.createPathBySelectedShapeType(
          ev.downPoint,
          ev.point
        );
        this.previewPath.strokeColor = 'grey';
        this.previewPath.dashArray = [5, 5];
    }
  }

  onMouseUp(ev) {
    if (!this.previewPath) {
      return;
    }

    const path = this.createPathBySelectedShapeType(ev.downPoint, ev.point);

    // Hold Shift to draw the shape outline;
    if (ev.modifiers.shift) {
      path.strokeColor = this.settingsProvider.color;
    } else {
      path.fillColor = this.settingsProvider.color;
    }

    this.pathDrawingController.finalizeRawPath(path);
    this.previewPath.remove();
    this.previewPath = null;
    this.telemetries.emitTelemetry({
      name: 'use-tool',
      data: {
        type: 'SHAPE',
        strokeWidth: this.strokeWidth,
        color: this.settingsProvider.color,
        shapeType: this.shapeType.toUpperCase()
      }
    });
  }

  createPathBySelectedShapeType(from, to) {
    switch (this.shapeType) {
      case SHAPE_TYPES.RECTANGLE: {
        return new paper.Path.Rectangle(from, to);
      }
      case SHAPE_TYPES.ELLIPSE: {
        return new paper.Path.Ellipse(new paper.Rectangle(from, to));
      }
      case SHAPE_TYPES.TRIANGLE: {
        const topPoint = new paper.Point((from.x + to.x) / 2, from.y);
        const leftPoint = new paper.Point(from.x, to.y);
        const rightPoint = new paper.Point(to.x, to.y);
        return new paper.Path([topPoint, leftPoint, rightPoint, topPoint]);
      }
    }
  }

  onKeyDown(ev) {
    if (ev.key === 'escape' && this.previewPath) {
      this.previewPath.remove();
      this.previewPath = null;
    }
  }
}
