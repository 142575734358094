import Loggly from './loggly-service';

export default {
  init() {
    this.contextData = {
      appName: 'Vonage-Collaborative-Whiteboard',
      appEnv: process.env.NODE_ENV,
      URL: location.hostname
    };

    Loggly.init(process.env.VUE_APP_LOGGLY_TAG);
  },

  updateContext(contextData) {
    this.contextData = {
      ...this.contextData,
      ...contextData
    };
  },

  info(name, data = {}) {
    Loggly.push({
      contextData: this.contextData,
      name,
      data,
      type: 'info'
    });
  },

  error(name, data = {}) {
    Loggly.push({
      contextData: this.contextData,
      name,
      data,
      type: 'error'
    });
  },

  warning(name, data = {}) {
    Loggly.push({
      contextData: this.contextData,
      name,
      data,
      type: 'warning'
    });
  }
};
